import React, { useState, useEffect } from "react";
import "./LoginPage.css";
import { connect } from "react-redux";
import HeaderPage from "../../header/components/HeaderPage";
import loginpage_image from "./../../../assets/loginside_page.png";
import FooterPage from "../../footer/components/FooterPage";
import { Form, InputGroup } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import email_icon from "./../../../assets/email_icon.svg";
import password_icon from "./../../../assets/password_icon.svg";
import { getLogindetails } from "../util/login";
import { fetchLogindata } from "../redux/actions";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { getUserdetail } from "../../profile/util/getUserdetail";
import { fetchUserid } from "../redux/actions";
import { useTranslation } from "react-i18next";

function LoginPage(props) {
  const primaryBtn = props.theme.primaryBtn;
  const inputType = props.theme.inputForm;
  const { t } = useTranslation();
  const { access, fetchLogindata, fetchUserid } = props;

  const navigate = new useNavigate();
  const location = useLocation();

  const emailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  const [passwordState, setPasswordState] = useState(false);
  const [loginInput, setLogininput] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    redirectHome();
    // eslint-disable-next-line
  }, [access]);

  useEffect(() => {
    if (location?.state?.regisiterSuccess === true) {
      toast.success(
        props.language === "En"
          ? "Your Registration is done successfully. Please login"
          : "Su Registro se ha realizado con éxito. Por favor Iniciar sesión"
      );
      navigate(location.pathname, { replace: true });
    }
    // eslint-disable-next-line
  }, [location?.state?.regisiterSuccess]);

  useEffect(() => {
    if (location?.state?.proceedBeforelogin === true) {
      toast.error(
        props.language === "En"
          ? "Please login with your credential"
          : "Por favor inicie sesión con su credencial"
      );
      navigate(location.pathname, { replace: true });
    }
    // eslint-disable-next-line
  }, [location?.state?.proceedBeforelogin]);

  const redirectHome = () => {
    if (access) {
      navigate("/", { state: { loginFirst: true } });
    }
  };

  const logininputChange = (e) => {
    setLogininput({
      ...loginInput,
      [e.target.name]: e.target.value,
    });

    switch (e.target.name) {
      case "email":
        setErrors({
          ...errors,
          email: "",
        });
        break;
      case "password":
        setErrors({
          ...errors,
          password: "",
        });
        break;

      default:
        break;
    }
  };

  const validation = () => {
    let checkValue = true;

    if (!loginInput.email.match(emailRegex)) {
      errors.email = t("pages.login.email-valid")
      checkValue = false;
    }

    if (loginInput.email === "") {
      errors.email = t("pages.login.email-warn")
      checkValue = false;
    }

    if (loginInput.password === "") {
      errors.password = t("pages.login.password-warn");
      checkValue = false;
    }

    setErrors({
      ...errors,
      email: errors.email,
      password: errors.password,
    });

    if (checkValue === false) {
      return false;
    } else {
      return true;
    }
  };

  const callLogin = async (e) => {
    e.preventDefault();
    if (validation()) {
      let loginDetail = {
        username: loginInput.email,
        password: loginInput.password,
      };
      let getLoginresponse = await getLogindetails(loginDetail);
      if (getLoginresponse.status === 200) {
        let getLogindata = await getLoginresponse.json();
        let getUserdetailresponse = await getUserdetail(getLogindata?.access);
        //console.log("login res", getLogindata);
        if (getUserdetailresponse.status === 200) {
            let getResponse = await getUserdetailresponse.json();

            if(getResponse?.data[0]?.user_type==="buyer"|| getResponse?.data[0]?.user_type==="seller"){
              if(getResponse?.data[0]?.is_active === true && getResponse?.data[0]?.is_deleted === false){
                fetchUserid(getResponse?.data[0]?.id,getResponse?.data[0]?.user_type);
                fetchLogindata(getLogindata?.access);
              }else{
                toast.info("don't have authorization to login")
              }
            }else{
              toast.info(t('pages.invalid-crediatial'))
            }
        }
      } else if (getLoginresponse.status === 401) {
        let getLogindata = await getLoginresponse.json();
        toast.error(getLogindata?.detail);
      } else if (getLoginresponse.status === 501) {
        toast.error("Internal server error");
      }
    }
  };

  return (
    <>
      <HeaderPage color={"white"} logo={"black"} background={"transparent"} />
      <div className={"login col-12 row m-0"}>
        <div className={"col-6 px-10 py-5 loginview"}>
          <div className={"login-box bounceIn"} px={"28px"}>
            <div className={"login-heading"}>
              <h3 className={"login-title  m-4 "}>{t("pages.login.title")}</h3>
              <label className={"secoundry"}>{t("pages.login.subtitle")}</label>
            </div>
            <Form className={"login-form"}>
              <div className={"form-group"}>
                <div className={"form-title"}>{t("pages.login.email-ti")}</div>
                <InputGroup style={inputType}>
                  <InputGroup.Text>
                    <img
                      src={email_icon}
                      alt={"."}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder={t("pages.login.email-place")}
                    name={"email"}
                    onChange={(e) => logininputChange(e)}
                    value={loginInput.email}
                    type={"email"}
                    className={"input-field"}
                  />
                </InputGroup>
                <div className="text-danger">{errors.email}</div>
              </div>
              <div className={"form-group"}>
                <div className={"form-title"}>{t("pages.login.pass-ti")}</div>
                <InputGroup style={inputType}>
                  <InputGroup.Text>
                    <img
                      src={password_icon}
                      alt={"."}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder={t("pages.login.pass-place")}
                    name={"password"}
                    onChange={(e) => logininputChange(e)}
                    value={loginInput.password}
                    type={passwordState ? "text" : "password"}
                    className={"input-field"}
                  />
                  <InputGroup.Text
                    style={{ cursor: "pointer" }}
                    onClick={() => setPasswordState(!passwordState)}
                  >
                    <i
                      className={
                        passwordState ? "fa fa-eye" : "fa fa-eye-slash"
                      }
                    />
                  </InputGroup.Text>
                </InputGroup>
                <div className="text-danger">{errors.password}</div>
              </div>
              <div className={"pt-4 pb-4 "}>
                <Link
                  to={"/forgot"}
                  style={{ textDecoration: "none", cursor: "pointer" }}
                >
                  <label className={"forgot-title"}>
                    {t("pages.login.forgot")}
                  </label>
                </Link>
              </div>
              <Button
                className={"shadow-btn login-btn"}
                type={"submit"}
                style={primaryBtn}
                onClick={(e) => callLogin(e)}
              >
                {t("pages.login.button")}
              </Button>
            </Form>
            <center>
              <Link
                to={"/register"}
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <label className={"alter-login"}>
                  {t("pages.login.regtitle")}
                  <strong> {t("pages.login.register")}</strong>
                </label>
              </Link>
            </center>
            <br />
          </div>
          <label className={"all-right-reserve"}>
            © 2022 ConfyCar. {t("pages.footer.rights")}
          </label>
        </div>
        <div className={"col p-0"}>
          <img
            src={loginpage_image}
            alt={"."}
            className={"user-select-none"}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
      <FooterPage />
    </>
  );
}
const mapStateToProps = (state) => ({
  theme: state?.theme?.primaryTheme,
  access: state?.login?.access,
  language: state?.theme?.language,
});
export default connect(mapStateToProps, { fetchLogindata, fetchUserid })(
  LoginPage
);
