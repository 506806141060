import React from "react";
import { Modal } from "react-bootstrap";
import successful from "../../../assets/successful.svg";
import "./PaymentsuccPage.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function PaymentsuccPage(props) {
  const { showpay, setShowpay, requestId } = props;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const closePaymodal = () => {
    setShowpay(false);
    navigate("/my-account/request");
  };

  const viewDetail = () => {
    navigate("/my-account/request");
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showpay}
      className="payment-modal"
      onHide={closePaymodal}
    >
      <Modal.Body>
        <div>
          <div className="payment">
            <img className="success-img" alt={"."} src={successful}></img>
            <h3 className="payment-succ">
              {t("pages.payment-popup.successful")}
            </h3>
            {/* <h4 className="payment-id">
              {t("pages.payment-popup.transaction")}
            </h4>
            <h4 className="transaction-id">3535DRFERTYR231Q</h4> */}
            <h4 className="payment-id">{t("pages.payment-popup.request")}</h4>
            <h4 className="transaction-id">{requestId}</h4>
            <button
              className="viewdet-btn"
              type={"submit"}
              onClick={() => viewDetail()}
            >
              {t("pages.payment-popup.button")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default PaymentsuccPage;
