import React, { useState } from "react";
import "./ChangepasswordPage.css";
import HeaderPage from "../../header/components/HeaderPage";
import { Row, Form, InputGroup, Button, Container } from "react-bootstrap";
import password_icon from "./../../../assets/password_icon.svg";
import arrow_back from "../../../assets/arrow_back.svg";
import FooterPage from "../../footer/components/FooterPage";
import { useNavigate } from "react-router-dom";
import { getChangepassworddetail } from "../util/changepassword";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function ChangepasswordPage(props) {
  const { access } = props;
  const { t } = useTranslation();
  const passRegex =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\w\W]){1,})(?!.*\s).{8,}$/;

  const navigate = useNavigate();

  const [passwordInput, setPasswordInput] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const passwordInputChange = (e) => {
   // console.log("hello");
    setPasswordInput({
      ...passwordInput,
      [e.target.name]: e.target.value,
    });

    switch (e.target.name) {
      case "old_password":
        setErrors({
          ...errors,
          old_password: "",
        });
        break;
      case "new_password":
        setErrors({
          ...errors,
          new_password: "",
        });
        break;
      case "confirm_password":
        setErrors({
          ...errors,
          confirm_password: "",
        });
        break;
      default:
        break;
    }
  };

  const validation = () => {
    let checkValue = true;

    if (passwordInput.old_password === "") {
      errors.old_password = t("pages.change.password-warn");
      checkValue = false;
    }

    if (!passwordInput.new_password.match(passRegex)) {
      errors.new_password =t("pages.change.n-password-valid");
      checkValue = false;
    }

    if (passwordInput.new_password === "") {
      errors.new_password = t("pages.change.n-password-warn");
      checkValue = false;
    }

    if (passwordInput.new_password !== passwordInput.confirm_password) {
      errors.confirm_password = t("pages.change.c-password-valid");
      checkValue = false;
    }

    if (passwordInput.confirm_password === "") {
      errors.confirm_password = t("pages.change.c-password-warn");
      checkValue = false;
    }

    setErrors({
      ...errors,
      old_password: errors.old_password,
      new_password: errors.new_password,
      confirm_password: errors.confirm_password,
    });

    if (checkValue === true) {
      return true;
    } else {
      return false;
    }
  };

  const savePassword = async (e) => {
    e.preventDefault();
    if (validation()) {
      let passworddetail = {
        old_password: passwordInput.old_password,
        new_password: passwordInput.new_password,
        confirm_password: passwordInput.confirm_password,
      };

      let getChangepasswordresponse = await getChangepassworddetail(
        passworddetail,
        access
      );

      if (getChangepasswordresponse.status === 200) {
        //console.log("res 201", await getChangepasswordresponse.json());
        toast.success(t("pages.change.success"));
        setPasswordInput({
          old_password: "",
          new_password: "",
          confirm_password: "",
        });
      } else if (getChangepasswordresponse.status === 400) {
        let getres = await getChangepasswordresponse.json();
        if (getres?.old_password) {
          toast.error(t("pages.change.wrong"));
        }
      } else if (getChangepasswordresponse.status === 500) {
        toast.success(t("pages.change.server-err"));
      }
    }
  };

  return (
    <>
      <HeaderPage color={"black"} logo={"black"} background={"#F7F7F7"} />
      <div className="col-12">
        <div className="change-password">
          <Container>
            <h3>{t("pages.change.title")}</h3>
            <Row>
              <form>
                <div className="col-11">
                  <Form.Group className="form-group">
                    <Form.Label className="form-label">
                      {t("pages.change.password")}
                    </Form.Label>
                    <InputGroup className="input-group">
                      <InputGroup.Text>
                        <img
                          src={password_icon}
                          alt={"."}
                          style={{ height: "100%", width: "100%" }}
                        />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="form-control"
                        placeholder={t("pages.change.password-place")}
                        value={passwordInput.old_password}
                        name="old_password"
                        onChange={(e) => passwordInputChange(e)}
                      ></Form.Control>
                    </InputGroup>
                  </Form.Group>
                  <div className="text-danger">{errors.old_password}</div>
                </div>
                <div className="col-11">
                  <Form.Group className="form-group">
                    <Form.Label className="form-label">
                      {t("pages.change.n-password")}
                    </Form.Label>
                    <InputGroup className="input-group">
                      <InputGroup.Text>
                        <img
                          src={password_icon}
                          alt={"."}
                          style={{ height: "100%", width: "100%" }}
                        />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="form-control"
                        placeholder={t("pages.change.n-password-place")}
                        value={passwordInput.new_password}
                        name="new_password"
                        onChange={(e) => passwordInputChange(e)}
                      ></Form.Control>
                    </InputGroup>
                  </Form.Group>
                  <div className="text-danger">{errors.new_password}</div>
                </div>
                <div className="col-11">
                  <Form.Group className="form-group">
                    <Form.Label className="form-label">
                      {t("pages.change.c-passowrd")}
                    </Form.Label>
                    <InputGroup className="input-group">
                      <InputGroup.Text>
                        <img
                          src={password_icon}
                          alt={"."}
                          style={{ height: "100%", width: "100%" }}
                        />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="form-control"
                        placeholder={t("pages.change.c-password-place")}
                        value={passwordInput.confirm_password}
                        name="confirm_password"
                        onChange={(e) => passwordInputChange(e)}
                      ></Form.Control>
                    </InputGroup>
                  </Form.Group>
                  <div className="text-danger">{errors.confirm_password}</div>
                </div>
                <div className="col-11">
                  <div className="pass-footer">
                    <div className="save-pass-btn">
                      <Button
                        type={"submit"}
                        className="save-password"
                        onClick={(e) => savePassword(e)}
                      >
                        {t("pages.change.button")}
                      </Button>
                      <div
                        className="back-profile"
                        onClick={() => navigate("/my-account")}
                      >
                        <img src={arrow_back} alt={"."}></img>
                        <h4>{t("pages.change.back")}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Row>
          </Container>
        </div>
      </div>
      <FooterPage />
    </>
  );
}

const mapStateToProps = (state) => ({
  access: state?.login?.access,
  language: state?.theme?.language,
});

export default connect(mapStateToProps)(ChangepasswordPage);
