import { ADMIN_LOGIN_SUCCESS } from "./actionTypes";

const loginState = {
  access: "",
  admin_data: [],
  profile_pic_base_url: "",
};

export const adminReducer = (state = loginState, action) => {
  switch (action.type) {
    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        access: action.access,
        admin_data: action.admin_data,
        profile_pic_base_url: action.profile_pic_base_url,
      };
    default:
      return state;
  }
};
export default adminReducer;
