import { PACKAGE_LIST, LOGIN_PACKAGE, SPECIALIST_ID } from "./actionTypes";

const homestate = {
  packagelist: [],
  showPackagelist: false,

  specialist_id: "",
};

export const homeReducer = (state = homestate, action) => {
  console.log("updated reducer package list", action.packagelist);
  switch (action.type) {
    case PACKAGE_LIST:
      return {
        ...state,
        packagelist: action.packagelist,
      };
    case LOGIN_PACKAGE:
      return {
        ...state,
        showPackagelist: action.showPackagelist,
      };
    case SPECIALIST_ID:
      return {
        ...state,
        specialist_id: action.specialist_id,
      };
    default:
      return state;
  }
};
export default homeReducer;
