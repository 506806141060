import React, { useEffect, useState } from "react";
import "./CarddetailPage.css";
import { Row, Container } from "react-bootstrap";
import PaymentsuccPage from "../../modal/components/PaymentsuccPage";
import { getOrderdetails } from "../util/orderCreate";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getPaydetails } from "../util/storePayment";
import { useLocation } from "react-router-dom";
import HeaderPage from "../../header/components/HeaderPage";
import FooterPage from "../../footer/components/FooterPage";
import { useTranslation } from "react-i18next";
import bank_icon from "../../../assets/bank_icon.png";
import moment from "moment";

function CarddetailPage(props) {
  const [showpay, setShowpay] = useState(false);

  const { t } = useTranslation();

  const [requestId, setRequestid] = useState();

  const location = useLocation();

  const [packageamount, setPackageamount] = useState();

  useEffect(() => {
    let getPackage = props.packagelist.filter(
      (item) => item.is_checked === true
    );
    setPackageamount(getPackage[0]?.amount);
    // eslint-disable-next-line
  }, []);

  const showPayment = async () => {
    let getPackage = props.packagelist.filter(
      (item) => item.is_checked === true
    );

    //console.log("user type", props?.userType);

  //  console.log("Vehical information", location?.state?.vehicaldetail);

    let orderDetails = {
      user_id: props?.userId.toString(),
      final_amount: getPackage[0]?.amount,
      current_status: "initiated",
      o_type: props?.userType,
      payment_status: "pending",
      package_id: getPackage[0]?.id,
      amount: getPackage[0]?.amount,
      vehicle_brand: location?.state?.vehicaldetail?.vehicle_brand,
      vehicle_km: location?.state?.vehicaldetail?.vehicle_km,
      vehicle_type: location?.state.vehicaldetail?.vehicle_type,
      vehicle_age: moment(location?.state.vehicaldetail?.vehicle_age).year(),
      vehicle_color: location?.state?.vehicaldetail?.vehicle_color,
      vehicle_number_plate:
        location?.state?.vehicaldetail?.vehicle_number_plate,
      vehicle_power: location?.state?.vehicaldetail?.vehicle_power,
      vehicle_location: location?.state?.vehicaldetail?.vehicle_location,
      lat: location?.state?.vehicaldetail?.lat.toString(),
      lng: location?.state?.vehicaldetail?.lng.toString(),
      specialist_id: props.specialist_id.toString(),
    };

   // console.log("order detail", orderDetails);

    let getOrderdetailresponse = await getOrderdetails(
      orderDetails,
      props.access
    );

    if (getOrderdetailresponse.status === 201) {
      let getRes = await getOrderdetailresponse.json();

    //  console.log("order create response", getRes);
      setRequestid(getRes?.data?.request_id);

      let pay = {
        package_id: getPackage[0].id,
        order_id: getRes?.data?.order_id,
        amount: getPackage[0].amount,
        trn_id: "",
        status: "",
        payer_id: "",
        gross_pay: "",
      };

      let getPaydetailsresponse = await getPaydetails(pay, props.access);

      if (getPaydetailsresponse?.status === 201) {
        toast.success(t('pages.card.success'));
      } else if (getPaydetailsresponse?.status === 500) {
        toast.error(t('pages.card.error'));
      }
    }
    setShowpay(true);
  };

  return (
    <>
      <HeaderPage color={"black"} logo={"black"} background={"#F7F7F7"} />

      <div className="card-form">
        <Container>
          <Row>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="detail">
                <div className="package-detail">
                  <h5>{t("pages.card.title")}</h5>
                  <h3>{packageamount}</h3>
                  <p>{t("pages.card.bank-description")}</p>
                </div>
                <div className="package-detail pt-3">
                  <p>{t("pages.card.tran-description")}</p>
                </div>
                <div className="package-detail-wh pt-3">
                  <p>
                    {t("pages.card.whatsapp")} <br />
                    +91 98602714142
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="bank-detail-parent">
                <div className="bank-detail">
                  <div className="bank-detail-header">
                    <h5 className="pt-2">
                      {t("pages.card.bank")}
                      <span className="px-1">{t("pages.card.details")}</span>
                    </h5>
                    <img alt="" src={bank_icon}></img>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-borderless account-detail">
                      <tbody>
                        <tr>
                          <td className="account-no-text">
                            {t("pages.card.account-no")}
                          </td>
                          <td className="account-no">4454578155514</td>
                        </tr>
                        <tr>
                          <td className="bank-name-text">
                            {t("pages.card.bank-name")}
                          </td>
                          <td className="bank-name">Bank of India</td>
                        </tr>
                        <tr>
                          <td className="bank-name-text">
                            {t("pages.card.ifsc")}
                          </td>
                          <td className="bank-name">KKGA3545AGJF</td>
                        </tr>
                        <tr>
                          <td className="bank-name-text">
                            {t("pages.card.branch-name")}
                          </td>
                          <td className="bank-name">Solapur</td>
                        </tr>
                      </tbody>
                    </table>
                    <span className="doller-sapn">$</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="sub-order">
              <button className="sub-order-btn" onClick={() => showPayment()}>
                {t("pages.card.submit")}
              </button>
            </div>
          </Row>
          <PaymentsuccPage
            showpay={showpay}
            setShowpay={setShowpay}
            requestId={requestId}
          ></PaymentsuccPage>
        </Container>
      </div>
      <FooterPage />
    </>
  );
}
const mapStateToProps = (state) => ({
  access: state?.login?.access,
  packagelist: state?.home?.packagelist,
  userId: state?.login?.userId,
  userType: state?.login?.userType,
  specialist_id: state?.home?.specialist_id,
  language: state?.theme?.language,
});

export default connect(mapStateToProps)(CarddetailPage);
