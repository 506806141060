import React from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import "./FooternavPage.css"

function FooternavPage(props) {
    const is_Sp = props?.admin_data[0]?.user_type === "sp"?true:false;
    const { t } = useTranslation()
  return (
    <footer class="admin footer">
        <div class="admin container-fluid">
            <div class="admin row">
                <div class="admin col-md-6">
                    2022 © ConfyCar by <a rel="noreferrer"  href="https://appristine.com/" target={"_blank"} style={{ cursor:"pointer", textDecoration:"none", color:"#f05151" }}>appristine.com</a>
                </div>
                <div class="admin col-md-6"hidden>
                    <div class="admin text-md-end footer-links d-none d-sm-block" hidden>
                        <a href>{is_Sp?t('pages.specialist.footer.about'):"About Us"}</a>
                        <a href>{is_Sp?t('pages.specialist.footer.help'):"Help"}</a> 
                        <a href>{is_Sp?t('pages.specialist.footer.contact'):"Contact Us"}</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}
const mapStateToProps = (state) => ({
    access: state.adminlogin.access,
    admin_data: state?.adminlogin?.admin_data,
    searchInput: state.searchInput.search,
    language: state?.theme?.language,
    profile_url: state.adminlogin.profile_pic_base_url,
  });
  
export default connect(mapStateToProps)(FooternavPage)