import React, { useEffect, useState } from "react";
import "./HeaderPage.css";
import { connect } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Dropdown, Offcanvas } from "react-bootstrap";
import user_circle from "./../../../assets/user-circle.svg";
import notifications from "./../../../assets/notifications.svg";
import user_icon_black from "./../../../assets/user_icon_black.svg";
//import storage from "redux-persist/lib/storage";
import { setShowpackagelist } from "../../home/redux/actions";
import Uk from "./../../../assets/ukflag.png";
import Spanish from "./../../../assets/spain.png";
import { fetchChangeLanguage } from "../redux/actions";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Logout } from "./../../login/redux/actions"
import { getUserdetail } from './../../profile/util/getUserdetail'

function HeaderPage(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [scroll, setScroll] = useState(false);
  const theme = props.theme;
  const colors = props.color;
  const [show, setShow] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  useEffect(() => {
    changeChange(props.language);
    // eslint-disable-next-line
  }, [!props.language]);
  useEffect(()=>{ 
    callGetuserDetail()
    // eslint-disable-next-line
  },[])
  window.onscroll = function () {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const showHome = () => {
    if (props.showPackagelist === false) {
      navigate("/");
    } else {
      props.setShowpackagelist(false);
      navigate("/");
    }
  };

  const callLogout = (e) => {
    props.Logout();
    props.setShowpackagelist(false)
    handleClose()
    navigate("/");
  };
  const changeChange = (e) => {
    props.fetchChangeLanguage(e);
    i18next.changeLanguage(e);
  };
  const callGetuserDetail = async () =>{
    const responceDetails = await getUserdetail(props.access);
    if(responceDetails.status===200){
      const resultDetail = await responceDetails.json();
      const data = {
        ...resultDetail.data[0],
        url:resultDetail.url
      }
     setUserDetail(data);
    }else{
      const resultDetail = await responceDetails.json();
      if(resultDetail?.code==="token_not_valid"){
        props.Logout();
        props.setShowpackagelist(false)
      }
    }
  }
  return (
    <div
      className={scroll ? "header-nav stick " : "header-nav"}
      style={{ background: props.background }}
    >
      <div className={"header-nav-inner row col-12 m-0 p-0"}>
        <nav>
          <div className={"bounceIn Logos-div"} onClick={() => showHome()}>
            <h4 style={{ color: props?.logo }}>
              {t("pages.header.title-l")}
              <span>{t("pages.header.title-r")}</span>
            </h4>
          </div>
          <div className={"header-right  "}>
            <div className={"slide-effect desktop"}>
              <NavLink
                to={"/"}
                className={"header-pages"}
                style={{ textDecoration: "none" }}
              >
                <button
                  className={"text-upper btn"}
                  style={{ color: colors }}
                  variant="text"
                >
                  {props.showPackagelist === true
                    ? t("pages.header.plans")
                    : t("pages.header.home")}
                </button>
              </NavLink>
              <NavLink
                to={"/about"}
                className={"header-pages"}
                style={{ textDecoration: "none" }}
              >
                <button
                  className={"text-upper btn"}
                  style={{ color: colors }}
                  variant="text"
                >
                  {t("pages.header.about")}
                </button>
              </NavLink>
              <NavLink
                to={"/faq"}
                className={"header-pages"}
                style={{ textDecoration: "none" }}
              >
                <button
                  className={"text-upper btn"}
                  style={{ color: colors }}
                  variant="text"
                >
                  {t("pages.header.faq")}
                </button>
              </NavLink>
              <NavLink
                to={"/contact"}
                className={"header-pages"}
                style={{ textDecoration: "none" }}
              >
                <button
                  className={"text-upper btn"}
                  style={{ color: colors }}
                  variant="text"
                >
                  {t("pages.header.contact")}
                </button>
              </NavLink>
              <Dropdown
                className="language-dropdown"
                align="end"
                id="dropdown-menu-align-end"
              >
                <Dropdown.Toggle
                  id="dropdown-basic"
                  style={{
                    color: colors,
                    background: "transparent",
                  }}
                >
                  <img
                    src={props.language === "Sp" ? Spanish : Uk}
                    alt={"."}
                    className={"flag-select-en"}
                  />{" "}
                  {props?.language}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Header>
                    {t("pages.header.select-lang")}
                  </Dropdown.Header>
                  <Dropdown.Item onClick={() => changeChange("En")}>
                    <img src={Uk} alt={"."} className={"flag-en"} />
                    English - En
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => changeChange("Sp")}>
                    <img src={Spanish} alt={"."} className={"flag-en"} />
                    Spanish - Sp
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {props.access ? (
                <>
                  <Dropdown className="dropdown-user" align={"end"}>
                    <Dropdown.Toggle
                      className={""}
                      id="dropdown-basic"
                      style={{
                        color: theme.primaryBtn.background,
                        background: "transparent",
                        fontSize:14
                      }}
                    >
                      <div className={"user_circle"}>
                        <img src={userDetail?.url+userDetail?.profile_pic} onError={(event) => { event.target.src = user_circle}} alt={"."} />
                      </div>
                      {userDetail?.first_name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        style={{ color: theme?.primaryBtn?.color }}
                        onClick={() => {
                          props.setShowpackagelist(false)
                          navigate("/my-account/profile")
                        }}
                      >
                        <div className={"notification_icon"}>
                          <img src={user_icon_black} alt={"."} />
                        </div>
                        {t("pages.header.my-account")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{ color: theme?.primaryBtn?.color }}
                        onClick={() =>{
                            props.setShowpackagelist(false)
                            navigate("/my-account/notification")
                           }}
                      >
                        <div className={"notification_icon"}>
                          <img src={notifications} alt={"."} />
                        </div>
                        {t("pages.header.notification")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{ color: theme?.primaryBtn?.color }}
                        onClick={(e) => callLogout(e)}
                      >
                        <div className={"notification_icon"}>
                          <i className="fa fa-sign-out" />
                        </div>
                        {t("pages.header.log-out")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : (
                <>
                  <Link to={"/register"} style={{ textDecoration: "none" }}>
                    <button
                      className={"text-upper btn register"}
                      style={{ color: colors, textDecoration: "underline" }}
                      variant="text"
                    >
                      {t("pages.header.register")}
                    </button>
                  </Link>
                  <Link to={"/login"} style={{ textDecoration: "none" }}>
                    <button
                      className={"text-upper btn login-btn"}
                      style={theme.headerBtn}
                    >
                      {t("pages.header.login")}
                    </button>
                  </Link>
                  <Link to={"/specialist"} style={{ textDecoration: "none" }}>
                    <button className={"text-upper specialist-btn"}>
                      {t("pages.header.specialist")}
                    </button>
                  </Link>
                </>
              )}
            </div>
            <div className="mo-drawer-icon" onClick={handleShow}>
              <i style={{ color: props?.logo }} className="fa fa-align-right" />
            </div>
            <div
              className={"mobile-viewdrawer mobile"}
              style={{ background: "#000000e6!important" }}
            >
              <Offcanvas
                style={{ background: "#000000e6!important" }}
                placement="end"
                size="full"
                show={show}
                onHide={handleClose}
                className={"mobile-drawer"}
              >
                <Offcanvas.Title
                  className="pt-4 text-center"
                  
                >
                  <h4 onClick={() => showHome()}>
                    {t("pages.header.title-l")}
                    <span>{t("pages.header.title-r")}</span>
                  </h4>
                  <i onClick={handleClose} className="fa fa-times" />
                </Offcanvas.Title>
                <Offcanvas.Body className={props.access ? "log" : "not-log"}>
                  <NavLink
                    to={"/"}
                    className={"header-pages"}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      className={"text-upper btn"}
                      style={{ color: colors }}
                      variant="text"
                    >
                      {props.showPackagelist === true
                        ? t("pages.header.plans")
                        : t("pages.header.home")}
                    </button>
                  </NavLink>
                  <NavLink
                    to={"/about"}
                    className={"header-pages"}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      className={"text-upper btn"}
                      style={{ color: colors }}
                      variant="text"
                    >
                      {t("pages.header.about")}
                    </button>
                  </NavLink>
                  <NavLink
                    to={"/faq"}
                    className={"header-pages"}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      className={"text-upper btn"}
                      style={{ color: colors }}
                      variant="text"
                    >
                      {t("pages.header.faq")}
                    </button>
                  </NavLink>
                  <NavLink
                    to={"/contact"}
                    className={"header-pages"}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      className={"text-upper btn"}
                      style={{ color: colors }}
                      variant="text"
                    >
                      {t("pages.header.contact")}
                    </button>
                  </NavLink>
                  <Dropdown
                    className="language-dropdown "
                    align="end"
                    id="dropdown-menu-align-end"
                  >
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      style={{
                        color: theme.primaryBtn.background,
                        background: "transparent",
                      }}
                    >
                      <i className="mdi mdi-earth" /> {props?.language}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Header>
                        {t("pages.header.select-lang")}
                      </Dropdown.Header>
                      <Dropdown.Item onClick={() => changeChange("En")}>
                        <img src={Uk} alt={"."} className={"flag-en"} />
                        English - En
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => changeChange("Sp")}>
                        <img src={Spanish} alt={"."} className={"flag-en"} />
                        Spanish - Sp
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {props.access ? (
                    <>
                      <Dropdown className="dropdown-user" align={"end"}>
                        <Dropdown.Toggle
                          className={""}
                          id="dropdown-basic"
                          style={{
                            color: theme.primaryBtn.background,
                            background: "transparent",
                          }}
                        >
                          <div className={"user_circle"}>
                            <img src={userDetail?.url+userDetail?.profile_pic} onError={(event) => { event.target.src = user_circle}} alt={"."} />
                          </div>
                          {userDetail?.first_name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            style={{ color: theme?.primaryBtn?.color }}
                            onClick={() =>{ 
                                  props.setShowpackagelist(false)
                                  navigate("/my-account/profile")
                                }
                            }
                          >
                            <div className={"notification_icon"}>
                              <img src={user_icon_black} alt={"."} />
                            </div>
                            {t("pages.header.my-account")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{ color: theme?.primaryBtn?.color }}
                            onClick={() => {
                              props.setShowpackagelist(false)
                              navigate("/my-account/notification")
                            }}
                          >
                            <div className={"notification_icon"}>
                              <img src={notifications} alt={"."} />
                            </div>
                            {t("pages.header.notification")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{ color: theme?.primaryBtn?.color }}
                            onClick={(e) => callLogout(e)}
                          >
                            <div className={"notification_icon"}>
                              <i className="fa fa-sign-out" />
                            </div>
                            {t("pages.header.log-out")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : (
                    <>
                      <Link to={"/register"} style={{ textDecoration: "none" }}>
                        <button
                          className={"text-upper btn register"}
                          style={{ color: colors, textDecoration: "underline" }}
                          variant="text"
                        >
                          {t("pages.header.register")}
                        </button>
                      </Link>
                      <Link to={"/login"} style={{ textDecoration: "none" }}>
                        <button
                          className={"text-upper btn login-btn"}
                          style={theme.headerBtn}
                        >
                          {t("pages.header.login")}
                        </button>
                      </Link>
                      <Link
                        to={"/specialist"}
                        style={{ textDecoration: "none" }}
                      >
                        <button className={"text-upper specialist-btn"}>
                          {t("pages.header.specialist")}
                        </button>
                      </Link>
                    </>
                  )}
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  theme: state.theme.primaryTheme,
  access: state?.login?.access,
  showPackagelist: state?.home?.showPackagelist,
  language: state?.theme?.language,
});
export default connect(mapStateToProps, {
  setShowpackagelist,
  fetchChangeLanguage,
  Logout
})(HeaderPage);
