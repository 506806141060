import React, { useState, useEffect } from "react";
import "./RequestPage.css";
import { connect } from "react-redux";
import arrow_up from "./../../../assets/arrow_up.svg";
import arrow_down from "./../../../assets/arrow_down.svg";
import pdf from "./../../../assets/pdf_doc.png";
//import attached_file from "./../../../assets/attached_file.png";
import close_icon from "./../../../assets/close_icon.png";
import { Container, Table, Row } from "react-bootstrap";
import { getOrderlist } from "../../details/util/orderList";
import ViewerPage from "../../modal/components/ViewerPage";
import video_icon from "../../../assets/video_icon.png";
import AddreviewPage from "../../modal/components/AddreviewPage";
import { useTranslation } from "react-i18next";
import Dropzone from 'react-dropzone'
import { uploadPaymentreceipt } from "../util/uploadPaymentreceipt";
import { updatePaymentreceipt } from "../util/updatePaymentreceipt";
import { toast } from "react-toastify";

function RequestPage(props) {
  const [selectedIndex, setSelectedindex] = useState();
  const [orderlist, setOrderlist] = useState([]);
  const [showView, setShowview] = useState(false);

  const [showreview, setShowreview] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [mediaArray, setMediaarray] = useState([]);

  const [mediaImages, setMediaimages] = useState([]);
  const [mediaVideos, setMediavideos] = useState([]);
  const [mediaDocuments, setMediadocuments] = useState([]);

  const [nodata, setNodata] = useState(false);

  const [documentUrl, setDocumenturl] = useState([]);

  const [requestId, setRequestid] = useState();

  const [paymentreceipt, setPaymentreceipt] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (props.tabkey === "request") {
      getRequestlist();
    }
    // eslint-disable-next-line
  }, [props.tabkey]);

  const getRequestlist = async () => {
    let getOrderlistresponse = await getOrderlist(props.access, "");
    if (getOrderlistresponse.status === 200) {
      let getResponse = await getOrderlistresponse.json();
      getResponse = Object.values(getResponse);

      if (getResponse.length > 0) {
        setOrderlist(getResponse);
        getRequestdetail(0, getResponse);
        setNodata(false);
      } else {
        setNodata(true);
      }
    }
  };

  const showOrderdetail = async (getIndex, list) => {
    if (getIndex === selectedIndex) {
      setSelectedindex();
    } else {
      getRequestdetail(getIndex, list);
      setPaymentreceipt("");
    }
  };

  const getRequestdetail = async (getIndex, list) => {
    let getOrder = list.filter((item, index) => index === getIndex);
    let getOrderlistresponse = await getOrderlist(
      props.access,
      getOrder[0]?.id
    );
    if (getOrderlistresponse.status === 200) {
      let getResponse = await getOrderlistresponse.json();
     // console.log("response", getResponse);
      let fileObject = {};
      let imagesList = [];
      let videosList = [];
      if(getResponse?.data?.base_url && getResponse?.data?.payment_receipt){
        setPaymentreceipt(getResponse?.data?.base_url+getResponse?.data?.payment_receipt)
      }else{

        getResponse?.data?.payment_receipt&&setPaymentreceipt(getResponse?.data?.payment_receipt_base_url+getResponse?.data?.payment_receipt)
        //https://bcd.appristine.in/media/payment_receipt

      }
      getResponse?.data?.order_media_images?.forEach((item) => {
        fileObject = {
          url: item.path,
          type: "photo",
          altTag: "some image",
        };
        imagesList.push(fileObject);
      });
      setMediaimages(imagesList);
      setMediadocuments(getResponse?.data?.order_media_documents);
      getResponse?.data?.order_media_videos?.forEach((item) => {
        fileObject = {
          url: item.path,
          type: "video",
          altTag: "some image",
        };
        videosList.push(fileObject);
      });
      setMediavideos(videosList);
    }
    setSelectedindex(getIndex);
  };

  const openPdf = (item) => {
    let docs = [];
    docs.push({
      uri: item?.path,
    });
    setDocumenturl(docs);
    setShowview(true);
  };

  const openImageViewer = (index) => {
    setCurrentIndex(index);
    setMediaarray(mediaImages);
    setShowview(true);
  };

  const openVideoViewer = (index) => {
    setCurrentIndex(index);
    setMediaarray(mediaVideos);
    setShowview(true);
  };

  const openReview = (e, item) => {
    e.preventDefault();
    setRequestid(item?.id);
    setShowreview(true);
  };

  const receiptChange = async (e, item, list, index) => {
    const imageUpload = e?.target?.files[0] || e[0];
    const file_name =  imageUpload.name.substring(imageUpload.name.lastIndexOf('.') + 1).toLowerCase() 
    if(
      file_name==="png"||
      file_name==="jpg"||
      file_name==="jpeg"||
      file_name==="apng"||
      file_name==="svg"
    ){
      const responce = await uploadPaymentreceipt(props.access,imageUpload,item.payment_details[0].id)
      if(responce.status===201){
        const result = await responce.json()
        const data = {
          order_id:item.id,
          pay_receipt:result.data[0].name
        }
        const responceUpdateOrder = await updatePaymentreceipt(props.access, data);
        await responceUpdateOrder.json();
       // console.log(resutlUpdateOrder);
        getRequestdetail(index,list);
      }
    }else{
      toast.error(props.language==="Sp"?"geçersiz dosya":"invalid type file")
    }
    // let url = URL.createObjectURL(imageUpload);
    // console.log(url);
    // setPaymentreceipt(url);
  };

  const closeImage = () => {
    setPaymentreceipt("");
  };

  return (
    <div className={"request"}>
      {nodata ? (
        <div className="nodata">
          <h5>{t("pages.no-data")}</h5>
        </div>
      ) : (
        <Container>
          <div className={"req-list desktop"}>
            {orderlist.map((item, index) => (
              <div key={index.toString()} className={"req-list-div "}>
                <div>
                  <div
                    className={
                      selectedIndex === index
                        ? "collapsible active"
                        : "collapsible"
                    }
                  >
                    <div>
                      <Table size="sm" borderless className={"request-list"}>
                        <thead>
                          <tr>
                            <th>{t("pages.request.requst-id")}</th>
                            {/* <th>{t("pages.request.payment-id")}</th> */}
                            <th>{t("pages.request.payment-amount")}</th>
                            <th
                              rowSpan={4}
                              style={{ verticalAlign: "middle", width: "100%" }}
                              align={"right"}
                              colSpan={2}
                            >
                              <div className={"table-status"}>
                                <div
                                  className={"review-btn"}
                                  onClick={(e) => openReview(e, item)}
                                >
                                  {item?.user_feedback_rating?.length === 0
                                    ? t("pages.request.add-review")
                                    : t("pages.request.view-review")}
                                </div>
                                <div className={"progress-section"}>
                                  <div className={"progress-btn  processing"}>
                                    {item?.current_status
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      item?.current_status?.slice(1)}
                                  </div>
                                </div>
                                <div
                                  className={"arrow-section"}
                                  onClick={() =>
                                    showOrderdetail(index, orderlist)
                                  }
                                >
                                  {selectedIndex === index ? (
                                    <img src={arrow_up} alt={"."} />
                                  ) : (
                                    <img src={arrow_down} alt={"."} />
                                  )}
                                </div>
                              </div>
                            </th>
                          </tr>
                          <tr className={"primaryTr"}>
                            <td>{item?.order_id}</td>
                            {/* <td>3535DRFERTYR231Q</td> */}
                            <td>{item?.final_amount}</td>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </Table>
                    </div>
                  </div>
                  <div className={"collapsible-bottom "}>
                    <Table size="sm" borderless className={"request-list"}>
                      <thead>
                        <tr>
                          <th>{t("pages.request.brand")}</th>
                          <th>{t("pages.request.car-type")}</th>
                          <th>{t("pages.request.colour")}</th>
                          <th>{t("pages.request.number")}</th>
                          <th>{t("pages.request.type")}</th>
                        </tr>
                        <tr className={"primaryTr"}>
                          <td>{item?.order_details[0]?.vehicle_brand}</td>
                          <td>{item?.order_details[0]?.vehicle_type}</td>
                          <td>{item?.order_details[0]?.vehicle_color}</td>
                          <td>
                            {item?.order_details[0]?.vehicle_number_plate}
                          </td>
                          <td>{item?.o_type}</td>
                        </tr>
                      </thead>
                    </Table>
                    <div className={"content"}>
                      <Table
                        size="sm"
                        borderless
                        className={"request-list-collapse mt-2"}
                      >
                        <thead>
                          <tr>
                            <th>{t("pages.request.power")}</th>
                          </tr>
                          <tr className={"primaryTr"}>
                            <td>{item?.order_details[0]?.vehicle_power}</td>
                          </tr>
                          {item.payment_status!=="done"&&
                          <tr>
                            <th>
                              {t("pages.request.receipt")}
                              <span className="pay-whno">+919860274142</span>
                            </th>
                          </tr>
                          }
                          {item.payment_status!=="done"&&
                          <tr>
                            <td className="pt-2">
                              {paymentreceipt === "" ? (
                                <div className="admin container upload-payment-ui">
                                 
                                  <Dropzone onChange={(e) => receiptChange(e, item, orderlist,index)} onDrop={(e) => receiptChange(e, item, orderlist,index)}>
                                    {({getRootProps, getInputProps}) => (
                                      <section className="upload-ui-section">
                                        <div className="upload-ui-inner" {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          <div className="upload-ui-div ">
                                            <i className="fa fa-cloud-upload" />
                                            <span className="drag-upload-title">{t("pages.request.upload-titles")}</span>
                                            <span className="OR">{t("pages.request.OR")}</span>
                                            <button className="button-browse">{t("pages.request.browse")}</button>
                                          </div>
                                        </div>
                                      </section>
                                    )}
                                  </Dropzone>
                                </div>
                              ) : (
                                <div className="container upload-image-ui">
                                   <div className="p-4 px-0">
                                    <div className="receipt">
                                      <img
                                        src={close_icon}
                                        alt=""
                                        className="close_img"
                                        onClick={() => closeImage()}
                                      />
                                      <img
                                        src={paymentreceipt}
                                        className="receipt_img"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                          }
                          {mediaImages.length > 0 && (
                            <>
                              <tr>
                                <th>{t("pages.request.images")}</th>
                              </tr>
                              <tr className={"primaryTr"}>
                                <td>
                                  <div className={"image-data-list"}>
                                    {mediaImages.map((src, index) => (
                                      <div key={index.toString()} className={"image-list"}>
                                        <img
                                          src={src.url}
                                          alt={"."}
                                          onClick={() => openImageViewer(index)}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                          {mediaVideos.length > 0 && (
                            <>
                              <tr>
                                <th>{t("pages.request.video")}</th>
                              </tr>
                              <tr className={"primaryTr"}>
                                <td>
                                  <div className={"image-data-list"}>
                                    {mediaVideos.map((item, index) => (
                                      <div key={index.toString()} className={"video-list"}>
                                        <img
                                          src={video_icon}
                                          alt={"."}
                                          onClick={() => openVideoViewer(index)}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                          {mediaDocuments.length > 0 && (
                            <>
                              <tr>
                                <th>{t("pages.request.pdf")}</th>
                              </tr>
                              <tr className={"primaryTr"}>
                                <td>
                                  <div className={"image-data-list"}>
                                    {mediaDocuments.map((item, index) => (
                                      <div key={index.toString()}
                                        className={"pdf-list"}
                                        onClick={() => openPdf(item)}
                                      >
                                        <img src={pdf} alt={"."} />
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </thead>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className={" req-list mobile"}>
            {orderlist.map((item, index) => (
              <div key={index.toString()} className={"req-list-div "}>
                <div>
                  <div
                    className={
                      selectedIndex === index
                        ? "collapsible active"
                        : "collapsible"
                    }
                  >
                    <div onClick={() => showOrderdetail(index, orderlist)}>
                      <Row>
                        <div className={"col-12"}>
                          <label className={"secound flex-1"}>
                            {t("pages.request.requst-id")}
                          </label>
                          <label className={"primary flex-1"}>
                            {item?.order_id}
                          </label>
                        </div>
                        
                      </Row>
                      <Row className={"pt-2"} hidden>
                        <div className={"col"}>
                          {/* <label className={"secound flex-1"}>
                            {t("pages.request.payment-id")}
                          </label>
                          <label className={"primary flex-1"}>
                            1454ACVGSDRYGV
                          </label> */}
                        </div>
                      </Row>
                      <Row className={"pt-2"}>
                        <div className={"col"}>
                          <label className={"secound flex-1"}>
                            {t("pages.request.payment-amount")}
                          </label>
                          <label className={"primary flex-1"}>
                            {item?.final_amount}
                          </label>
                        </div>
                      </Row>
                      <div className={"col-12 status"}>
                          <div className={"table-status"}>
                            <div className={"progress-section"}>
                              <div
                                className={"review-btn"}
                                onClick={(e) => openReview(e, item)}
                              >
                                {item?.user_feedback_rating?.length === 0
                                  ? t("pages.request.add-review")
                                  : t("pages.request.view-review")}
                              </div>
                              <div className={"progress-btn  processing"}>
                                {item?.current_status?.charAt(0).toUpperCase() +
                                  item?.current_status?.slice(1)}
                              </div>
                            </div>
                            <div className={"arrow-section"}>
                              {selectedIndex === index ? (
                                <img src={arrow_up} alt={"."} />
                              ) : (
                                <img src={arrow_down} alt={"."} />
                              )}
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className={"collapsible-bottom "}>
                    <div className={"request-list"}>
                      <Row>
                        <div className={"col-6"}>
                          <label className={"secound flex-1"}>
                            {t("pages.request.brand")}
                          </label>
                          <label className={"primary flex-1"}>
                            {item?.order_details[0]?.vehicle_brand}
                          </label>
                        </div>
                        <div className={"col"}>
                          <label className={"secound flex-1"}>
                            {t("pages.request.car-type")}
                          </label>
                          <label className={"primary flex-1"}>
                            {item?.order_details[0]?.vehicle_type}
                          </label>
                        </div>
                      </Row>
                      <Row className={"pt-2"}>
                        <div className={"col"}>
                          <label className={"secound flex-1"}>
                            {t("pages.request.colour")}
                          </label>
                          <label className={"primary flex-1"}>
                            {item?.order_details[0]?.vehicle_color}
                          </label>
                        </div>
                        <div className={"col"}>
                          <label className={"secound flex-1"}>
                            {t("pages.request.number")}
                          </label>
                          <label className={"primary flex-1"}>
                            {item?.order_details[0]?.vehicle_number_plate}
                          </label>
                        </div>
                      </Row>
                      <Row className={"pt-2"}>
                        <div className={"col"}>
                          <label className={"secound flex-1"}>
                            {t("pages.request.type")}
                          </label>
                          <label className={"primary flex-1"}>
                            {item?.o_type}
                          </label>
                        </div>
                        <div className={"col"}>
                          <label className={"secound flex-1"}>
                            {t("pages.request.power")}
                          </label>
                          <label className={"primary flex-1"}>
                            {item?.order_details[0]?.vehicle_power}
                          </label>
                        </div>
                      </Row>
                    </div>
                    <div className={"content"}>
                      <Table
                        size="sm"
                        borderless
                        className={"request-list-collapse mt-2"}
                      >
                        <thead>
                          <tr>
                            <th>{t("pages.request.power")}</th>
                          </tr>
                          <tr className={"primaryTr"}>
                            <td>{item?.order_details[0]?.vehicle_power}</td>
                          </tr>
                          <tr>
                            <th className="recipt-mobile">
                              {t("pages.request.receipt")}
                              <span className="pay-whno">+919860274142</span>
                            </th>
                          </tr>
                          <tr>
                            <td className="pt-2">
                              {paymentreceipt === "" ? (
                                <div className="admin container upload-payment-ui">
                                 
                                  <Dropzone onChange={(e) => receiptChange(e, item, orderlist,index)} onDrop={(e) => receiptChange(e, item, orderlist,index)}>
                                    {({getRootProps, getInputProps}) => (
                                      <section className="upload-ui-section">
                                        <div className="upload-ui-inner" {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          <div className="upload-ui-div ">
                                            <i className="fa fa-cloud-upload" />
                                            <span className="drag-upload-title">{t("pages.request.upload-titles")}</span>
                                            <span className="OR">{t("pages.request.OR")}</span>
                                            <button className="button-browse">{t("pages.request.browse")}</button>
                                          </div>
                                        </div>
                                      </section>
                                    )}
                                  </Dropzone>
                                </div>
                              ) : (
                                <div className="container upload-image-ui">
                                   <div className="p-4 px-2">
                                    <div className="receipt">
                                      <img
                                        src={close_icon}
                                        alt=""
                                        className="close_img"
                                        onClick={() => closeImage()}
                                      />
                                      <img
                                        src={paymentreceipt}
                                        className="receipt_img"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                          {mediaImages.length > 0 && (
                            <>
                              <tr>
                                <th>{t("pages.request.images")}</th>
                              </tr>
                              <tr className={"primaryTr"}>
                                <td>
                                  <div className={"image-data-list"}>
                                    {mediaImages.map((src, index) => (
                                      <div key={index.toString()} className={"image-list"}>
                                        <img
                                          src={src.url}
                                          alt={"."}
                                          onClick={() => openImageViewer(index)}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                          {mediaVideos.length > 0 && (
                            <>
                              <tr>
                                <th>{t("pages.request.video")}</th>
                              </tr>
                              <tr className={"primaryTr"}>
                                <td>
                                  <div className={"image-data-list"}>
                                    {mediaVideos.map((item, index) => (
                                      <div key={index.toString()} className={"video-list"}>
                                        <img
                                          src={video_icon}
                                          alt={"."}
                                          onClick={() => openVideoViewer(index)}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                          {mediaDocuments.length > 0 && (
                            <>
                              <tr>
                                <th>{t("pages.request.pdf")}</th>
                              </tr>
                              <tr className={"primaryTr"}>
                                <td>
                                  <div className={"image-data-list"}>
                                    {mediaDocuments.map((item ,index1) => (
                                      <div key={index1.toString()}
                                        className={"pdf-list"}
                                        onClick={(e) => openPdf(item)}
                                      >
                                        <img src={pdf} alt={"."} />
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </thead>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      )}
      <AddreviewPage
        orderlist={orderlist}
        setOrderlist={setOrderlist}
        requestId={requestId}
        showreview={showreview}
        setShowreview={setShowreview}
      />
      <ViewerPage
        showView={showView}
        setShowview={setShowview}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        mediaArray={mediaArray}
        setMediaarray={setMediaarray}
        documentUrl={documentUrl}
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  access: state?.login?.access,
  language: state?.theme?.language,
});
export default connect(mapStateToProps)(RequestPage);
