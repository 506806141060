import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { addTermsandcondition } from '../util/addterms';
import "./AddtermsPage.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from 'draft-js';
import { Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import draftjsToHtml from 'draftjs-to-html';
import { getTermsdetail } from '../../../termsandconditions/util/getTerms';
import { convertFromRaw } from 'draft-js';
import { updateTermsandcondition } from '../util/updateterms';
import LoaderPage from '../../loader/components/LoaderPage';

function AddtermsPage(props) {
    const [tabKey, setTabKey] = useState("english");
    const [termsState, setTermsState] = useState("Update");
    const [termInputState, setTermsInputState] = useState("Read");
    const [termsList, settermsList] = useState([]);
    const [termsInput,setTermsInput] = useState({
        title:"",
        title_sp:"",
    });
    const [description, setDescription] = useState(() =>EditorState.createEmpty());
    const [loadState, setLoadState] = useState(true)
    const [description_sp, setDescription_sp] = useState(() =>
        EditorState.createEmpty()
    );
    useEffect(()=>{
        callGetTerms()
    // eslint-disable-next-line
    },[])

//   useEffect(() => {
//     console.log(convertToRaw(description.getCurrentContent()));
//   }, [description]);

    const callAddterms = async (e) =>{
        e.preventDefault();
        if(termsInput.title.length>0&&termsInput.title_sp.length>0){
            const data = {
                title:termsInput.title,
                description:JSON.stringify(convertToRaw(description.getCurrentContent())),
                title_sp:termsInput.title_sp,
                description_sp:JSON.stringify(convertToRaw(description_sp.getCurrentContent()))
            }
            const aboutResponce = await addTermsandcondition(props.access,data);
            if(aboutResponce.status === 201){
                 await aboutResponce.json();
               // console.log(aboutResult);
                setDescription(()=>EditorState.createEmpty())
                setDescription_sp(()=>EditorState.createEmpty())
                setTermsInput({title:"", title_sp:""})
                toast("Successfull added Terms and condition content")
            }
        }else{
            toast.error(termsInput.title===""?"title field is empty":"spanish title field is empty")
        }
    }
    const callGetTerms = async () => {
        const responceAbout = await getTermsdetail();
        if(responceAbout.status === 200){
            const resultAbout = await responceAbout.json();
            settermsList(resultAbout)
            const convertedState = convertFromRaw(JSON.parse(resultAbout[0].description))
            const convertedState_sp = convertFromRaw(JSON.parse(resultAbout[0].description_sp))
            setDescription(()=>EditorState.createWithContent(convertedState))
            setDescription_sp(()=>EditorState.createWithContent(convertedState_sp))
            setTermsInput({
                title:resultAbout[0]?.title,
                title_sp:resultAbout[0]?.title_sp,
                id:resultAbout[0]?.id
            })
           // console.log(resultAbout)
            setTermsInputState("Read");
            setTermsState("Update")
        }else{
            setDescription_sp(()=>EditorState.createEmpty())
            setTermsState("Save");
            setTermsInputState("Write")
        }
    setTimeout(()=>setLoadState(false), 100)
    }
    const callUpdateTerms = async (e) =>{
        e.preventDefault();
        if(termsInput.title.length>0&&termsInput.title_sp.length>0){
            const data = {
                title:termsInput.title,
                description:JSON.stringify(convertToRaw(description.getCurrentContent())),
                title_sp:termsInput.title_sp,
                description_sp:JSON.stringify(convertToRaw(description_sp.getCurrentContent())),
                id:termsInput.id
            }
            const aboutResponce = await updateTermsandcondition(props.access,data);
            if(aboutResponce.status === 200){
                await aboutResponce.json();
               // console.log(aboutResult);
                setDescription(()=>EditorState.createEmpty())
                setDescription_sp(()=>EditorState.createEmpty())
                callGetTerms()
                toast("Successfull Updated Terms and condition content")
            }
        }else{
            toast.error(termsInput.title===""?"title field is empty":"spanish title field is empty")
        }
    }

  if(loadState) 
    return <LoaderPage/>
  return (
    <div className='terms-contents'>
       <div class="admin content-page">
            <div class="admin content">
                <div class="admin container-fluid">
                    <div class="admin row">
                        {termsState==="Update" && termInputState ==="Write"?
                        <div class="admin col-12 ">
                            <div class="admin card mt-3">
                                <div class="admin card-body">
                                    {termsState==="Update"&&<div className='col terms-display-right'>
                                        <button onClick={()=>setTermsInputState("Read")}>
                                            <i className='fa fa-times' />
                                        </button>
                                    </div>}
                                    <form onSubmit={termsState==="Save"?callAddterms:callUpdateTerms}>
                                    <Tabs id={`controlled-tab-example`}  activeKey={tabKey} onSelect={(k) => setTabKey(k)} className="admin nav nav-tabs total-cnt about-tab" variant={"pills tabs-inner-div"}>
                                        <Tab className="admin tab-pane  inbox-widget" tabClassName='hi' eventKey="english" title={"English"}>
                                            <div class="admin row mt-3">
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="title" class="admin form-label">Title</label>
                                                    <input value={termsInput.title} onChange={(e)=>setTermsInput((res)=>({...res,[e.target.name]:e.target.value}))} type="text" id="title" name="title" class="admin form-control" placeholder="Enter title hear...." />
                                                </div>
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="description" class="admin form-label">Desription</label>
                                                    <Editor
                                                        defaultEditorState={description}
                                                        editorState={description}
                                                        onEditorStateChange={setDescription}
                                                        toolbarClassName="toolbarClassName b-0"
                                                        wrapperClassName="rich-editor border rounded-3 mt-2"
                                                        editorClassName="editorClassName px-3"
                                                        placeholder='Enter Description hear...'
                                                    />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab className="admin tab-pane  inbox-widget" tabClassName='hi' eventKey="spanish" title={"Spanish"}>
                                             <div class="admin row mt-3">
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="title" class="admin form-label">Title</label>
                                                    <input value={termsInput.title_sp} onChange={(e)=>setTermsInput((res)=>({...res,[e.target.name]:e.target.value}))} type="text" id="title_sp" name="title_sp" class="admin form-control" placeholder="Enter spanish title hear...." />
                                                </div>
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="description" class="admin form-label">Desription</label>
                                                    <Editor
                                                        editorState={description_sp}
                                                        onEditorStateChange={setDescription_sp}
                                                        toolbarClassName="toolbarClassName b-0"
                                                        wrapperClassName="rich-editor border rounded-3 mt-2"
                                                        editorClassName="editorClassName px-3"
                                                        placeholder='Enter Spanish description hear...'
                                                    />
                                                </div>
                                                
                                            </div>
                                        </Tab>
                                    </Tabs>
                                    <div class="admin mt-3 col-sm-12 text-left col-md-12 col-lg-12 col-xl-12">
                                        <button type="submit" class="admin btn btn-warning mt-1 width-xl waves-effect waves-light">{termsState}</button>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        :
                        <div class="admin col-12">
                            <div class="admin card mt-3">
                                <div class="admin card-body">
                                <div className='row'>
                                    <div className='col terms-display-right'>
                                        <button onClick={()=>setTermsInputState("Write")}>
                                            <i className='fa fa-pencil' />{console.log(termInputState)}
                                        </button>
                                    </div>
                                </div>
                                    <Tabs id={`controlled-tab-example`}  activeKey={tabKey} onSelect={(k) => setTabKey(k)} className="admin nav nav-tabs total-cnt about-tab" variant={"pills tabs-inner-div"}>
                                        <Tab className="admin tab-pane  inbox-widget" tabClassName='hi' eventKey="english" title={"English"}>
                                            <div class="admin row mt-3">
                                                {
                                                    termsList.map((item)=>(
                                                        <div className='mb-2'>
                                                            <h6 class=" admin mt-0 mb-2 header-title">{item.title}</h6>
                                                            <div dangerouslySetInnerHTML={{__html:draftjsToHtml(JSON.parse(item.description))}}></div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </Tab>
                                        <Tab className="admin tab-pane  inbox-widget" tabClassName='hi' eventKey="spanish" title={"Spanish"}>
                                            <div class="admin row mt-3">
                                                {
                                                    termsList.map((item)=>(
                                                        <div className='mb-2'>
                                                            <h6 class=" admin mt-0 mb-2 header-title">{item.title_sp}</h6>
                                                            <div dangerouslySetInnerHTML={{__html:draftjsToHtml(JSON.parse(item.description_sp))}}></div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}
const mapStateToProps = (store) =>({
    access:store?.adminlogin?.access
})
export default connect(mapStateToProps)(AddtermsPage);