export const getPackagelist = async (access) => {
  try {
    const headers = new Headers();


    headers.append("Accept", "application/json");

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/packages/`,
      {
        headers,
        method: "GET",
      }
    );

    return getResponse;
  } catch (error) {}
};
