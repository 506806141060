export const getDashboardDetail = async (access) => {
  try {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    headers.append("Authorization", `Bearer ${access}`);

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/specialist/dashboard-data/`,
      {
        headers,
        method: "GET",
      }
    );

    console.log("Response acc", getResponse);

    return getResponse;
  } catch (error) {
    console.log("er", error);
  }
};
