import React, { useEffect, useState } from "react";
import user from "./../../../../assets/profile_pic.svg";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./DashboardPage.css";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { getDashboardDetail } from "../util/dashboard";
import { fetchAdminLogindata } from "../../login/redux/actions";
import { getRequestList } from "../../request/util/getRequest";
import { Link, NavLink, useNavigate } from "react-router-dom";
import LoaderPage from "../../loader/components/LoaderPage";

function DashboardPage(props) {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState(null);
  const [requestList, setRequestList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);
  const [specialList, setSpecialList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [tabKey, setTabKey] = useState("buyers");
  const [loadState, setLoadState] = useState(true)
  useEffect(() => {
    callDashboardDetail();

    // eslint-disable-next-line
  }, []);
  const callRequestDetails = async () => {
    //console.log("Dashboard admin");
    const requestResponce = await getRequestList(props.access);
    if (requestResponce.status === 200) {
      const requestResult = await requestResponce.json();
     // console.log(Object.values(requestResult));
      setRequestList(Object.values(requestResult));
    }
    setTimeout(()=>setLoadState(false), 100)
  };
  const callDashboardDetail = async () => {
    callRequestDetails();
    const Dashboardresponce = await getDashboardDetail(props?.access);
    if (Dashboardresponce.status === 200) {
      const Dashboardresult = await Dashboardresponce.json();
      setDashboardData(Dashboardresult);
      setBuyerList(Dashboardresult?.buyer_list);
      setSpecialList(Dashboardresult?.specialist_list);
      setSellerList(Dashboardresult?.seller_list);
    } else {
     await Dashboardresponce.json();
     // console.log(Dashboardresult, props);
      props.fetchAdminLogindata("");

      navigate("/bc-admin");
    }
  };

  if(loadState) 
    return <LoaderPage/>
  return (
    <div className="admin-dash-main admin-content">
      <div class="admin content-page">
        <div class="admin content">
          <div class="admin container-fluid">
            <div class="admin row">
              <div class="admin col-xl-3 col-md-6">
                <NavLink
                  to={"/bc-admin/buyer"}
                  className={"text-decoration-none"}
                >
                  <div class="admin card">
                    <div class="admin card-body">
                      <h4 class="admin header-title mt-0 mb-4">Total Buyer</h4>

                      <div class="admin widget-chart-1">
                        <div
                          class="admin widget-chart-box-1 float-start"
                          dir="ltr"
                        >
                          <CircularProgressbar
                            className="progress-circles"
                            value={58}
                            text={`${58}%`}
                            styles={buildStyles({
                              rotation: 0.5,
                              strokeLinecap: "butt",
                              textSize: "20px",
                              pathTransitionDuration: 0.5,
                              pathColor: `#f05050`,
                              textColor: "#f9b9b9",
                              trailColor: "#f9b9b9",
                              backgroundColor: "#3e98c7",
                            })}
                          />
                        </div>

                        <div class="admin widget-detail-1 text-end">
                          <h2 class="admin fw-normal pt-2 mb-1">
                            {" "}
                            {dashboardData?.total_buyer}{" "}
                          </h2>
                          <p style={{visibility:"hidden"}} class="admin text-muted mb-1 fw-5">
                            Revenue today
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div class="admin col-xl-3 col-md-6">
                <NavLink
                  to={"/bc-admin/seller"}
                  className={"text-decoration-none"}
                >
                  <div class="admin card">
                    <div class="admin card-body">
                      <h4 class="admin header-title mt-0 mb-4">Total Seller</h4>

                      <div class="admin widget-chart-1">
                        <div
                          class="admin widget-chart-box-1 float-start"
                          dir="ltr"
                        >
                          <CircularProgressbar
                            className="progress-circles"
                            value={40}
                            text={`${40}`}
                            styles={buildStyles({
                              rotation: 0.5,
                              strokeLinecap: "butt",
                              textSize: "20px",
                              pathTransitionDuration: 0.5,
                              pathColor: `#ffbd4a`,
                              textColor: "#ffbd4a",
                              trailColor: "#ffe6ba",
                              backgroundColor: "#ffe6ba",
                            })}
                          />
                        </div>
                        <div class="admin widget-detail-1 text-end">
                          <h2 class="admin fw-normal pt-2 mb-1">
                            {" "}
                            {dashboardData?.total_seller}{" "}
                          </h2>
                          <p style={{visibility:"hidden"}} class="admin text-muted mb-1 fw-5">
                            Revenue today
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div class="admin col-xl-3 col-md-6">
                <NavLink
                  to={"/bc-admin/specialist"}
                  className={"text-decoration-none"}
                >
                  <div class="admin card">
                    <div class="admin card-body">
                      <h4 class="admin header-title mt-0 mb-3">
                        Total Specialist
                      </h4>

                      <div class="admin widget-box-2">
                        <div class="admin widget-detail-2 text-end">
                          <span class="admin badge bg-success rounded-pill float-start mt-3">
                            30% <i class="admin mdi mdi-trending-up"></i>{" "}
                          </span>
                          <h2 class="admin fw-normal mb-1">
                            {" "}
                            {dashboardData?.total_specialist}{" "}
                          </h2>
                          <p style={{visibility:"hidden"}} class="admin text-muted mb-3 fw-5">
                            Revenue today
                          </p>
                        </div>
                        <div class="admin progress progress-bar-alt-success progress-sm">
                          <div
                            class="admin progress-bar bg-success"
                            aria-valuenow="30"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "30%" }}
                          >
                            <span class="admin visually-hidden">
                              30% Complete
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div class="admin col-xl-3 col-md-6">
                <NavLink
                  to={"/bc-admin/request"}
                  className={"text-decoration-none"}
                >
                  <div class="admin card">
                    <div class="admin card-body">
                      <h4 class="admin header-title mt-0 mb-3">
                        Total Request
                      </h4>

                      <div class="admin widget-box-2">
                        <div class="admin widget-detail-2 text-end">
                          <span class="admin badge bg-pink rounded-pill float-start mt-3">
                            30% <i class="admin mdi mdi-trending-up"></i>{" "}
                          </span>
                          <h2 class="admin fw-normal mb-1">
                            {" "}
                            {dashboardData?.total_orders}{" "}
                          </h2>
                          <p style={{visibility:"hidden"}} class="admin text-muted mb-3 fw-5">
                            Revenue today
                          </p>
                        </div>
                        <div class="admin progress progress-bar-alt-pink progress-sm">
                          <div
                            class="admin progress-bar bg-pink"
                            role="progressbar"
                            aria-valuenow="30"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "30%" }}
                          >
                            <span class="admin visually-hidden">
                              30% Complete
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div class="admin row request-lists">
              <div class="admin col-6">
                <h4 className="row-title">Request</h4>
              </div>
              <div class="admin col-6 text-end text-decoration-underline">
                {" "}
                <NavLink
                  to={"/bc-admin/request"}
                  className="admin btn-link text-secondary fw-5"
                >
                  View All Request
                </NavLink>
              </div>
              {requestList?.map(
                (item, index) =>
                  index < 4 && (
                    <div class="admin col-xl-3 col-md-6">
                      <div class="admin card">
                        <div class="admin card-body widget-user">
                          <div class="admin d-flex align-items-center">
                            <div class="admin flex-shrink-0 avatar-lg me-2">
                              <img
                                src={item?.user_details[0]?.profile_pic ? props?.profile_url+item?.user_details[0]?.profile_pic :user}
                                class="admin img-fluid rounded-circle"
                                alt="user"
                              />
                            </div>
                            <div class="admin flex-grow-1 overflow-hidden">
                              <h5 class="admin mt-0 mb-1">
                                {item?.user_details[0]?.first_name}
                              </h5>
                              <p class="admin text-muted mb-2 font-13 text-truncate">
                                {item?.user_details[0]?.email}
                              </p>
                              <NavLink
                                to={`/bc-admin/request/${item?.id}`}
                                className={"text-decoration-none"}
                              >
                                <button
                                  type="button"
                                  class="admin btn btn-request rounded-pill waves-effect waves-light"
                                >
                                  Request
                                </button>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
            <div class="admin row">
              <div class="admin col-xl-4">
                <div class="admin card">
                  <div class="admin card-body">
                    <Tabs
                      id={`controlled-tab-example`}
                      activeKey={tabKey}
                      onSelect={(k) => setTabKey(k)}
                      className="admin nav nav-tabs total-cnt nav-bordered"
                      variant={"pills tabs-inner-div"}
                    >
                      <Tab
                        className="admin tab-pane  inbox-widget"
                        tabClassName="hi"
                        eventKey="buyers"
                        title={
                          <div data-bs-toggle="tab" class="admin header-title">
                            Buyer
                          </div>
                        }
                      >
                        {buyerList.length !== 0 ? (
                          buyerList.map(
                            (item) =>
                              buyerList.length < 10 && (
                                <div class="admin inbox-item">
                                  <a href>
                                    <div class="admin inbox-item-img">
                                      <img
                                        src={item?.profile_pic? props?.profile_url+item?.profile_pic :user}
                                        class="admin rounded-circle"
                                        alt=""
                                      />
                                    </div>
                                    <h5 class="admin inbox-item-author mt-0 mb-1">
                                      {item?.first_name}
                                    </h5>
                                    <p class="admin inbox-item-text">
                                      {item?.mobile}
                                    </p>
                                    <p class="admin inbox-item-date" hidden>
                                      13:40 PM
                                    </p>
                                  </a>
                                </div>
                              )
                          )
                        ) : (
                          <div class="admin inbox-item">
                            <a href>
                              <center>
                                <h6 class="admin inbox-item-text">
                                  No Data found
                                </h6>
                              </center>
                            </a>
                          </div>
                        )}
                          <Link
                            className="text-viewall text-decoration-none"
                            to={"/bc-admin/buyer"}
                          >
                            View All
                          </Link>
                      </Tab>
                      <Tab
                        className="admin tab-pane  inbox-widget"
                        tabClassName="hi"
                        eventKey="sellers"
                        title={
                          <div data-bs-toggle="tab" class="admin header-title">
                            Seller
                          </div>
                        }
                      >
                        {sellerList.length !== 0 ? (
                          sellerList.map((item) => (
                            <div class="admin inbox-item">
                              <a href>
                                <div class="admin inbox-item-img">
                                  <img
                                    src={item?.profile_pic? props?.profile_url+item?.profile_pic :user}
                                    class="admin rounded-circle"
                                    alt=""
                                  />
                                </div>
                                <h5 class="admin inbox-item-author mt-0 mb-1">
                                  {item?.first_name}
                                </h5>
                                <p class="admin inbox-item-text">
                                  {item?.mobile}
                                </p>
                                <p class="admin inbox-item-date" hidden>
                                  13:40 PM
                                </p>
                              </a>
                            </div>
                          ))
                        ) : (
                          <div class="admin inbox-item">
                            <a href>
                              <center>
                                <h6 class="admin inbox-item-text">
                                  No Data found
                                </h6>
                              </center>
                            </a>
                          </div>
                        )}
                          <Link
                            className="text-viewall text-decoration-none"
                            to={"/bc-admin/seller"}
                          >
                            View All
                          </Link>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>

              <div class="admin col-xl-8">
                <div class="admin card">
                  <div class="admin card-body">
                    <h4 class="admin header-title mt-0 mb-3">Specialist</h4>

                    <div class="admin table-responsive">
                      <table class="admin table table-hover mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Status</th>
                            <th hidden>Assign</th>
                          </tr>
                        </thead>
                        <tbody>
                          {specialList.length !== 0 ? (
                            specialList.map((item, index) => (
                              <tr key={index.toString()}>
                                <td>{index + 1}</td>
                                <td>{item?.first_name}</td>
                                <td>{item?.email}</td>
                                <td>{item?.mobile}</td>
                                <td>
                                  <span class={item?.is_active?"admin badge bg-success":"admin badge bg-secondary"}>
                                    {item?.is_active?"Active":"Inactive"}
                                  </span>
                                </td>
                                <td hidden>Coderthemes</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6} align={"center"}>
                                No Data found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                        <Link
                          className="text-viewall text-decoration-none pt-3"
                          to={"/bc-admin/specialist"}
                        >
                          View All
                        </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (store) => ({
  access: store.adminlogin.access,
  profile_url:store.adminlogin.profile_pic_base_url,
});
export default connect(mapStateToProps, { fetchAdminLogindata })(DashboardPage);
