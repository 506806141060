import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { addPrivacy } from '../util/addprivacy';
import "./AddprivacyPage.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from 'draft-js';
import { Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getPrivacy } from '../util/getprivacy';
import draftjsToHtml from 'draftjs-to-html';
import { convertFromRaw } from 'draft-js';
import { updatePrivacy } from '../util/updateprivacy';
import LoaderPage from '../../loader/components/LoaderPage';

function AddprivacyPage(props) {
    const [tabKey, setTabKey] = useState("english");
    const [privacyList, setprivacyList] = useState([]);
    const [privacyState, setPrivacyState] = useState("Save");
    const [privacyInputState, setPrivacyInputState] = useState("Read");
    const [loadState, setLoadState] = useState(true)
    const [privacyInput,setPrivacyInput] = useState({
        title:"",
        title_sp:"",
    });
    const [description, setDescription] = useState(() =>
        EditorState.createEmpty()
    );
    const [description_sp, setDescription_sp] = useState(() =>
        EditorState.createEmpty()
    );
    useEffect(()=>{
        callGetPrivacy()
    // eslint-disable-next-line
    },[])
//   useEffect(() => {
//     console.log(convertToRaw(description.getCurrentContent()));
//   }, [description]);
    const callAddPrivacy = async (e) =>{
        e.preventDefault();
        if(privacyInput.title.length>0&&privacyInput.title_sp.length>0){
            const data = {
                title:privacyInput.title,
                description:JSON.stringify(convertToRaw(description.getCurrentContent())),
                title_sp:privacyInput.title_sp,
                description_sp:JSON.stringify(convertToRaw(description_sp.getCurrentContent()))
            }
            const aboutResponce = await addPrivacy(props.access,data);
            if(aboutResponce.status === 201){
                await aboutResponce.json();
              //  console.log(aboutResult);
                setDescription(()=>EditorState.createEmpty())
                setDescription_sp(()=>EditorState.createEmpty())
                setPrivacyInput({title:"", title_sp:""})
                toast("Successfull added Abouts content")
            }
        }else{
            toast.error(privacyInput.title===""?"English title field is empty":"Spanish title field is empty")
        }
    }
    const callGetPrivacy = async () => {
        const responceAbout = await getPrivacy();
        if(responceAbout.status === 200){
            const resultAbout = await responceAbout.json();
            const convertedState = convertFromRaw(JSON.parse(resultAbout[0].description))
            const convertedState_sp = convertFromRaw(JSON.parse(resultAbout[0].description_sp))
            setDescription(()=>EditorState.createWithContent(convertedState))
            setDescription_sp(()=>EditorState.createWithContent(convertedState_sp))
            setPrivacyInput({
                title:resultAbout[0].title,
                title_sp:resultAbout[0].title_sp,
                id:resultAbout[0].id,
            })
            setprivacyList(resultAbout)
            setPrivacyInputState("Read");
            setPrivacyState("Update");
        }else{
            setPrivacyInputState("Write");
            setPrivacyState("Save");
        }
        setTimeout(()=>setLoadState(false), 100)
    }
    const callUpdatePrivacy = async (e)=>{
        e.preventDefault();
        if(privacyInput.title.length>0&&privacyInput.title_sp.length>0){
            const data = {
                title:privacyInput.title,
                description:JSON.stringify(convertToRaw(description.getCurrentContent())),
                title_sp:privacyInput.title_sp,
                description_sp:JSON.stringify(convertToRaw(description_sp.getCurrentContent())),
                id:privacyInput.id
            }
            const aboutResponce = await updatePrivacy(props.access,data);
            if(aboutResponce.status === 200){
                 await aboutResponce.json();
               // console.log(aboutResult);
                setDescription(()=>EditorState.createEmpty())
                setDescription_sp(()=>EditorState.createEmpty())
                setPrivacyInput({title:"", title_sp:""})
                toast("Successfull added Abouts content")
                callGetPrivacy();
            }
        }else{
            toast.error(privacyInput.title===""?"English title field is empty":"Spanish title field is empty")
        }
    }

  if(loadState) 
     return <LoaderPage/>

  return (
    <div className='privacy-contents'>
       <div class="admin content-page">
            <div class="admin content">
                <div class="admin container-fluid">
                    <div class="admin row">
                       {privacyInputState==="Write"?
                        <div class="admin col-12">
                            <div class="admin card mt-3">
                                <div class="admin card-body">
                                    {privacyState==="Update"&&
                                        <div className='col privacy-display-right'>
                                            <button onClick={()=>setPrivacyInputState("Read")}>
                                                <i className='fa fa-times' />
                                            </button>
                                        </div>
                                    }
                                    <form onSubmit={privacyState==="Update"?callUpdatePrivacy:callAddPrivacy}>
                                    <Tabs id={`controlled-tab-example`}  activeKey={tabKey} onSelect={(k) => setTabKey(k)} className="admin nav nav-tabs total-cnt about-tab" variant={"pills tabs-inner-div"}>
                                        <Tab className="admin tab-pane  inbox-widget" tabClassName='hi' eventKey="english" title={"English"}>
                                            <div class="admin row mt-3">
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="title" class="admin form-label">Title</label>
                                                    <input value={privacyInput.title} onChange={(e)=>setPrivacyInput((res)=>({...res,[e.target.name]:e.target.value}))} type="text" id="title" name="title" class="admin form-control" placeholder="Enter title hear...." />
                                                </div>
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="description" class="admin form-label">Desription</label>
                                                    <Editor
                                                        editorState={description}
                                                        onEditorStateChange={setDescription}
                                                        toolbarClassName="toolbarClassName b-0"
                                                        wrapperClassName="rich-editor border rounded-3 mt-2"
                                                        editorClassName="editorClassName px-3"
                                                        placeholder='Enter Description hear...'
                                                    />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab className="admin tab-pane  inbox-widget" tabClassName='hi' eventKey="spanish" title={"Spanish"}>
                                             <div class="admin row mt-3">
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="title" class="admin form-label">Title</label>
                                                    <input value={privacyInput.title_sp} onChange={(e)=>setPrivacyInput((res)=>({...res,[e.target.name]:e.target.value}))} type="text" id="title_sp" name="title_sp" class="admin form-control" placeholder="Enter spanish title hear...." />
                                                </div>
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="description" class="admin form-label">Desription</label>
                                                    <Editor
                                                        editorState={description_sp}
                                                        onEditorStateChange={setDescription_sp}
                                                        toolbarClassName="toolbarClassName b-0"
                                                        wrapperClassName="rich-editor border rounded-3 mt-2"
                                                        editorClassName="editorClassName px-3"
                                                        placeholder='Enter Spanish description hear...'
                                                    />
                                                </div>
                                                
                                            </div>
                                        </Tab>
                                    </Tabs>
                                    <div class="admin mt-3 col-sm-12 text-left col-md-12 col-lg-12 col-xl-12">
                                        <button type="submit" class="admin btn btn-warning mt-1 width-xl waves-effect waves-light">{privacyState}</button>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        :
                        <div class="admin col-12">
                            <div class="admin card mt-3">
                                <div class="admin card-body">
                                    {privacyState==="Update"&&
                                    <div className='col privacy-display-right'>
                                        <button onClick={()=>setPrivacyInputState("Write")}>
                                            <i className='fa fa-pencil' />
                                        </button>
                                    </div>}  
                                    <Tabs id={`controlled-tab-example`}  activeKey={tabKey} onSelect={(k) => setTabKey(k)} className="admin nav nav-tabs total-cnt about-tab" variant={"pills tabs-inner-div"}>
                                        <Tab className="admin tab-pane  inbox-widget" tabClassName='hi' eventKey="english" title={"English"}>
                                                {
                                                    privacyList.map((item)=>(
                                                        <div className='mb-2'>
                                                            <h3 class="mt-3 admin mt-0 mb-2 header-title">{item.title}</h3>
                                                            <div dangerouslySetInnerHTML={{__html:draftjsToHtml(JSON.parse(item.description))}}></div>
                                                        </div>
                                                    ))
                                                }
                                        </Tab>
                                        <Tab className="admin tab-pane  inbox-widget"  tabClassName='hi' eventKey="spanish" title={"Spanish"}>
                                                {
                                                    privacyList.map((item)=>(
                                                        <div className='mb-2'>
                                                            <h3 class=" admin mt-3 mb-2 header-title">{item.title_sp}</h3>
                                                            <div dangerouslySetInnerHTML={{__html:draftjsToHtml(JSON.parse(item.description_sp))}}></div>
                                                        </div>
                                                    ))
                                                }
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}
const mapStateToProps = (store) =>({
    access:store?.adminlogin?.access
})
export default connect(mapStateToProps)(AddprivacyPage);