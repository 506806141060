import { Form } from "react-bootstrap";
import "./SendnotificationPage.css";
import { useEffect, useState } from "react";
import Select from "react-select";
import {PostNotification} from "./../util/postnotification"
import { connect } from "react-redux";
import { toast } from "react-toastify";
import LoaderPage from "../../loader/components/LoaderPage";

function SendnotificationPage(props) {
  const options = [
    { value: "buyer", label: "Buyer" },
    { value: "seller", label: "Seller" },
    { value: "specialist", label: "Specialist" },
    { value: "all", label: "All" },
  ];
  const [loadState, setLoadState] = useState(true)
  const [errors, setErrors] = useState({
    type:"",
    content:""
  });
  const [contentInput, setContentinput] = useState({
    type: null,
    content: "",
  });
  useEffect(() => {
    setTimeout(()=>setLoadState(false), 100)
    // eslint-disable-next-line
  }, []);
  const contentChange = (e) => {
    setContentinput({
      ...contentInput,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };
  const validation = () =>{
    let result = true;
    if(contentInput.type===null||!contentInput.type.value){
      errors.type = "Please select type of user"
      result = false;
    }
    if(contentInput.content===""){
      errors.content = "Enter description..."
      result = false;
    }
    setErrors({
      ...errors,
      type:errors.type,
      content:errors.content,
    })
    return result;
  }
  const callPostNotification =  async (e) =>{
    e.preventDefault();
    if(validation()){
      const data = {
        notify_to:contentInput.type.value,
        content:contentInput.content,
        notify_type:"success"
      }
      const responceNoti =await PostNotification(props.access,data)
      if(responceNoti.status===200){
        toast.success(`Your notification send to ${contentInput.type.value==="all"?"all User":`all ${contentInput.type.label}`}`)
        setContentinput({type:null,content:""})
      }else if(responceNoti.status===401){
        toast.error("your time is expired please Relogin")
      }
    }
  }

  if(loadState) 
    return <LoaderPage/>
  return (
    <div className="notification-contents">
      <div class="admin content-page">
        <div class="admin content">
          <div class="admin container-fluid">
            <div class="admin row">
              <div class="admin col-12">
                <div class="admin card mt-2">
                  <div class="admin card-body">
                    <h4 class=" admin mt-0 mb-2 header-title">
                      Send notification
                    </h4>
                    <form onSubmit={callPostNotification}>
                      <div class="admin row">
                        <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <label for="title" class="admin form-label">
                            Select type
                          </label>
                          <Select  value={contentInput.type} options={options}  onChange={(e)=>{
                              setContentinput({...contentInput,type:e})
                              setErrors({...errors,type:""})
                            }} isMulti={false} />
                          <span className="text-danger">{errors.type}</span>
                        </div>
                        <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <label for="description" class="admin form-label">
                            Description
                          </label>
                          <Form.Group className="form-group">
                            <Form.Control
                              type="text"
                              placeholder="Enter your content"
                              as="textarea"
                              rows="5"
                              name="content"
                              value={contentInput.content}
                              onChange={(e) => contentChange(e)}
                            ></Form.Control>
                          <span className="text-danger">{errors.content}</span>
                          </Form.Group>
                          
                        </div>
                        <div class="admin mt-3 col-sm-12 text-left col-md-12 col-lg-12 col-xl-12">
                          <button
                            type="submit"
                            class="admin btn btn-warning mt-1 width-xl waves-effect waves-light"
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) =>({
  access:state.adminlogin.access
})
export default connect(mapStateToProps)(SendnotificationPage);
