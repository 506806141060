export const updateSpecialistPassword = async (access, data, id) => {
    try {
      const headers = new Headers();
  
      headers.append("Authorization", `Bearer ${access}`);
  
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
  
      const getResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/update-specialist-password/${id}/`,
        {
          headers,
          method: "POST",
          body:JSON.stringify(data),
        }
      );
  
      return getResponse;
    } catch (error) {}
};