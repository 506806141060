export const getRequeststatusdetail = async (access, statusDetail) => {
  try {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    headers.append("Authorization", `Bearer ${access}`);

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/specialist/accept_reject_package/`,
      {
        headers,
        method: "POST",
        body: JSON.stringify(statusDetail),
      }
    );

    console.log("Response accept", getResponse);

    return getResponse;
  } catch (error) {
    console.log("er", error);
  }
};
