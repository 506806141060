export const getUserdetail = async (access) => {
  try {
    const headers = new Headers();

    headers.append("Authorization", `Bearer ${access}`);

    headers.append("Accept", "application/json");

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/get_user_details`,
      {
        headers,
        method: "GET",
      }
    );

    return getResponse;
  } catch (error) {}
};
