import React, { useState, useEffect } from "react";
import "./HeadernavPage.css";
import profile_pic from "./../../../../assets/profile_pic.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { fetchAdminLogindata } from "../../login/redux/actions";
import { connect } from "react-redux";
import { changeSearchInput } from "../redux/actions";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Uk from "./../../../../assets/ukflag.png";
import Spanish from "./../../../../assets/spain.png";
import { Dropdown } from "react-bootstrap";
import { fetchChangeLanguage } from "../../../header/redux/actions";
import { getNotificationlist } from "../../../notification/util/getnotification";
import moment from "moment";
import notifiyellow from "../../../../assets/notifiyellow.png";
import { getReadNotification } from "./../../../specialists/notifications/util/getreadnotification";

function HeadernavPage(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const title = window.location.pathname.substring(window.location.pathname.lastIndexOf('/')+1);
  const [headerTitle, setHeaderTitle] = useState("Dashboard");
  const Ltype = props.admin_data[0]?.user_type;
  const [notificationlist, setNotificationlist] = useState([]);
  const [searchState, setSearchState] = useState(true);

  useEffect(()=>{
    changeChange(props.language)
    // eslint-disable-next-line
  },[!props.language]);

  useEffect(()=>{
    notificationList()
    // eslint-disable-next-line
  },[])
  const changeChange = (e) =>{
    changeHeaderTitle(title)
    i18next.changeLanguage (e);
    props.fetchChangeLanguage(e)
  }
  useEffect(()=>{
    changeHeaderTitle(title)
    // eslint-disable-next-line
  },[title]);

  const changeHeaderTitle = (title) =>{
    switch(title){
      case "dashboard":
        setSearchState(false)
        Ltype!=="admin"?setHeaderTitle(t('pages.specialist.header.dashboard')): setHeaderTitle("Dashboard"); 
       break;
      case "specialist": setHeaderTitle("Specialist");
        setSearchState(true)
      break;
      case "request":Ltype!=="admin"?setHeaderTitle(t('pages.specialist.header.request')): setHeaderTitle("Request"); 
        setSearchState(true)
      break;
      case "buyer": setHeaderTitle("Buyer"); 
        setSearchState(true)
      break;
      case "seller": setHeaderTitle("Seller"); 
        setSearchState(true)
      break;
      case "notification":Ltype!=="admin"?setHeaderTitle(t('pages.specialist.header.notification')): setHeaderTitle("Notification"); 
        setSearchState(false)
        break;
      case "feedback": Ltype!=="admin"?setHeaderTitle(t('pages.specialist.header.feedback')): setHeaderTitle("Feedback"); 
        setSearchState(false)
      break;
      case "aboutus": setHeaderTitle("About us"); 
        setSearchState(false)
      break;
      case "faq": setHeaderTitle("FAQ"); 
        setSearchState(false)
      break;
      case "privacy": setHeaderTitle("Privacy Policy"); 
        setSearchState(false)
      break;
      case "terms-condition": setHeaderTitle("Terms and Condition"); 
        setSearchState(false)
      break;
      case "company-info": setHeaderTitle("Company Info"); 
        setSearchState(false)
      break;
      case "my-account":Ltype!=="admin"?setHeaderTitle(t('pages.specialist.header.my-account')): setHeaderTitle("My Account"); 
        setSearchState(false)
      break;
      case "change-password":Ltype!=="admin"?setHeaderTitle(t('pages.specialist.header.change-password')): setHeaderTitle("Change Password"); 
        setSearchState(false)
      break;

        default: 
        break;
    }
  }
  const callLogout = () => {
    if (props.admin_data[0]?.user_type === "admin") {
      props.fetchAdminLogindata("", []);
      navigate("/bc-admin");
    } else {
      props.fetchAdminLogindata("", []);
      navigate("/specialist");
    }
  };
  const notificationList = async () => {
    let getNotificationlistresponse = await getNotificationlist(
      props?.access,
      props?.admin_data[0]?.id
    );
    if (getNotificationlistresponse?.status === 200) {
      let getResponse = await getNotificationlistresponse.json();
      //console.log("notification response", getResponse);
      const reverse = getResponse.reverse().filter((item)=>item?.read===false)
      setNotificationlist(reverse);
    }
  };
  const callReadNotification = async () =>{
    const responceNoti = await getReadNotification(props.access);
    if(responceNoti.status===200){
      const result = await responceNoti.json();
      if(result?.data){
        setNotificationlist([]);
      }
    }
  }
  const gotoMyaccount = () => {
    navigate("my-account");
  };
  const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return moment(date).format("DD/MM/YYYY HH:mm A");
    }

    interval = seconds / 2592000;

    if (interval > 1) {
      return moment(date).format("DD/MM/YYYY HH:mm A");
    }

    interval = seconds / 86400;

    if (interval > 1) {
      return moment(date).format("DD/MM/YYYY HH:mm A");
    }

    interval = seconds / 3600;

    if (interval > 1) {
      return props.language ==="Sp"?"face " + Math.floor(interval) + t('pages.hours-ago'):Math.floor(interval) + t('pages.hours-ago');
    }

    interval = seconds / 60;

    if (interval > 1) {
      return props.language ==="Sp"?"face " + Math.floor(interval) + t('pages.minutes-ago'):Math.floor(interval) + t('pages.minutes-ago');

    }

    return props.language ==="Sp"?"face " + Math.floor(seconds) + t('pages.seconds-ago'):Math.floor(seconds) + t('pages.seconds-ago');

  };

  return (
    <>
      <div className="navbar-custom" onLoad={()=>changeChange(props.language)}>
        <ul className="list-unstyled topnav-menu float-end mb-0">

          {searchState&&
            <li className="d-none d-lg-block">
              <form className="app-search">
                <div className="app-search-box">
                  <div className="input-group">
                    <input
                      type="text"
                      value={props.searchInput}
                      onChange={(e) => props.changeSearchInput(e.target.value)}
                      className="form-control"
                      placeholder={Ltype!=="admin"?t('pages.specialist.header.search-place'):"Search..."}
                      id="top-search"
                    />
                    <button
                      className="btn input-group-text"
                      type="button"
                      onClick={() =>
                        props.searchInput.length > 0 &&
                        props.changeSearchInput("")
                      }
                    >
                      <i
                        class={
                          props.searchInput.length > 0
                            ? "fa fa-times"
                            : "fa mdi mdi-magnify"
                        }
                      ></i>
                    </button>
                  </div>
                </div>
              </form>
            </li>
          }
          {searchState&&
            <li className="d-inline-block d-lg-none">
              <NavLink
                to=""
                className="nav-link arrow-none waves-effect waves-light"
                data-bs-toggle="dropdown"
              >
                <i className="fa mdi mdi-magnify"></i>
              </NavLink>
              <div className="dropdown-menu dropdown-lg dropdown-menu-end p-0">
                <form className="p-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search ..."
                    value={props.searchInput}
                    onChange={(e) => props.changeSearchInput(e.target.value)}
                  />
                </form>
              </div>
            </li>
          }


          {Ltype==="sp"&&
            <li className="dropdown notification-list topbar-dropdown">
              <Dropdown className="admin language-dropdown" align="end" id="dropdown-menu-align-end">
                <Dropdown.Toggle id="dropdown-basic" style={{color: "black",background: "transparent"}}>
                  <img src={props.language==="Sp"?Spanish:Uk} alt={"."} className={"flag-select-en"} /> {props?.language}
                </Dropdown.Toggle>
                <Dropdown.Menu className="admin">
                  <Dropdown.Header>{t('pages.header.select-lang')}</Dropdown.Header>
                  <Dropdown.Item onClick={()=>changeChange("En")} >
                    <img src={Uk} alt={"."} className={"flag-en"} />
                    English - En
                  </Dropdown.Item>
                  <Dropdown.Item  onClick={()=>changeChange("Sp")} >
                    <img src={Spanish} alt={"."} className={"flag-en"} />
                    Spanish - Sp
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          }
          {Ltype==="sp"&&
          <li className="dropdown notification-list topbar-dropdown">
            <Dropdown className="admin noti-list-drop language-dropdown language-dropdowns" align="end" id="dropdown-menu-align-end">
                <Dropdown.Toggle  id="dropdown-basic" style={{color: "#343a40",background: "transparent"}}>
                    <i className="mdi mdi-bell-outline" style={{ fontWeight: 500 }}></i>
                    {notificationlist.length!==0&&<span className="badge bg-danger rounded-circle noti-icon-badge">
                      {notificationlist.length}
                    </span>}
                </Dropdown.Toggle>
                <Dropdown.Menu className="admin dropdown-menu dropdown-menu-end  dropdown-lg">
                  <Dropdown.Header>
                  <div className="admin noti-title">
                      <h5 className="admin m-0">
                          <span className="admin float-end">
                            {notificationlist.length!==0&&
                              <a href className="admin text-dark" onClick={()=>callReadNotification()} >
                                  <small>{props.language==="Sp"?"Limpiar todo":"Clear All"}</small>
                              </a>
                            }
                          </span>{props.language==="Sp"?"Notificación":"Notification"}
                      </h5>
                  </div>
                  </Dropdown.Header>
                  
                  <div className="admin noti-scroll" data-simplebar="init">
                    <div className="admin simplebar-wrapper" >
                      <div className="admin simplebar-height-auto-observer-wrapper">
                        <div className="admin lebar-height-auto-observer">
                          </div>
                      </div>
                      <div className="admin simplebar-mask">
                        <div className="admin simplebar-offset" style={{right: "0px", bottom:" 0px"}}>
                          <div className="admin simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content" style={{height: "auto", overflow: "hidden scroll"}}>
                            <div className="admin simplebar-content" >
                              {
                                notificationlist.length===0?
                                <>
                                  <label className="mt-3" style={{textAlign:"center", width:"100%"}}>
                                    {t('pages.specialist.header.no-notification')}
                                  </label>
                                </>
                                :
                                notificationlist.map((item, index)=>(
                                  <Dropdown.Item key={index.toString()}  className="admin dropdown-item notify-item ">
                                      <div className="admin notify-icon">
                                          <img src={notifiyellow} className="admin img-fluid rounded-circle" alt=""/>
                                      </div>
                                      <p className="admin m-0 mb-1 mt-1 notify-details" >{item.content}
                                        
                                      </p>
                                      <p  className="admin m-0 text-muted mb-0 user-msg">
                                        <small>{timeSince(new Date(item.created_at ))}</small>
                                      </p>
                                      <p hidden className="admin m-0 text-muted mb-0 user-msg">
                                          <small hidden>{item.content}</small>
                                          <div style={{float:'right', verticalAlign:"bottom"}} className="noti-right">
                                            <small>{timeSince(new Date(item.created_at))}</small>
                                          </div>
                                      </p>
                                  </Dropdown.Item>
                                ))
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="admin simplebar-placeholder" style={{width: "auto", height: "396px"}}></div>
                    </div>
                    <div className="admin simplebar-track simplebar-horizontal" >
                      <div className="admin simplebar-scrollbar" ></div>
                    </div>
                    <div className="admin simplebar-track simplebar-vertical">
                        <div className="admin simplebar-scrollbar" ></div>
                    </div>
                  </div>
                  <Link to={"notification"} className="admin dropdown-item text-center text-primary notify-item notify-all">
                     {props.language==="Sp"?"Ver todo":" View all"} <i className="fa fa-arrow-right"></i>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>

          </li>
          }
          <li className="dropdown notification-list topbar-dropdown">
          <Dropdown className="admin myaccount-menu">
            <Dropdown.Toggle className="admin nav-user" id="dropdown-basic">
            <img src={props.admin_data[0]?.profile_pic?props.profile_url+props.admin_data[0]?.profile_pic:profile_pic} alt="u" className="rounded-circle" />
              <span className="pro-user-name ms-1">
                {props.admin_data[0]?.first_name} <i className="mdi mdi-chevron-down"></i>
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="admin">
              <Dropdown.Header>
                  <h6 className="text-overflow m-0">{Ltype!=="admin"?t('pages.specialist.header.menu.title'):"Welcome !"}</h6>
              </Dropdown.Header>
              <Dropdown.Item className={"admin notify-item"} onClick={() => gotoMyaccount()}>
                <i className="mdi mdi-account-outline"></i>
                <span>{Ltype!=="admin"?t('pages.specialist.header.menu.account'):"My Account"}</span>
              </Dropdown.Item>
              <div className="dropdown-divider"></div>
              <Dropdown.Item className={"admin notify-item"} onClick={() => callLogout()}>
                <i className="mdi mdi-logout"></i>
                <span>{Ltype!=="admin"?t('pages.specialist.header.menu.logout'):"Logout"}</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </li>

          <li hidden className="dropdown notification-list">
            <NavLink
              to=""
              className="nav-link right-bar-toggle waves-effect waves-light"
            >
              <i className="fa fa-gear noti-icon"></i>
            </NavLink>
          </li>
        </ul>

        {/* <!-- LOGO --> */}
        <div className="logo-box">
          <NavLink
            to=""
            className="logo text-decoration-none    logo-light text-center"
          >
            <span className="logo-sm">
              <img src="images/logo-sm.png" alt="" width="20" />
            </span>
            <span className="logo-lg">
              <img src="images/logo-light.png" alt="" height="16" />
            </span>
          </NavLink>
          <NavLink
            to=""
            className="logo text-decoration-none logo-dark text-center"
          >
            <span className="logo-sm">
              <h5>C</h5>
            </span>
            <span className="logo-lg">
              <h5>
                Confy<label style={{cursor:"pointer"}}>Car</label>
              </h5>
            </span>
          </NavLink>
        </div>

        <ul className="list-unstyled topnav-menu topnav-menu-left mb-0">
          <li>
            <button
              className="button-menu-mobile disable-btn waves-effect"
              onClick={() => props?.setopenSideNav(!props?.openSideNav)}
            >
              <i className="fa fa-bars"></i>
            </button>
          </li>

          <li>
            <h4 className="page-title-main">{headerTitle}</h4>
          </li>
        </ul>

        <div className="clearfix"></div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  access: state.adminlogin.access,
  admin_data: state?.adminlogin?.admin_data,
  searchInput: state.searchInput.search,
  language:state?.theme?.language,
  profile_url:state.adminlogin.profile_pic_base_url,
});

export default connect(mapStateToProps, {
  fetchAdminLogindata,
  changeSearchInput,
  fetchChangeLanguage
})(HeadernavPage);
