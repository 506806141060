export const PostNotification = async (access, data) => {
    try {
        let url;

        const headers = new Headers();

        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${access}`);


        url = `${process.env.REACT_APP_BASE_URL}/api/admin/post_notification`;

        const getResponse = await fetch(url, {
            headers,
            method: "POST",
            body: JSON.stringify(data),
        });
  
      return getResponse;
    } catch (error) {}
  };
  