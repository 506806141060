export const removeUploadDoc = async (access, data) => {
    try {
      const headers = new Headers();
  
      headers.append("Content-Type", "application/json");
  
      headers.append("Authorization", `Bearer ${access}`);
  
      const getResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/upload/remove/`,
        {
          headers,
          method: "POST",
          body: JSON.stringify(data),
        }
      );
  
      return getResponse;
    } catch (error) {}
  };
  