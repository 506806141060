export const getContactusdetail = async (contactusdetail) => {
  try {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/footer-pages/contact-us/`,
      {
        headers,
        method: "POST",
        body: JSON.stringify(contactusdetail),
      }
    );

    return getResponse;
  } catch (error) {}
};
