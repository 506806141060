import React from "react";
import { Modal } from "react-bootstrap";
import "./PackageinformationPage.css";
import info_icon from "../../../assets/info_icon.png";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function PackageinformationPage(props) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const navigateTonextpage = () => {
    if (props.access) {
      navigate("/detail/car");
    } else {
      navigate("/login", { state: { proceedBeforelogin: true } });
    }
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={props.showpackageinfo}
      className="package-info"
      onHide={() => navigateTonextpage()}
      centered
      style={{zIndex:1299}}
    >
      <Modal.Body>
        <img className="info-icon" alt={""} src={info_icon}></img>
        <div className="info-content">
          <p className="content-f">{t("pages.dashboard.packageinfo.title")}</p>
          <p className="content-s">
            {t("pages.dashboard.packageinfo.subtitle-1")}
          </p>
          <p className="content-t">
            {t("pages.dashboard.packageinfo.subtitle-2")}
          </p>
          <Modal.Footer>
            <button onClick={() => navigateTonextpage()}>
              {t("pages.dashboard.packageinfo.button")}
            </button>
          </Modal.Footer>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  access: state?.login?.access,
});

export default connect(mapStateToProps)(PackageinformationPage);
