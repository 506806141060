export const getNearbyspecialist = async (access, currentLatlng) => {
  try {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    headers.append("Authorization", `Bearer ${access}`);

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/nearby-specialist?lat=${currentLatlng.lat}&lng=${currentLatlng.lng}`,
      {
        headers,
        method: "GET",
      }
    );

    return getResponse;
  } catch (error) {
    console.log(error);
  }
};
