import React, { useEffect, useState } from "react";
import "./ProfilePage.css";
import { connect } from "react-redux";
import user_icon from "./../../../assets/user_icon.svg";
import call_icon from "./../../../assets/call_icon.svg";
import email_icon from "./../../../assets/email_icon.svg";
import user_circle from "./../../../assets/profile_pic.svg";
import {
  FormControl,
  InputGroup,
  Button,
  Container,
  Col,
  Form,
} from "react-bootstrap";
import { getUserdetail } from "../util/getUserdetail";
import { getProfiledetail } from "../util/profileUpdate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
//import storage from "redux-persist/lib/storage";
import { uploadFile } from "../../admin/addspecialist/util/uploadFile";
import { useTranslation } from "react-i18next";
import { Logout } from "./../../login/redux/actions"
import { setShowpackagelist } from "../../home/redux/actions";

function ProfilePage(props) {
  const prnBtn = props.theme.primaryBtn;
  const secndBtn = props.theme.secoundryBtn;
  const inputType = props.theme.inputForm;

  const emailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  const mobileNumRegex = /^\d{10}$/;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [userField, setUserfield] = useState({
    first_name: "",
    email: "",
    password: "",
    mobile: "",
    profile_pic: "",
    profile_pic_base_url: "",
  });

  const [userinfo, setUserinfo] = useState({
    first_name: "",
    mobile: "",
  });

  const [errors, setErrors] = useState({
    first_name: "",
    email: "",
    mobile: "",
  });

  useEffect(() => {
    if (props.tabkey === "profile") {
      getDetails();
    }
    // eslint-disable-next-line
  }, [props.tabkey]);

  const getDetails = async () => {
    let getUserdetailresponse = await getUserdetail(props.access);
    if (getUserdetailresponse.status === 200) {
      let getUserdata = await getUserdetailresponse.json();
      //console.log("response data", getUserdata);
      setUserfield({
        ...userField,
        first_name: getUserdata?.data[0]?.first_name,
        email: getUserdata?.data[0]?.email,
        mobile: getUserdata?.data[0]?.mobile,
        profile_pic: getUserdata?.data[0]?.profile_pic,
        profile_pic_base_url: getUserdata?.url,
      });
      setUserinfo({
        ...userinfo,
        first_name: getUserdata?.data[0]?.first_name,
        mobile: getUserdata?.data[0]?.mobile,
      });
    }
  };

  const profileInputchange = (e) => {
    setUserfield({
      ...userField,
      [e.target.name]: e.target.value,
    });

    switch (e.target.name) {
      case "first_name":
        setErrors({
          ...errors,
          first_name: "",
        });
        break;

      case "email":
        setErrors({
          ...errors,
          email: "",
        });
        break;

      case "mobile":
        setErrors({
          ...errors,
          mobile: "",
        });
        break;

      default:
        break;
    }
  };

  const validation = () => {
    let checkValue = true;

    if (userField.first_name === "") {
      if (props.language === "Sp") {
        errors.first_name = "Ingresa tu nombre completo";
      } else {
        errors.first_name = "Enter your full name";
      }
      checkValue = false;
    }

    if (!userField.email.match(emailRegex)) {
      if (props.language === "Sp") {
        errors.email = "Ingrese su correo electrónico válido";
      } else {
        errors.email = "Enter your valid email";
      }
      checkValue = false;
    }

    if (userField.email === "") {
      if (props.language === "Sp") {
        errors.email = "Introduce tu correo electrónico";
      } else {
        errors.email = "Enter your email";
      }
      checkValue = false;
    }

    if (!userField.mobile.match(mobileNumRegex)) {
      if (props.language === "Sp") {
        errors.mobile = "Ingrese su número de móvil válido de 10 dígitos";
      } else {
        errors.mobile = "Enter your 10 digit valid mobile number";
      }
      checkValue = false;
    }

    if (userField.mobile === "") {
      if (props.language === "Sp") {
        errors.mobile = "Ingrese su numero celular";
      } else {
        errors.mobile = "Enter your mobile number";
      }
      checkValue = false;
    }

    setErrors({
      ...errors,
      first_name: errors.first_name,
      email: errors.email,
      mobile: errors.mobile,
    });

    if (checkValue === true) {
      return true;
    } else {
      return false;
    }
  };

  const updateProfile = async () => {
    if (validation()) {
      let profileDetail = {
        fullname: userField.first_name,
        email: userField.email,
        mobile: userField.mobile,
        profile_pic: userField.profile_pic,
      };

      let getProfiledetailresponse = await getProfiledetail(
        profileDetail,
        props.access
      );

      if (getProfiledetailresponse.status === 200) {
        let getUserdata = await getProfiledetailresponse.json();
        //console.log("check value", getUserdata);
        setUserfield({
          ...userField,
          first_name: getUserdata[0].first_name,
          email: getUserdata[0].email,
          mobile: getUserdata[0].mobile,
        });
        setUserinfo({
          ...userinfo,
          first_name: getUserdata[0].first_name,
          mobile: getUserdata[0].mobile,
        });

        toast.success(t("pages.profile.profile-update"));
      }
    }
  };

  const callLogout = () => {
    //storage.removeItem("persist:persist-blockcar");
    props.Logout();
    props.setShowpackagelist(false);
    navigate("/");
   // window.location.reload();
  };

  const profileChange = async (e) => {
    if(
        e.target.files[0].type==="image/png"||
        e.target.files[0].type==="image/jpg"||
        e.target.files[0].type==="image/jpeg"||
        e.target.files[0].type==="image/jfif"||
        e.target.files[0].type==="image/svg"||
        e.target.files[0].type==="image/apng"||
        e.target.files[0].type==="image/avif"||
        e.target.files[0].type==="image/gif"
    ){
      let uploadFileresponse = await uploadFile(
        props.access,
        e.target.files[0],
        "profile"
      );
  
      if (uploadFileresponse.status === 201) {
        let getRespone = await uploadFileresponse.json();
        if (getRespone?.data?.length > 0) {
          setUserfield({
            ...userField,
            profile_pic: getRespone?.data[0]?.name,
          });
          e.target.files="";
        }
      }
    }else{
      alert("invalid image type")
    }
  };

  return (
    <>
      <div className={"profile"}>
        <Container>
          <div className={"profile-div"}>
            <Col>
              <div className={"profile-panel"}>
                <div style={secndBtn} className={"profile-page p-4"}>
                  <div className={"pro-pic-div"}>
                    <div className="img-profile-div">
                      <div className={"pro-image-div"}>
                        <img
                          src={
                            userField.profile_pic_base_url + userField.profile_pic
                          }
                          onError={(event) => {
                            event.target.src = user_circle;
                          }}
                          alt={""}
                          className="user_circle"
                        />
                      </div>
                      <div className="profile-pic">
                        <input
                          type="file"
                          id="user_profile"
                          style={{ display: "none" }}
                          onChange={(e) => profileChange(e)}
                          accept='image/*'
                        />
                        <div
                          onClick={() =>
                            document.getElementById("user_profile").click()
                          }
                        >
                          <label title={userField.profile_pic === null? t("pages.profile.add-picture"): t("pages.profile.change-picture")}>
                            <i className="fa fa-pencil" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className={"prifile-title"}>
                      <h5 className={"primarytxt"}>{userinfo?.first_name}</h5>
                      <h5 className={"secoundrytxt"}>{userinfo?.mobile}</h5>
                    </div>
                  </div>
                  <div className={"logout-div"}>
                    <Button
                      variant={"danger shadow-btn"}
                      onClick={() => callLogout()}
                    >
                      {t("pages.profile.logout")}
                    </Button>
                  </div>
                </div>
              </div>
              <div className={"profile-box"}>
                <Form className={"profile-form "}>
                  <Form.Group className={"col-6 mb-3"}>
                    <Form.Label>{t("pages.profile.username")}</Form.Label>
                    <InputGroup style={inputType}>
                      <InputGroup.Text id="basic-addon1">
                        <img
                          src={user_icon}
                          alt={"."}
                          style={{ height: "100%", width: "100%" }}
                        />
                      </InputGroup.Text>
                      <FormControl
                        placeholder={t("pages.profile.username-place")}
                        name={"first_name"}
                        onChange={(e) => profileInputchange(e)}
                        value={userField.first_name}
                        required
                        type={"text"}
                        className={"input-field"}
                      />
                    </InputGroup>
                    <div className="text-danger">{errors.first_name}</div>
                  </Form.Group>
                  <Form.Group className={"col-6 mb-3"}>
                    <Form.Label>{t("pages.profile.mobile")}</Form.Label>
                    <InputGroup style={inputType}>
                      <InputGroup.Text id="basic-addon1">
                        <img
                          src={call_icon}
                          alt={"."}
                          style={{ height: "100%", width: "100%" }}
                        />
                      </InputGroup.Text>
                      <FormControl
                        placeholder={t("pages.profile.mobile-place")}
                        name={"mobile"}
                        onChange={(e) => profileInputchange(e)}
                        value={userField.mobile}
                        required
                        type={"number"}
                        className={"input-field"}
                        onWheel={(e) => e.target.blur()}
                      />
                    </InputGroup>
                    <div className="text-danger">{errors.mobile}</div>
                  </Form.Group>
                  <Form.Group className={"col-6 mb-3"}>
                    <Form.Label>{t("pages.profile.email")}</Form.Label>
                    <InputGroup style={inputType}>
                      <InputGroup.Text id="basic-addon1">
                        <img
                          src={email_icon}
                          alt={"."}
                          style={{ height: "100%", width: "100%" }}
                        />
                      </InputGroup.Text>
                      <FormControl
                        placeholder={t("pages.profile.email-place")}
                        name={"email"}
                        onChange={(e) => profileInputchange(e)}
                        value={userField.email}
                        required
                        type={"email"}
                        className={"input-field"}
                      />
                    </InputGroup>
                    <div className="text-danger">{errors.email}</div>
                  </Form.Group>
                  <Form.Group className={"col-6 mb-3 "}>
                    <div className={" profile-action pt-1"}>
                      <Col className={"p-1"}>
                        <Button
                          variant="outline-light"
                          onClick={() => navigate("/changepassword")}
                          className={"change-btn shadow-btn"}
                          style={prnBtn}
                        >
                          {t("pages.profile.change")}{" "}
                        </Button>
                      </Col>
                      <Col className={"p-1"}>
                        <Button
                          style={secndBtn}
                          className={"save-btn shadow-btn"}
                          variant="outline-light"
                          onClick={() => updateProfile()}
                        >
                          {t("pages.profile.save")}
                        </Button>
                      </Col>
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </div>
        </Container>
      </div>
    </>
  );
}
const mapStateToProps = (store) => ({
  theme: store.theme?.primaryTheme,
  access: store?.login?.access,
  language: store?.theme?.language,
});
export default connect(mapStateToProps,{Logout,setShowpackagelist})(ProfilePage);
