export const addTermsandcondition = async (access, about_data) => {
    try {
      const headers = new Headers();
        console.log(about_data)
      headers.append("Authorization", `Bearer ${access}`);
  
      headers.append("Accept", "application/json");
  
      const getResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/term_condition`,
        {
          headers,
          method: "POST",
          body:JSON.stringify(about_data)
        }
      );
  
      return getResponse;
    } catch (error) {}
};
  