import React, { useState } from "react";
import "./MoredetailPage.css";
import HeaderPage from "../../header/components/HeaderPage";
import { Container, Form, InputGroup, Row } from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import car_brand from "../../../assets/car_brand.svg";
import car_km from "../../../assets/car_km.svg";
import car_type from "../../../assets/car_type.svg";
import car_color from "../../../assets/car_color.svg";
import car_number from "../../../assets/car_number.svg";
import FooterPage from "../../footer/components/FooterPage";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function MoredetailPage(props) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [vehicaldetail, setVehicaldetail] = useState({
    user_id: "",
    final_amount: "",
    current_status: "initiated",
    payment_status: "done",
    package_id: "",
    amount: "",
    vehicle_brand: "",
    vehicle_km: "",
    vehicle_type: "",
    vehicle_age: new Date(),
    vehicle_color: "",
    vehicle_number_plate: "",
    vehicle_power: "",
    vehicle_location: "",
    lat: "",
    lng: "",
  });

  const [errors, setErrors] = useState({
    vehicle_brand: "",
    vehicle_km: "",
    vehicle_type: "",
    vehicle_age: "",
    vehicle_color: "",
    vehicle_number_plate: "",
    vehicle_power: "",
    vehicle_location: "",
  });

  const locationOnchange = (value) => {
    setVehicaldetail({
      ...vehicaldetail,
      vehicle_location: value,
    });
    setErrors({
      ...errors,
      vehicle_location: "",
    });
  };

  const getSelectedlocation = async (value) => {
    const results = await geocodeByAddress(value);
    const latlng = await getLatLng(results[0]);
    setVehicaldetail({
      ...vehicaldetail,
      vehicle_location: value,
      lat: latlng.lat,
      lng: latlng.lng,
    });
  };

  const detailOnchange = (e) => {
    setVehicaldetail({
      ...vehicaldetail,
      [e.target.name]: e.target.value,
    });

    switch (e.target.name) {
      case "vehicle_brand":
        setErrors({
          ...errors,
          vehicle_brand: "",
        });
        break;
      case "vehicle_km":
        setErrors({
          ...errors,
          vehicle_km: "",
        });
        break;
      case "vehicle_type":
        setErrors({
          ...errors,
          vehicle_type: "",
        });
        break;
      case "vehicle_age":
        setErrors({
          ...errors,
          vehicle_age: "",
        });
        break;
      case "vehicle_color":
        setErrors({
          ...errors,
          vehicle_color: "",
        });
        break;
      case "vehicle_number_plate":
        setErrors({
          ...errors,
          vehicle_number_plate: "",
        });
        break;
      case "vehicle_power":
        setErrors({
          ...errors,
          vehicle_power: "",
        });
        break;

      default:
        break;
    }
  };

  const validation = () => {  
    let checkValue = true;

    if (vehicaldetail.vehicle_brand === "") {
      if (props.language === "Sp") {
        errors.vehicle_brand = "Introduce la marca de tu coche";
      } else {
        errors.vehicle_brand = "Enter your car brand";
      }
      checkValue = false;
    }

    if (vehicaldetail.vehicle_km === "") {
      if (props.language === "Sp") {
        errors.vehicle_km = "Introduce los km de tu coche";
      } else {
        errors.vehicle_km = "Enter your car km";
      }
      checkValue = false;
    }

    if (vehicaldetail.vehicle_type === "") {
      if (props.language === "Sp") {
        errors.vehicle_type = "Introduce tu tipo de coche";
      } else {
        errors.vehicle_type = "Enter your car type";
      }
      checkValue = false;
    }

    if (vehicaldetail.vehicle_age === "") {
      if (props.language === "Sp") {
        errors.vehicle_age = "Ingrese su primer año de registro";
      } else {
        errors.vehicle_age = "Enter your 1st registration year";
      }
      checkValue = false;
    }

    if (vehicaldetail.vehicle_color === "") {
      if (props.language === "Sp") {
        errors.vehicle_color = "Introduce el color de tu coche";
      } else {
        errors.vehicle_color = "Enter your car color";
      }
      checkValue = false;
    }

    if (vehicaldetail.vehicle_number_plate === "") {
      if (props.language === "Sp") {
        errors.vehicle_number_plate = "Introduce la matrícula de tu coche";
      } else {
        errors.vehicle_number_plate = "Enter your car number plate";
      }
      checkValue = false;
    }

    if (vehicaldetail.vehicle_power === "") {
      if (props.language === "Sp") {
        errors.vehicle_power = "Introduce la potencia de tu coche";
      } else {
        errors.vehicle_power = "Enter your car power";
      }
      checkValue = false;
    }

    if (vehicaldetail.vehicle_location === "") {
      if (props.language === "Sp") {
        errors.vehicle_location = "Introduce la ubicación de tu coche";
      } else {
        errors.vehicle_location = "Enter your car location";
      }
      checkValue = false;
    }

    setErrors({
      ...errors,
      vehicle_brand: errors.vehicle_brand,
      vehicle_km: errors.vehicle_km,
      vehicle_type: errors.vehicle_type,
      vehicle_age: errors.vehicle_age,
      vehicle_color: errors.vehicle_color,
      vehicle_number_plate: errors.vehicle_number_plate,
      vehicle_power: errors.vehicle_power,
      vehicle_location: errors.vehicle_location,
    });

    if (checkValue === true) {
      return true;
    } else {
      return false;
    }
  };

  const callCardpage = (e) => {
    if (validation()) {
      navigate("/detail/map", { state: { vehicaldetail: vehicaldetail } });
    }
  };

  return (
    <>
      <HeaderPage color={"black"} logo={"black"} background={"#F7F7F7"} />
      <div className="detail-form">
        <Container>
          <h3>{t("pages.detail.title")}</h3>
          <Row>
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-6">
              <Form.Group className="form-group">
                <Form.Label className="form-label">
                  {t("pages.detail.brand")}
                </Form.Label>
                <InputGroup className="input-group">
                  <InputGroup.Text>
                    <img
                      src={car_brand}
                      alt={"."}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className="form-control"
                    placeholder={t("pages.detail.brand-place")}
                    value={vehicaldetail.vehicle_brand}
                    name="vehicle_brand"
                    onChange={(e) => detailOnchange(e)}
                  ></Form.Control>
                </InputGroup>
                <div className="text-danger">{errors.vehicle_brand}</div>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="form-label">
                  {t("pages.detail.type")}
                </Form.Label>
                <InputGroup className="input-group">
                  <InputGroup.Text>
                    <img
                      src={car_type}
                      alt={"."}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className="form-control"
                    placeholder={t("pages.detail.type-place")}
                    value={vehicaldetail.vehicle_type}
                    name="vehicle_type"
                    onChange={(e) => detailOnchange(e)}
                  ></Form.Control>
                </InputGroup>
                <div className="text-danger">{errors.vehicle_type}</div>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="form-label">
                  {t("pages.detail.colour")}
                </Form.Label>
                <InputGroup className="input-group">
                  <InputGroup.Text>
                    <img
                      src={car_color}
                      alt={"."}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className="form-control"
                    placeholder={t("pages.detail.colour-place")}
                    value={vehicaldetail.vehicle_color}
                    name="vehicle_color"
                    onChange={(e) => detailOnchange(e)}
                  ></Form.Control>
                </InputGroup>
                <div className="text-danger">{errors.vehicle_color}</div>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="form-label">
                  {t("pages.detail.power")}
                </Form.Label>
                <InputGroup className="input-group">
                  <InputGroup.Text>
                    <i className="fa fa-bolt" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className="form-control"
                    placeholder={t("pages.detail.power-place")}
                    value={vehicaldetail.vehicle_power}
                    name="vehicle_power"
                    onChange={(e) => detailOnchange(e)}
                  ></Form.Control>
                </InputGroup>
                <div className="text-danger">{errors.vehicle_power}</div>
              </Form.Group>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-6">
              <Form.Group className="form-group">
                <Form.Label className="form-label">
                  {t("pages.detail.km")}
                </Form.Label>
                <InputGroup className="input-group">
                  <InputGroup.Text>
                    <img
                      src={car_km}
                      alt={"."}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className="form-control"
                    placeholder={t("pages.detail.km-place")}
                    value={vehicaldetail.vehicle_km}
                    name="vehicle_km"
                    onChange={(e) => detailOnchange(e)}
                  ></Form.Control>
                </InputGroup>
                <div className="text-danger">{errors.vehicle_km}</div>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="form-label">
                  {t("pages.detail.age")}
                </Form.Label>
                <InputGroup className="input-group">
                  <InputGroup.Text>
                    <i className="fa fa-calendar" />
                  </InputGroup.Text>
                  <div className="form-control">
                    <DatePicker
                      onChange={(date) => setVehicaldetail({...vehicaldetail,vehicle_age:date})}
                      showYearPicker={true}
                      dateFormat="yyyy"
                      placeholder={"select year"}
                      className={"date-pickers"}
                      selected={vehicaldetail.vehicle_age}
                      minDate={"2007"}
                      maxDate={"2022"}
                    />
                  </div>
                  <InputGroup.Text>
                    <i className="fa fa-angle-down" />
                  </InputGroup.Text>
                </InputGroup>
                <div className="text-danger">{errors.vehicle_age}</div>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="form-label">
                  {t("pages.detail.number")}
                </Form.Label>
                <InputGroup className="input-group">
                  <InputGroup.Text>
                    <img
                      src={car_number}
                      alt={"."}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className="form-control"
                    placeholder={t("pages.detail.number-place")}
                    value={vehicaldetail.vehicle_number_plate}
                    name="vehicle_number_plate"
                    onChange={(e) => detailOnchange(e)}
                  ></Form.Control>
                </InputGroup>
                <div className="text-danger">{errors.vehicle_number_plate}</div>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="form-label">
                  {t("pages.detail.location")}
                </Form.Label>
                <PlacesAutocomplete
                  name="location"
                  value={vehicaldetail.vehicle_location}
                  onChange={locationOnchange}
                  onSelect={getSelectedlocation}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="vehicle-location-place">
                      <InputGroup className="input-group location-input">
                        <InputGroup.Text>
                          <i className="fa fa-file" />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          className="form-control"
                          {...getInputProps({
                            placeholder: t("pages.detail.location-place"),
                          })}
                        ></Form.Control>
                      </InputGroup>
                      <div className="text-danger location-err">
                        {errors.vehicle_location}
                      </div>
                      <div className="autoplace-location">
                        {loading ? <div>...loading</div> : null}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#fff"
                              : "#fff",
                            border: "1px solid #dbdcde",
                            padding: "0px 10px",
                          };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                style,
                              })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Form.Group>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-6" />
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-6">
              <Button className="continue-btn" onClick={(e) => callCardpage(e)}>
                {t("pages.detail.button")}
              </Button>
            </div>
          </Row>
        </Container>
      </div>
      <FooterPage />
    </>
  );
}

const mapStateToProps = (state) => ({
  language: state?.theme?.language,
});

export default connect(mapStateToProps)(MoredetailPage);
