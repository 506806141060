import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import user from "./../../../../assets/profile_pic.svg";
import pushnoti_icon from "../../../../assets/pushnoti_icon.svg";
import { getRequestList } from "../../request/util/getRequest";
import "./SidebarPage.css";
import { useTranslation } from "react-i18next";
import { fetchAdminLogindata } from "../../login/redux/actions";

function SidenavPage(props) {
  const [openAdminMenu, setOpenAdminMenu] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const { t } = useTranslation();
  const [RequestCnd, setRequestCnd] = useState([]);
  const Ltype = props?.admin_data[0]?.user_type;
  const navigate = useNavigate();
  useEffect(() => {
    callRequestCnd();
    // eslint-disable-next-line
  }, []);
  const callRequestCnd = async () => {
    const responceRequest = await getRequestList(props.access, Ltype);
    if (responceRequest.status === 200) {
      const resultRequest = await responceRequest.json();
      const initiated = Object.values(resultRequest)
      setRequestCnd(initiated.filter((item)=>item.current_status==="initiated"))
    }
  };

  const logoutmodule = () => {
    if (props.admin_data[0]?.user_type === "admin") {
      props.fetchAdminLogindata("", []);
      navigate("/bc-admin");
    } else {
      props.fetchAdminLogindata("", []);
      navigate("/specialist");
    }
  };

  return (
    <div
      class={
        props.openSideNav
          ? "admin open-side-nav left-side-menu"
          : "admin left-side-menu"
      }
    >
      <div class="admin h-100" data-simplebar>
        <div class="admin user-box text-center">
          <img
            src={
              props?.admin_data[0]?.profile_pic
                ? props.profile_url + props?.admin_data[0].profile_pic
                : user
            }
            alt="user-img"
            title={props?.admin_data[0].first_name}
            class="admin rounded-circle img-thumbnail avatar-md"
          />
          <div class="admin dropdown">
            <a
              href
              onClick={() => setOpenAdminMenu(!openAdminMenu)}
              class="admin user-name h5 mt-2 mb-1 d-block"
              data-bs-toggle="dropdown"
            >
              {props?.admin_data[0].first_name}
            </a>
            <div
              class={
                openAdminMenu
                  ? "admin dropdown-menu user-pro-dropdown d-block"
                  : "admin dropdown-menu user-pro-dropdown"
              }
            >
              <Link to="my-account" class="admin dropdown-item notify-item">
                <i class="admin mdi mdi-account me-1"></i>
                <span>
                  {Ltype !== "admin"
                    ? t("pages.specialist.sidenav.menu.account")
                    : "My Account"}
                </span>
              </Link>
              <a href class="admin dropdown-item notify-item" onClick={logoutmodule}>
                <i class="admin mdi mdi-logout me-1"></i>
                <span>
                  {Ltype !== "admin"
                    ? t("pages.specialist.sidenav.menu.logout")
                    : "Logout"}
                </span>
              </a>
            </div>
          </div>
          <ul class="admin list-inline">
            <li class="admin list-inline-item">
              <a href onClick={() => logoutmodule()}>
                <i
                  class="admin fa fa-power-off "
                  title={
                    Ltype !== "admin"
                      ? t("pages.specialist.sidenav.menu.logout")
                      : "Logout"
                  }
                ></i>
              </a>
            </li>
          </ul>
        </div>

        {/* <!--- Sidemenu --> */}
        <div id="sidebar-menu">
          <ul id="side-menu">
            <li class="admin menu-title">
              {Ltype !== "admin"
                ? t("pages.specialist.sidenav.navigation")
                : "Navigation"}
            </li>
            <li class="admin menuitem">
              <NavLink
                class="admin"
                to={
                  props?.admin_data[0]?.user_type === "sp"
                    ? "/specialist/dashboard"
                    : "/bc-admin/dashboard"
                }
              >
                <i class="admin mdi mdi-view-dashboard-outline"></i>
                <span>
                  {" "}
                  {Ltype !== "admin"
                    ? t("pages.specialist.sidenav.dashboard")
                    : "Dashboard"}{" "}
                </span>
              </NavLink>
            </li>
            {props?.admin_data[0]?.user_type !== "sp" && (
              <li className="admin menuitem">
                <NavLink class="admin" to={"/bc-admin/specialist"}>
                  <a href>
                    <i class="admin fa fa-star" aria-hidden="true"></i>
                    <span> Specialist </span>
                  </a>
                </NavLink>
              </li>
            )}
            <li className="admin menuitem" title={"Request"}>
              <NavLink
                class="admin"
                to={
                  props?.admin_data[0]?.user_type === "sp"
                    ? "/specialist/request"
                    : "/bc-admin/request"
                }
              >
                <i class="admin fa fa-location-arrow "></i>
                { Ltype === "sp"?
                  props.req_count!=="0"&&
                  <span class="admin badge bg-success float-end" title={"Pending Request"}>
                    {props.req_count }{props.req_count!=="0"&&'+'}
                  </span>
                :
                  <span class="admin badge bg-success float-end" title={"New Request"}>
                    {RequestCnd.length}+ 
                  </span>
                }
                <span>
                  {" "}
                  {Ltype !== "admin"
                    ? t("pages.specialist.sidenav.request")
                    : "Request"}{" "}
                </span>
              </NavLink>
            </li>
            {props?.admin_data[0]?.user_type !== "sp" && (
              <>
                <li className="admin menuitem">
                  <NavLink class="admin" to={"/bc-admin/buyer"}>
                    <i class="admin fa fa-shopping-cart"></i>
                    <span> Buyer </span>
                  </NavLink>
                </li>
                <li className="admin menuitem">
                  <NavLink class="admin" to={"/bc-admin/seller"}>
                    <i class="admin fa fa-store"></i>
                    <span> Seller </span>
                  </NavLink>
                </li>
              </>
            )}
            <li className="admin menuitem">
              <NavLink
                class="admin"
                to={
                  props?.admin_data[0]?.user_type === "sp"
                    ? "/specialist/notification"
                    : "/bc-admin/notification"
                }
              >
                <img className="pushnoti_icon" alt="" src={pushnoti_icon}></img>
                <span>
                  {Ltype !== "admin"
                    ? t("pages.specialist.sidenav.notification")
                    : "Notification"}
                </span>
              </NavLink>
            </li>
            {Ltype === "sp" && (
              <li className="admin menuitem">
                <NavLink class="admin" to={"/specialist/feedback"}>
                  <i class="admin fa fa-comments"></i>
                  <span>{t("pages.specialist.sidenav.view-feed")}</span>
                </NavLink>
              </li>
            )}
            {props?.admin_data[0]?.user_type !== "sp" && (
              <>
                <li className="admin menuitem">
                  <a
                    href
                    onClick={() => setOpenDropdown(!openDropdown)}
                    class="admin"
                  >
                    <i class="admin mdi mdi-view-dashboard-outline"></i>
                    <span> Pages </span>
                    <span class="admin badge text-black float-end">
                      <i
                        className={
                          openDropdown ? "fa fa-angle-up" : "fa fa-angle-down"
                        }
                      />
                    </span>
                  </a>
                  <div
                    className={
                      openDropdown
                        ? "inner-menu-list d-block"
                        : "inner-menu-list d-none"
                    }
                  >
                    <li className="admin menuitem">
                      <NavLink class="admin" to={"/bc-admin/pages/aboutus"}>
                        <i class="admin fa fa-info-circle"></i>
                        <span> Aboutus </span>
                      </NavLink>
                    </li>
                    <li className="admin menuitem">
                      <NavLink class="admin" to={"/bc-admin/pages/faq"}>
                        <i class="admin fa fa-question-circle "></i>
                        <span> FAQ`s </span>
                      </NavLink>
                    </li>
                    <li className="admin menuitem">
                      <NavLink class="admin" to={"/bc-admin/pages/privacy"}>
                        <i class="admin fa fa-lock"></i>
                        <span> Privacy Policy </span>
                      </NavLink>
                    </li>
                    <li className="admin menuitem">
                      <NavLink
                        class="admin"
                        to={"/bc-admin/pages/terms-condition"}
                      >
                        <i class="admin fa fa-legal"></i>
                        <span> Terms and Condition </span>
                      </NavLink>
                    </li>
                    <li className="admin menuitem">
                      <NavLink
                        class="admin"
                        to={"/bc-admin/pages/company-info"}
                      >
                        <i class="admin fa fa-info"></i>
                        <span> Company Info </span>
                      </NavLink>
                    </li>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
        <div class="admin clearfix"></div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  access: state?.adminlogin?.access,
  admin_data: state?.adminlogin?.admin_data,
  profile_url: state?.adminlogin?.profile_pic_base_url,
  req_count: state?.dashboard?.req_count,
});
export default connect(mapStateToProps, { fetchAdminLogindata })(SidenavPage);
