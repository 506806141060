import React, { useEffect, useState } from "react";
import { getRequestdetail } from "../util/requestDetail";
import { getSpecialList } from "../../specialist/util/specialList";
import user from "./../../../../assets/profile_pic.svg";
import { getPostrequestdetail } from "../util/postRequest";
import { uploadFile } from "../../addspecialist/util/uploadFile";
import { connect } from "react-redux";
import "./RequestdetailPage.css";
import Select from "react-select";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import pdf from "../../../../assets/pdf_doc.png";
import video_icon from "../../../../assets/video_icon.png";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getMarkcomplete } from "../util/markComplete";
import close_icon from "../../../../assets/close_icon.png";
import ViewerPage from "../../../modal/components/ViewerPage";
import doc_doc from "../../../../assets/doc_doc.png";
import excel_doc from "../../../../assets/excel_doc.png";
import ppt_doc from "../../../../assets/ppt_doc.png";
import { removeUploadDoc } from "../util/removeUpload";
import RequestrejectPage from "../../modal/components/RequestrejectPage";
import LoaderPage from "../../loader/components/LoaderPage";

function RequestdetailPage(props) {
  let getRequestid = window.location.pathname.split("/").pop();

  let userType = props.admin_data[0]?.user_type;
  const is_Sp = userType === "sp" ? true : false;

  const { t } = useTranslation();
  const [showRequestReject, setShowRequestReject] = useState(false);
  const [specialList, setspecialList] = useState([]);

  const [selectedSpecialist, setSelectedSpecialist] = useState([]);

  // document preview state start
  const [showView, setShowview] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mediaArray, setMediaarray] = useState([]);
  const [documentUrl, setDocumenturl] = useState([]);
  // document preview state end

  let orderType = [
    {
      value: "buyer",
      label: "Buyer",
    },
    {
      value: "seller",
      label: "Seller",
    },
  ];

  let paymentStatus = [
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "done",
      label: "Completed",
    },
  ];

  const [selectedOrdertype, setSelectedordertype] = useState({});

  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [loadState, setLoadState] = useState(true)

  const [showmarkcomplete, setShowmarkcomplete] = useState(false);
  const [errors, setErrors] = useState({
    o_type: "",
    vehicle_brand: "",
    vehicle_km: "",
    vehicle_type: "",
    vehicle_age: "",
    vehicle_color: "",
    vehicle_number_plate: "",
    vehicle_power: "",
    vehicle_location: "",
    specialist: "",
    payment_id: "",
  });

  const [vehicaldetail, setVehicaldetail] = useState({
    o_type: "",
    vehicle_brand: "",
    vehicle_km: "",
    vehicle_type: "",
    vehicle_age: "",
    vehicle_color: "",
    vehicle_number_plate: "",
    vehicle_power: "",
    vehicle_location: "",
    lat: "",
    lng: "",
    payment_id: "",
    payment_status: {},
    order_status: "",
  });

  const [userDetail, setUserdetail] = useState({
    email: "",
    first_name: "",
    mobile: "",
    profile_pic: "",
  });

  useEffect(() => {
    getDetail();
    // eslint-disable-next-line
  }, [getRequestid]);

  const getDetail = async () => {
    let getRequestid = window.location.pathname.split("/").pop();
    let updatedList = [];
    const specialresponce = await getSpecialList(props.access);
    if (specialresponce?.status === 200) {
      const specialresult = await specialresponce.json();
      let specialist = {};
      specialresult?.data?.forEach((item) => {
       // console.log("specialist id", item?.basic_details?.id);
        specialist = {
          value: item?.basic_details?.id,
          label: item?.basic_details?.fullname,
        };
        updatedList.push(specialist);
      });
      setspecialList(updatedList);
    }
    setTimeout(()=>setLoadState(false), 1000)

    let getRequestdetailresponse = await getRequestdetail(
      props?.access,
      getRequestid
    );

    if (getRequestdetailresponse?.status === 200) {
      let getResponse = await getRequestdetailresponse.json();
     // console.log("Req detail Response", getResponse);

      setUserdetail({
        email: getResponse?.user_details[0]?.email,
        first_name: getResponse?.user_details[0]?.first_name,
        mobile: getResponse?.user_details[0]?.mobile,
        profile_pic: getResponse?.user_details[0]?.profile_pic,
      });

      setSelectedordertype(
        getResponse?.basic_info[0]?.o_type === "buyer"
          ? orderType[0]
          : orderType[1]
      );

      // assign selected specialist using id

      let getSpecialist = updatedList.filter(
        (item) => item.value === Number(getResponse?.basic_info[0]?.assign_id)
      );
       
      if (getSpecialist?.length === 0) {
        setSelectedSpecialist([]);
      } else {
        setSelectedSpecialist(getSpecialist[0]);
      }
      //console.log("Demioasd",getSpecialist);
      // set images video and pdf

      let image = {};
      let imagesList = [];
      getResponse?.order_media_images?.forEach((item) => {
        image = {
          file_path: item.path,
          name: item.file_name,
          real_name: item.title,
          doc_id: item.id,
          order_id: item.order_id,
        };
        imagesList.push(image);
      });
      setImages(imagesList);

      let videos = {};
      let videosList = [];
      getResponse?.order_media_videos?.forEach((item) => {
        videos = {
          file_path: item?.path,
          name: item?.file_name,
          real_name: item?.title,
          order_id: item.order_id,
          doc_id: item?.id,
        };
        videosList.push(videos);
      });
      setVideos(videosList);

      let document = {};
      let documentList = [];
      getResponse?.order_media_documents?.forEach((item) => {
        let icon = "";
        switch (item.path.split(".").pop().toLowerCase()) {
          case "pdf":
            icon = pdf;
            break;
          case "doc":
            icon = doc_doc;
            break;
          case "docx":
            icon = doc_doc;
            break;
          case "ppt":
            icon = ppt_doc;
            break;
          case "pptx":
            icon = ppt_doc;
            break;
          case "xls":
            icon = excel_doc;
            break;
          case "xlsx":
            icon = excel_doc;
            break;
          default:
            break;
        }
        if (icon !== "") {
          document = {
            file_path: item?.path,
            name: item?.file_name,
            real_name: item?.title,
            doc_id: item?.id,
            order_id: item.order_id,
            icon: icon,
          };
          documentList.push(document);
        }
      });
      setDocuments(documentList);

      if (
        getResponse?.basic_info[0]?.current_status === "complete" ||
        getResponse?.basic_info[0]?.current_status === "completed"
      ) {
        setShowmarkcomplete(false);
      } else {
        setShowmarkcomplete(true);
      }

      setVehicaldetail({
        ...vehicaldetail,
        o_type: getResponse?.basic_info[0]?.o_type,
        lat: getResponse?.basic_info[0]?.lat,
        lng: getResponse?.basic_info[0]?.lng,
        base_url: getResponse?.payment_receipt_base_url,
        payment_receipt: getResponse?.basic_info[0]?.payment_receipt?getResponse?.basic_info[0]?.payment_receipt:"",
        vehicle_brand: getResponse?.order_details[0]?.vehicle_brand,
        vehicle_km: getResponse?.order_details[0]?.vehicle_km,
        vehicle_type: getResponse?.order_details[0]?.vehicle_type,
        vehicle_age: getResponse?.order_details[0]?.vehicle_age,
        vehicle_color: getResponse?.order_details[0]?.vehicle_color,
        vehicle_number_plate:
          getResponse?.order_details[0]?.vehicle_number_plate,
        vehicle_power: getResponse?.order_details[0]?.vehicle_power,
        vehicle_location: getResponse?.order_details[0]?.vehicle_location,
        payment_status:getResponse?.basic_info[0]?.payment_status === "done" ? paymentStatus[1]: paymentStatus[0],
        order_status: getResponse?.basic_info[0]?.current_status,
        payment_id:getResponse?.basic_info[0]?.payment_id===null?"":getResponse?.basic_info[0]?.payment_id,
        sp_status:getResponse?.basic_info[0].sp_status,
        reject_reason:getResponse?.basic_info[0].reject_reason,
      });
    }

   // console.log("admin request list response", getRequestdetailresponse);
  };

  const returnIcon = async (url) => {
    let icon = "";
    switch (url.split(".").pop().toLowerCase()) {
      case "pdf":
        icon = pdf;
        break;
      case "doc":
        icon = doc_doc;
        break;
      case "docx":
        icon = doc_doc;
        break;
      case "ppt":
        icon = ppt_doc;
        break;
      case "pptx":
        icon = ppt_doc;
        break;
      case "xls":
        icon = excel_doc;
        break;
      case "xlsx":
        icon = excel_doc;
        break;
      default:
        break;
    }
    return icon;
  };

  const vehicaldetailChange = async (e) => {
    setVehicaldetail({
      ...vehicaldetail,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      payment_id: "",
    });
  };

  const specialistOnchange = (value) => {
    setSelectedSpecialist(value);
    setErrors({
      ...errors,
      specialist: "",
    });
  };

  const orderTypechange = (value) => {
    setSelectedordertype(value);
  };

  const paymentstautsChange = (value) => {
    setVehicaldetail({
      ...vehicaldetail,
      payment_status: value,
    });
    setErrors({
      ...errors,
      payment_id: "",
    });
  };

  const locationOnchange = (value) => {
    setVehicaldetail({
      ...vehicaldetail,
      vehicle_location: value,
    });
    setErrors({
      ...errors,
      vehicle_location: "",
    });
  };

  const getSelectedlocation = async (value) => {
    const results = await geocodeByAddress(value);
    const latlng = await getLatLng(results[0]);
    setVehicaldetail({
      ...vehicaldetail,
      vehicle_location: value,
      lat: latlng.lat,
      lng: latlng.lng,
    });
  };

  const validation = () => {
    let checkValue = true;

    if (userType === "sp") {
      if (vehicaldetail.vehicle_brand === "") {
        errors.vehicle_brand = is_Sp
          ? t("pages.specialist.req_detail.brand-warn")
          : "Enter your car brand";
        checkValue = false;
      }

      if (vehicaldetail.vehicle_km === "") {
        errors.vehicle_km = is_Sp
          ? t("pages.specialist.req_detail.km-warn")
          : "Enter your car km";
        checkValue = false;
      }

      if (vehicaldetail.vehicle_type === "") {
        errors.vehicle_type = is_Sp
          ? t("pages.specialist.req_detail.car-type-warn")
          : "Enter your car type";
        checkValue = false;
      }

      if (vehicaldetail.vehicle_age === "") {
        errors.vehicle_age = is_Sp
          ? t("pages.specialist.req_detail.age-warn")
          : "Enter your car age";
        checkValue = false;
      }

      if (vehicaldetail.vehicle_color === "") {
        errors.vehicle_color = is_Sp
          ? t("pages.specialist.req_detail.colour-warn")
          : "Enter your car color";
        checkValue = false;
      }

      if (vehicaldetail.vehicle_number_plate === "") {
        errors.vehicle_number_plate = is_Sp
          ? t("pages.specialist.req_detail.number-warn")
          : "Enter your number plate";
        checkValue = false;
      }

      if (vehicaldetail.vehicle_power === "") {
        errors.vehicle_power = is_Sp
          ? t("pages.specialist.req_detail.power-warn")
          : "Enter your car power";
        checkValue = false;
      }

      setErrors({
        ...errors,
        vehicle_brand: errors.vehicle_brand,
        vehicle_km: errors.vehicle_km,
        vehicle_type: errors.vehicle_type,
        vehicle_age: errors.vehicle_age,
        vehicle_color: errors.vehicle_color,
        vehicle_number_plate: errors.vehicle_number_plate,
        vehicle_power: errors.vehicle_power,
      });
    } else {
     // console.log("Specialist length", selectedSpecialist);
      if (selectedSpecialist.length === 0) {
        errors.specialist = "Select specialist";
        checkValue = false;
      }

      if (vehicaldetail.payment_status.value === "done") {
        if (vehicaldetail.payment_id === "") {
          errors.payment_id = "Enter payment id";
          checkValue = false;
        }
      }

      setErrors({
        ...errors,
        specialist: errors.specialist,
        payment_id: errors.payment_id,
      });
    }

    if (checkValue === false) {
      return false;
    } else {
      return true;
    }
  };

  const attachFile = async (e, type) => {
    let allow = true;
    if (type === "images") {
      if (
        e.target.files[0].name.split(".").pop().toLowerCase() === "png" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "jpeg" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "jpg" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "webp" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "jfif" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "svg"
      ) {
        allow = true;
      } else {
        allow = false;
      }
    } else if (type === "videos") {
      if (
        e.target.files[0].name.split(".").pop().toLowerCase() === "mp4" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "mov" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "webm" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "wmv" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "avi" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "avchd" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "flv" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "f4v" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "swf" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "mkv" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "html5"
      ) {
        allow = true;
      } else {
        allow = false;
      }
    } else {
      if (
        e.target.files[0].name.split(".").pop().toLowerCase() === "pdf" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "doc" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "docx" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "ppt" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "pptx" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "xls" ||
        e.target.files[0].name.split(".").pop().toLowerCase() === "xlsx"
      ) {
        allow = true;
      } else {
        allow = false;
      }
    }

    if (allow === true) {
      let getUploadFileresponse = await uploadFile(
        props.access,
        e.target.files[0],
        type
      );

      let getFile = await getUploadFileresponse.json();

      let file = {};
        //console.log(getFile?.data[0])
      if (type === "documents") {
        let icon = await returnIcon(getFile?.data[0]?.file_path);
        file = {
          file_path: getFile?.data[0]?.file_path,
          name: getFile?.data[0]?.name,
          real_name: getFile?.data[0]?.real_name,
          doc_id:"",
          icon: icon,
        };
      } else {
        file = {
          file_path: getFile?.data[0]?.file_path,
          name: getFile?.data[0]?.name,
          real_name: getFile?.data[0]?.real_name,
          doc_id:"",
        };
      }

      if (type === "images") {
        let updatedImages = [...images];
        updatedImages.push(file);
        setImages(updatedImages);
      } else if (type === "videos") {
        let updatedVideos = [...videos];
        updatedVideos.push(file);
        setVideos(updatedVideos);
      } else if (type === "documents") {
        let updatedDocuments = [...documents];
        updatedDocuments.push(file);
        setDocuments(updatedDocuments);
      }
    } else {
      toast.error(t("pages.specialist.req_detail.file-error"));
    }
    e.target.value = "";
  };

  const requestUpdate = async () => {
    if (validation()) {
      let documentList = [];

      if (documents.length > 0) {
        let document = {};
        documents.forEach((item) => {
          document = {
            file_path: item?.file_path,
            name: item?.name,
            real_name: item?.real_name,
          };
          documentList.push(document);
        });
      }

      let postRequestdetail = {
        id: getRequestid,
        o_type: selectedOrdertype?.value,
        vehicle_brand: vehicaldetail?.vehicle_brand,
        vehicle_type: vehicaldetail?.vehicle_type,
        vehicle_color: vehicaldetail?.vehicle_color,
        vehicle_power: vehicaldetail?.vehicle_power,
        vehicle_km: vehicaldetail?.vehicle_km,
        vehicle_age: vehicaldetail?.vehicle_age,
        vehicle_number_plate: vehicaldetail?.vehicle_number_plate,
        vehicle_location: vehicaldetail?.vehicle_location,
        lat: vehicaldetail?.lat,
        lng: vehicaldetail?.lng,
        specialist_id: selectedSpecialist?.value?.toString(),
        images: images,
        videos: videos,
        documents: documentList,
        post_type: userType === "sp" ? "sp" : "admin",
        trn_id:
          vehicaldetail?.payment_status?.value === "pending"
            ? ""
            : vehicaldetail?.payment_id,
        payment_status: vehicaldetail?.payment_status?.value,
      };

     // console.log("Post req response img", images, videos);

      const getPostrequestdetailresponse = await getPostrequestdetail(
        props.access,
        userType,
        postRequestdetail
      );

      if (getPostrequestdetailresponse.status === 201) {
        toast.success(
          is_Sp
            ? t("pages.specialist.toast-success")
            : "Request updated successfully"
        );
        //console.log( "request update response",await getPostrequestdetailresponse.json());
        getDetail();
      }
    }
  };

  const markComplete = async (e) => {
    e.preventDefault();

    let markcomplete = {
      order_id: getRequestid,
      order_status: "completed",
    };

    let getMarkcompleteresponse = await getMarkcomplete(
      markcomplete,
      props.access
    );

    if (getMarkcompleteresponse.status === 200) {
      toast.success( t("pages.specialist.req_detail.request-complete"));
      setShowmarkcomplete(false);
      setVehicaldetail({
        ...vehicaldetail,
        order_status: "completed",
      });
    }
  };

  const openDocument = (index, type) => {
    setCurrentIndex(index);
    let fileObject = {};
    if (type === "images") {
      let imagesList = [];
      images?.forEach((item) => {
        fileObject = {
          url: item.file_path,
          type: "photo",
          altTag: "some image",
        };
        imagesList.push(fileObject);
      });
      setMediaarray(imagesList);
    } else {
      let videosList = [];
      videos?.forEach((item) => {
        fileObject = {
          url: item.file_path,
          type: "video",
          altTag: "some image",
        };
        videosList.push(fileObject);
      });
     // console.log("Video play", videosList, index);
      setMediaarray(videosList);
    }
    setShowview(true);
  };

  const openPdf = (item) => {
    let docs = [];
    docs.push({
      uri: item?.file_path,
    });
    setDocumenturl(docs);
    setShowview(true);
  };
  const callRemoveUpload = async (doc_item) =>{
    if(doc_item.doc_id!==""){
      const data ={
        doc_id:doc_item.doc_id,
        order_id:doc_item.order_id,
      }
      const responce = await removeUploadDoc(props.access,data);
      if(responce.status){
        await responce.json();
        //console.log("delete", resultRemove)
        getDetail()
      }
    }
  }
  const closeMedia = (e, getIndex) => {
    //console.log("target name", e.target.name);
    switch (e.target.name) {
      case "mimages":
        const getImages = images.filter((item, index) => index !== getIndex);
        callRemoveUpload(images[getIndex]);
        setImages(getImages);
        break;
      case "mvideos":
        const getVideos = videos.filter((item, index) => index !== getIndex);
        callRemoveUpload(videos[getIndex])
        setVideos(getVideos);
        break;
      case "mdocuments":
        const getDocuments = documents.filter(
          (item, index) => index !== getIndex
        );
        callRemoveUpload(documents[getIndex])
        setDocuments(getDocuments);
        break;

      default:
        break;
    }
  };
  const openPaymentImage = (url) =>{
    setCurrentIndex(0);
    let imagesList = [ {
      url: url,
      type: "photo",
      altTag: "some image",
    }];
    setMediaarray(imagesList);
    setShowview(true);
  }
  if(loadState) 
  return <LoaderPage/>

  return (
    <div className="req-detail">
      <div className=" admin content-page">
        <div className=" admin content">
          <div className=" admin container-fluid">
            <div className=" admin row">
              <div className=" admin col-12">
                <div className=" admin card mt-2">
                  <div className=" admin card-body">
                    <form>
                      <div className=" admin row">
                        <div className=" admin d-flex mb-3">
                          <img
                            className=" admin flex-shrink-0 me-2 rounded-circle avatar-md"
                            alt="64x64"
                            onError={(event) => {
                              event.target.src = user;
                            }}
                            src={
                              userDetail.profile_pic === null
                                ? user
                                : props.profile_url + userDetail.profile_pic
                            }
                          />
                          <div className=" admin flex-grow-1 mt-1 user-desc">
                            <h5 className=" admin name mt-0 mb-1">
                              {userDetail.first_name}
                            </h5>
                            <p className=" admin desc text-muted mb-0">
                              {userDetail.email}
                            </p>
                          </div>
                          {userType === "sp" && showmarkcomplete === true ? (
                            <button
                              className="markas"
                              onClick={(e) => markComplete(e)}
                            >
                              {t("pages.specialist.req_detail.mark-complete")}
                            </button>
                          ) : (
                            <></>
                          )}
                          {userType !== "sp" && vehicaldetail.sp_status === 'reject' && (
                            <button
                              className="admin btn btn-secondary m-0 width-xl waves-effect waves-light"
                              onClick={(e) =>{
                                e.preventDefault();
                                setShowRequestReject(true)
                              } }
                              style={{height:"40px"}}
                            >
                              Request Reject
                            </button>
                          )}
                        </div>
                        <div className=" admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label className=" admin form-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.specialist")
                              : "Specialist"}
                          </label>
                          <Select
                            options={specialList}
                            value={selectedSpecialist}
                            onChange={specialistOnchange}
                            placeholder={"Select specialist"}
                            isDisabled={
                              is_Sp
                                ? true
                                : showmarkcomplete === false
                                ? true
                                : false
                            }
                          />
                          <div className="text-danger">{errors.specialist}</div>
                        </div>
                        <div className=" admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label className=" admin form-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.user-type")
                              : "User Type"}
                          </label>
                          <Select
                            options={orderType}
                            value={selectedOrdertype}
                            onChange={orderTypechange}
                            placeholder="Select type"
                            isDisabled={userType === "sp" ? true : false}
                          />
                        </div>
                        <div className=" admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label className=" admin form-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.brand")
                              : "Car Brand"}
                          </label>
                          <input
                            type="text"
                            id=""
                            className=" admin form-control"
                            placeholder={
                              is_Sp
                                ? t("pages.specialist.req_detail.brand-place")
                                : "Enter your Car Brand"
                            }
                            value={vehicaldetail.vehicle_brand}
                            name="vehicle_brand"
                            disabled={userType === "sp" ? false : true}
                            onChange={(e) => vehicaldetailChange(e)}
                          />
                          <div className="text-danger">
                            {errors.vehicle_brand}
                          </div>
                        </div>
                        <div className=" admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label className=" admin form-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.km")
                              : "Car KM"}
                          </label>
                          <input
                            type="text"
                            id=""
                            className=" admin form-control"
                            placeholder={
                              is_Sp
                                ? t("pages.specialist.req_detail.km-place")
                                : "Enter Car KM"
                            }
                            disabled={userType === "sp" ? false : true}
                            name="vehicle_km"
                            value={vehicaldetail.vehicle_km}
                            onChange={(e) => vehicaldetailChange(e)}
                          />
                          <div className="text-danger">{errors.vehicle_km}</div>
                        </div>
                        <div className=" admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label className=" admin form-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.car-type")
                              : "Car Type"}
                          </label>
                          <input
                            type="text"
                            id=""
                            className=" admin form-control"
                            placeholder={
                              is_Sp
                                ? t(
                                    "pages.specialist.req_detail.car-type-place"
                                  )
                                : "Enter your Car Type"
                            }
                            value={vehicaldetail.vehicle_type}
                            disabled={userType === "sp" ? false : true}
                            name="vehicle_type"
                            onChange={(e) => vehicaldetailChange(e)}
                          />
                          <div className="text-danger">
                            {errors.vehicle_type}
                          </div>
                        </div>
                        <div className=" admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label className=" admin form-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.age")
                              : "Car Age"}
                          </label>
                          <input
                            type="text"
                            id=""
                            className=" admin form-control"
                            placeholder={
                              is_Sp
                                ? t("pages.specialist.req_detail.age-place")
                                : "Enter your Car Age"
                            }
                            value={vehicaldetail.vehicle_age}
                            disabled={userType === "sp" ? false : true}
                            name="vehicle_age"
                            onChange={(e) => vehicaldetailChange(e)}
                          />
                          <div className="text-danger">
                            {errors.vehicle_age}
                          </div>
                        </div>
                        <div className=" admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label className=" admin form-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.colour")
                              : "Car Color"}
                          </label>
                          <input
                            type="text"
                            className=" admin form-control"
                            id="colorpicker-togglepaletteonly"
                            placeholder={
                              is_Sp
                                ? t("pages.specialist.req_detail.colour-place")
                                : "Select Car Color"
                            }
                            value={vehicaldetail.vehicle_color}
                            disabled={userType === "sp" ? false : true}
                            name="vehicle_color"
                            onChange={(e) => vehicaldetailChange(e)}
                          />
                          <div className="text-danger">
                            {errors.vehicle_color}
                          </div>
                        </div>
                        <div className=" admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label className=" admin form-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.number")
                              : "Car Number"}
                          </label>
                          <input
                            type="text"
                            id=""
                            className=" admin form-control"
                            placeholder={
                              is_Sp
                                ? t("pages.specialist.req_detail.number-place")
                                : "Enter your Car Number"
                            }
                            value={vehicaldetail.vehicle_number_plate}
                            disabled={userType === "sp" ? false : true}
                            name="vehicle_number_plate"
                            onChange={(e) => vehicaldetailChange(e)}
                          />
                          <div className="text-danger">
                            {errors.vehicle_number_plate}
                          </div>
                        </div>
                        <div className=" admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label className=" admin form-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.power")
                              : "Car Power"}
                          </label>
                          <input
                            type="text"
                            id=""
                            className=" admin form-control"
                            placeholder={
                              is_Sp
                                ? t("pages.specialist.req_detail.power-place")
                                : "Enter Car Power"
                            }
                            value={vehicaldetail.vehicle_power}
                            disabled={userType === "sp" ? false : true}
                            name="vehicle_power"
                            onChange={(e) => vehicaldetailChange(e)}
                          />
                          <div className="text-danger">
                            {errors.vehicle_power}
                          </div>
                        </div>
                        <div className=" admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label className=" admin form-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.location")
                              : "Location"}
                          </label>
                          <PlacesAutocomplete
                            name="location"
                            value={vehicaldetail.vehicle_location}
                            onChange={locationOnchange}
                            onSelect={getSelectedlocation}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div className="vehicle-location-place">
                                <input
                                  type="text"
                                  className=" admin form-control"
                                  {...getInputProps({
                                    placeholder: is_Sp
                                      ? t(
                                          "pages.specialist.req_detail.location-place"
                                        )
                                      : "Enter your car location",
                                  })}
                                  value={vehicaldetail.vehicle_location}
                                  disabled={userType === "sp" ? false : true}
                                />
                                <div className="autoplace-location">
                                  {loading ? <div>...loading</div> : null}
                                  {suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#fff"
                                        : "#fff",
                                      border: "1px solid #dbdcde",
                                      padding: "0px 10px",
                                    };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        {suggestion.description}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>

                        <div className=" admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label className=" admin form-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.order-status")
                              : "Order status"}
                          </label>
                          <input
                            type="text"
                            id=""
                            className=" admin form-control"
                            value={
                              vehicaldetail?.order_status?.charAt(0)?.toUpperCase() + vehicaldetail?.order_status?.slice(1)
                            }
                            disabled={true}
                            name="order_status"
                          />
                        </div>
                        

                        {userType !== "sp"  && (
                          <div>
                            <label>Payment information</label>
                            <br/>
                            <div className=" admin row">
                              <div className="payment_receipt-div">
                                
                                {
                                  vehicaldetail.payment_receipt &&
                                  <>
                                  <span><strong>Uploaded Payment screenshot</strong></span><br/>
                                  <img onClick={()=>openPaymentImage(vehicaldetail.base_url+vehicaldetail.payment_receipt)} src={vehicaldetail.base_url+vehicaldetail.payment_receipt} alt={"..."} />
                                  </>
                                }
                              </div>
                              <div className=" admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <label className=" admin form-label">
                                  Payment id
                                </label>
                                <input
                                  type="text"
                                  id=""
                                  className=" admin form-control"
                                  placeholder="Enter payment id"
                                  value={vehicaldetail.payment_id}
                                  name="payment_id"
                                  onChange={(e) => vehicaldetailChange(e)}
                                />
                                <div className="text-danger">
                                  {errors.payment_id}
                                </div>
                              </div>
                              <div className=" admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <label className=" admin form-label">
                                  Payment status
                                </label>
                                <Select
                                  options={paymentStatus}
                                  value={vehicaldetail.payment_status}
                                  onChange={paymentstautsChange}
                                  placeholder="Select type"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className=" admin attached-files">
                          <label className=" admin form-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.attach")
                              : "Attached Files"}
                          </label>
                          <label className=" admin form-label req-images-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.images")
                              : "Images"}
                          </label>
                          <ul className=" admin list-inline files-list">
                            {images.map((item, index) => (
                              <li className=" admin list-inline-item file-box">
                                <a
                                  href
                                  onClick={() => openDocument(index, "images")}
                                >
                                  <img
                                    src={item?.file_path}
                                    className=" admin img-fluid img-thumbnail"
                                    alt="attached-img"
                                  />
                                </a>
                                <img
                                  src={close_icon}
                                  alt=""
                                  className="close-media"
                                  name="mimages"
                                  onClick={(e) => closeMedia(e, index)}
                                ></img>
                              </li>
                            ))}
                            <li className=" admin list-inline-item file-box ms-1">
                              <div className=" admin fileupload add-new-plus">
                                <span>
                                  <i className=" admin mdi-plus mdi"></i>
                                </span>
                                <input
                                  type="file"
                                  onChange={(e) => attachFile(e, "images")}
                                  className=" admin upload"
                                />
                              </div>
                            </li>
                          </ul>

                          <label className=" admin form-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.video")
                              : "Videos"}
                          </label>
                          <ul className=" admin list-inline files-list">
                            {videos.map((item, index) => (
                              <li className=" admin list-inline-item file-box">
                                <a
                                  href
                                  onClick={() => openDocument(index, "videos")}
                                >
                                  <img
                                    src={video_icon}
                                    className=" admin img-fluid img-thumbnail"
                                    alt="attached-img"
                                  />
                                </a>
                                <img
                                  src={close_icon}
                                  alt=""
                                  className="close-media"
                                  name="mvideos"
                                  onClick={(e) => closeMedia(e, index)}
                                ></img>
                              </li>
                            ))}
                            <li className=" admin list-inline-item file-box ms-1">
                              <div className=" admin fileupload add-new-plus">
                                <span>
                                  <i className=" admin mdi-plus mdi"></i>
                                </span>
                                <input
                                  type="file"
                                  className=" admin upload"
                                  onChange={(e) => attachFile(e, "videos")}
                                />
                              </div>
                            </li>
                          </ul>

                          <label className=" admin form-label">
                            {is_Sp
                              ? t("pages.specialist.req_detail.documents")
                              : "Documents"}
                          </label>
                          <ul className=" admin list-inline files-list">
                            {documents.map((item, index) => (
                              <li className=" admin list-inline-item file-box">
                                <a href>
                                  <img
                                    src={item.icon}
                                    className=" admin img-fluid img-thumbnail"
                                    alt="attached-img"
                                    onClick={() => openPdf(item)}
                                  />
                                </a>
                                <img
                                  src={close_icon}
                                  alt=""
                                  className="close-media"
                                  name="mdocuments"
                                  onClick={(e) => closeMedia(e, index)}
                                ></img>
                              </li>
                            ))}
                            <li className=" admin list-inline-item file-box ms-1">
                              <div className=" admin fileupload add-new-plus">
                                <span>
                                  <i className=" admin mdi-plus mdi"></i>
                                </span>
                                <input
                                  type="file"
                                  className=" admin upload"
                                  onChange={(e) => attachFile(e, "documents")}
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className=" admin col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3">
                          <button
                            type="button"
                            className=" admin btn btn-warning width-xl waves-effect waves-light"
                            onClick={() => requestUpdate()}
                          >
                            {is_Sp
                              ? t("pages.specialist.req_detail.save")
                              : "Save"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RequestrejectPage
        showRequestReject={showRequestReject}
        setShowRequestReject={setShowRequestReject}
      >
        <form className={"reject-form"}>
          <div className=" admin col-12 p-2">
            <label htmlFor="reason" className=" admin form-label">Reason of Specialist</label>
            <textarea rows={6} style={{backgroundColor: "#e9ecef"}} disabled value={vehicaldetail.reject_reason} type="text" id="reason" className=" admin form-control" placeholder={"Enter reject reason"} name="reason" />
          </div>
          <div className="admin float-right pe-2 pt-2">
            <button type="button" onClick={()=>setShowRequestReject(false)} className="admin btn btn-secondary m-0 width-xl waves-effect waves-light">Close</button>
          </div>
        </form>
      </RequestrejectPage>
      {/* Document viewer */}
      <ViewerPage
        showView={showView}
        setShowview={setShowview}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        mediaArray={mediaArray}
        setMediaarray={setMediaarray}
        documentUrl={documentUrl}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  access: state?.adminlogin?.access,
  profile_url: state.adminlogin.profile_pic_base_url,
  admin_data: state?.adminlogin?.admin_data,
});

export default connect(mapStateToProps)(RequestdetailPage);
