export const getFeedbackdetail = async (access, feedbackDetail) => {
  try {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${access}`);

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/submit-feedback`,
      {
        headers,
        method: "POST",
        body: JSON.stringify(feedbackDetail),
      }
    );

    return getResponse;
  } catch (error) {}
};
