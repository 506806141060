import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { addAboutus } from '../util/aboutus';
import "./AboutusPage.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from 'draft-js';
import { Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getAboutusdetail } from '../../../aboutus/util/aboutus';
import draftjsToHtml from 'draftjs-to-html';
import { convertFromRaw } from 'draft-js';
import { updateaboutus } from '../util/updateaboutus';
import LoaderPage from '../../loader/components/LoaderPage';

function AboutusPage(props) {
    const [tabKey, setTabKey] = useState("english");
    const [aboutusState, setAboutusState] = useState("Update");
    const [aboutusInputState, setAboutusInputState] = useState("Read")
    const [aboutusList, setaboutusList] = useState([]);
    const [aboutInput,setAboutInput] = useState({
        title:"",
        title_sp:"",
    });
    const [description, setDescription] = useState(() =>
        EditorState.createEmpty()
    );
    const [description_sp, setDescription_sp] = useState(() =>
        EditorState.createEmpty()
    );
    const [loadState, setLoadState] = useState(true)
    useEffect(()=>{
        callGetAboutus()
    // eslint-disable-next-line
    },[])
//   useEffect(() => {
//     console.log(convertToRaw(description.getCurrentContent()));
//   }, [description]);
    const callAddAboutus = async (e) =>{
        e.preventDefault();
        if(aboutInput.title.length>0){
            const data = {
                title:aboutInput.title,
                description:JSON.stringify(convertToRaw(description.getCurrentContent())),
                title_sp:aboutInput.title_sp,
                description_sp:JSON.stringify(convertToRaw(description_sp.getCurrentContent()))
            }
            const aboutResponce = await addAboutus(props.access,data);
            if(aboutResponce.status === 201){
                await aboutResponce.json();
                //console.log(aboutResult);
                setDescription(()=>EditorState.createEmpty())
                setDescription_sp(()=>EditorState.createEmpty())
                setAboutInput({title:"", title_sp:""})
                toast("Successfull added Abouts content")
            }
        }else{
            toast.error(aboutInput.title===""?"English title field is empty":"Spanish title field is empty")
        }
    }
    const callGetAboutus = async () => {
        const responceAbout = await getAboutusdetail(props.access);
        if(responceAbout.status === 200){
            const resultAbout = await responceAbout.json();
            setaboutusList(resultAbout)
            const convertedState = convertFromRaw(JSON.parse(resultAbout[0].description))
            const convertedState_sp = convertFromRaw(JSON.parse(resultAbout[0].description_sp))
            setDescription(()=>EditorState.createWithContent(convertedState))
            setDescription_sp(()=>EditorState.createWithContent(convertedState_sp))
            setAboutInput({
                title:resultAbout[0].title,
                title_sp:resultAbout[0].title_sp,
                id:resultAbout[0].id,
            })
            setAboutusState("Update")
            setAboutusInputState("Read")
        }else{
            await responceAbout.json();
            //console.log(resultAbout);
            setAboutusState("Save")
            setAboutusInputState("Write")
        }
        setTimeout(()=>setLoadState(false), 100)
    }
    const callUpdateAboutus = async (e) =>{
        e.preventDefault();
        if(aboutInput.title.length>0&&aboutInput.title_sp.length>0){
            const data = {
                title:aboutInput.title,
                description:JSON.stringify(convertToRaw(description.getCurrentContent())),
                title_sp:aboutInput.title_sp,
                description_sp:JSON.stringify(convertToRaw(description_sp.getCurrentContent())),
                id:aboutInput.id
            }
            const aboutResponce = await updateaboutus(props.access,data);
            if(aboutResponce.status === 200){
                await aboutResponce.json();
                //console.log(aboutResult);
                setDescription(()=>EditorState.createEmpty())
                setDescription_sp(()=>EditorState.createEmpty())
                setAboutInput({title:"", title_sp:""})
                toast("Successfull Update Abouts content")
                callGetAboutus()
            }
        }else{
            toast.error(aboutInput.title===""?"English title field is empty":"Spanish title field is empty")
        }
    }

  if(loadState) 
    return <LoaderPage/>
  
  return (
    <div className='aboutus-contents'>
       <div class="admin content-page">
            <div class="admin content">
                <div class="admin container-fluid">
                    <div class="admin row">
                    {aboutusInputState==="Write"?
                        <div class="admin col-12">
                            <div class="admin card mt-3">
                                <div class="admin card-body">
                                    {aboutusState==="Update"&&<div className='col terms-display-right'>
                                            <button onClick={()=>setAboutusInputState("Read")}>
                                                <i className='fa fa-times' />
                                        </button>
                                    </div>}
                                    <form onSubmit={aboutusState==="Update"?callUpdateAboutus:callAddAboutus}>
                                    <Tabs id={`controlled-tab-example`}  activeKey={tabKey} onSelect={(k) => setTabKey(k)} className="admin nav nav-tabs total-cnt about-tab" variant={"pills tabs-inner-div"}>
                                        <Tab className="admin tab-pane  inbox-widget" tabClassName='hi' eventKey="english" title={"English"}>
                                            <div class="admin row mt-3">
                                                <h4 class=" admin mt-0 mb-2 header-title">English Aboutus</h4>
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="title" class="admin form-label">Title</label>
                                                    <input value={aboutInput.title} onChange={(e)=>setAboutInput((res)=>({...res,[e.target.name]:e.target.value}))} type="text" id="title" name="title" class="admin form-control" placeholder="Enter title hear...." />
                                                </div>
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="description" class="admin form-label">Desription</label>
                                                    <Editor
                                                        editorState={description}
                                                        onEditorStateChange={setDescription}
                                                        toolbarClassName="toolbarClassName b-0"
                                                        wrapperClassName="rich-editor border rounded-3 mt-2"
                                                        editorClassName="editorClassName px-3"
                                                        placeholder='Enter Description hear...'
                                                    />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab className="admin tab-pane  inbox-widget" tabClassName='hi' eventKey="spanish" title={"Spanish"}>
                                                <div class="admin row mt-3">
                                                <h4 class=" admin mt-0 mb-2 header-title">Spanish Aboutus</h4>
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="title" class="admin form-label">Title</label>
                                                    <input value={aboutInput.title_sp} onChange={(e)=>setAboutInput((res)=>({...res,[e.target.name]:e.target.value}))} type="text" id="title_sp" name="title_sp" class="admin form-control" placeholder="Enter spanish title hear...." />
                                                </div>
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="description" class="admin form-label">Desription</label>
                                                    <Editor
                                                        editorState={description_sp}
                                                        onEditorStateChange={setDescription_sp}
                                                        toolbarClassName="toolbarClassName b-0"
                                                        wrapperClassName="rich-editor border rounded-3 mt-2"
                                                        editorClassName="editorClassName px-3"
                                                        placeholder='Enter Spanish description hear...'
                                                    />
                                                </div>
                                                
                                            </div>
                                        </Tab>
                                    </Tabs>
                                    <div class="admin mt-3 col-sm-12 text-left col-md-12 col-lg-12 col-xl-12">
                                        <button type="submit" class="admin btn btn-warning mt-1 width-xl waves-effect waves-light">{aboutusState}</button>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        :
                        <div class="admin col-12">
                            <div class="admin card mt-3">
                                <div class="admin card-body">
                                    {aboutusState==="Update"&&
                                        <div className='col terms-display-right'>
                                            <button onClick={()=>setAboutusInputState("Write")}>
                                                <i className='fa fa-pencil' />
                                            </button>
                                        </div>
                                    }  
                                    <h4 class=" admin mb-3 header-title">Aboutus</h4>
                                    <Tabs id={`controlled-tab-example`}  activeKey={tabKey} onSelect={(k) => setTabKey(k)} className="admin nav nav-tabs total-cnt about-tab" variant={"pills tabs-inner-div"}>
                                        <Tab className="admin tab-pane  inbox-widget" tabClassName='hi' eventKey="english" title={"English"}>
                                            {
                                                aboutusList.map((item)=>(
                                                    <div className='mb-2 mt-2'>
                                                        <h4 class=" admin mt-0 mb-2 header-title">{item.title}</h4>
                                                        <div dangerouslySetInnerHTML={{__html:draftjsToHtml(JSON.parse(item.description))}}></div>
                                                    </div>
                                                ))
                                            }
                                        </Tab>
                                        <Tab className="admin tab-pane  inbox-widget" tabClassName='hi' eventKey="spanish" title={"Spanish"}>
                                            {
                                                aboutusList.map((item)=>(
                                                    <div className='mb-2 mt-2'>
                                                        <h4 class=" admin mt-0 mb-2 header-title">{item.title_sp}</h4>
                                                        <div dangerouslySetInnerHTML={{__html:draftjsToHtml(JSON.parse(item.description_sp))}}></div>
                                                    </div>
                                                ))
                                            }
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    }
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}
const mapStateToProps = (store) =>({
    access:store?.adminlogin?.access
})
export default connect(mapStateToProps)(AboutusPage);