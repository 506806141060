import { PACKAGE_LIST } from "./actionTypes";
import { LOGIN_PACKAGE } from "./actionTypes";
import { SPECIALIST_ID } from "./actionTypes";

export const storePackagelist = (packagelist) => async (dispatch) => {
  dispatch({
    type: PACKAGE_LIST,
    packagelist: packagelist,
  });
};

export const setShowpackagelist = (showPackagelist) => async (dispatch) => {
  dispatch({
    type: LOGIN_PACKAGE,
    showPackagelist: showPackagelist,
  });
};

export const setSpecialistid = (specialist_id) => async (dispatch) => {
  dispatch({
    type: SPECIALIST_ID,
    specialist_id: specialist_id,
  });
};
