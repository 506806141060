import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { addSpecialistData } from "../util/addspecialist";
import { uploadFile } from "../util/uploadFile";
import "./AddspecialistPage.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { getSpecialist } from "../util/getspecialist";
import { editSpecialistDetails } from "../util/editspecialist";
import { useNavigate } from "react-router";
import user_circle from "../../../../assets/profile_pic.svg";
import Select from "react-select";
import { updateSpecialistPassword } from "../util/updatePassword";
import LoaderPage from "../../loader/components/LoaderPage";

function AddspecialistPage(props) {
  //const [images, setimages] = useState({});
  const navigate = useNavigate();
  const inputField = {
    email: "",
    password: "",
    password2: "",
    fullname: "",
    mobile: "",
    age: "",
    specialization: "",
    location: "",
    user_type: "sp",
    profile_pic: "",
    experience: "",
    lat: "",
    lng: "",
  };
  const emailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const passwordRegex = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\w\W]){1,})(?!.*\s).{8,}$/;
  const [updateState, setUpdateState] = useState("detail");
  const [loadState, setLoadState] = useState(true)
  const state = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );
  const [inputState, setInputState] = useState("add");
  const [specialInput, setSpecialInput] = useState({
    email: "",
    password: "",
    password2: "",
    fullname: "",
    mobile: "",
    age: "",
    specialization: [],
    location: "",
    user_type: "sp",
    profile_pic: "",
    experience: "",
    lat: "",
    lng: "",
  });

  let specialisation = [
    {
      value: "Car Engine",
      label: "Car Engine",
    },
    {
      value: "Car Exterior",
      label: "Car Exterior",
    },
    {
      value: "Car Interior",
      label: "Car Interior",
    },
  ];

  const [eyeIcon, setEyeicon] = useState({
    password: false,
    confirmpassword: false,
  });

  const [errors, setErrors] = useState(inputField);
  useEffect(() => {
    confirmStateMethod(state);
    // eslint-disable-next-line
  }, [state]);
  const confirmStateMethod = (state) => {
    switch (state) {
      case "add":
        setInputState("Add");
        break;
      default:
        setInputState("Edit");
        callgetInputDetails();
        break;
    }
    setTimeout(()=>setLoadState(false), 100)
  };

  const callgetInputDetails = async () => {
    const responceSpecialist = await getSpecialist(props.access, state);
    if (responceSpecialist.status === 200) {
      const resultSpecialist = await responceSpecialist.json();
      //console.log("specialist detail", resultSpecialist);
      const data = resultSpecialist.data[0];

      let getValue = specialisation.filter(
        (item) => item.value === data.specialization
      );

    //  console.log("selected spe", getValue);

      setSpecialInput({
        password: "",
        password2: "",
        email: data.email ? data.email : "",
        first_name: data.first_name ? data.first_name : "",
        last_name: data.last_name ? data.last_name : "",
        fullname: data.first_name ? data.first_name : "",
        mobile: data.mobile ? data.mobile : "",
        age: data.age ? data.age : "",
        specialization: getValue.length === 0 ? "" : getValue[0],
        location: data.location ? data.location : "",
        user_type: data.user_type ? data.user_type : "",
        profile_pic: data?.profile_pic?.substring(
          data?.profile_pic?.lastIndexOf("/") + 1
        ),
        experience: data.experience ? data.experience : "",
        lat: data.lat ? data.lat : "",
        lng: data.lng ? data.lng : "",
        id: state,
      });
      //console.log(specialInput, "sdjh");
    } else {
    }
  };

  const locationOnchange = (value) => {
    setSpecialInput({
      ...specialInput,
      location: value,
    });
    setErrors({
      ...errors,
      location: "",
    });
  };
  const getSelectedlocation = async (value) => {
    const results = await geocodeByAddress(value);
    const latlng = await getLatLng(results[0]);
    setSpecialInput({
      ...specialInput,
      location: value,
      lat: latlng.lat,
      lng: latlng.lng,
    });
  };
  const specialInputChange = (e) => {
    //console.log("specialisation", e.target.value);
    setSpecialInput({
      ...specialInput,
      [e.target.name]: e.target.value,
    });

    switch (e.target.name) {
      case "email":
        setErrors({
          ...errors,
          email: "",
        });
        break;
      case "password":
        setErrors({
          ...errors,
          password: "",
        });
        break;
      case "mobile":
        setErrors({
          ...errors,
          mobile: "",
        });
        break;

      case "specialization":
        setErrors({
          ...errors,
          specialization: "",
        });
        break;

      case "age":
        setErrors({
          ...errors,
          age: "",
        });
        break;
      case "experience":
        setErrors({
          ...errors,
          experience: "",
        });
        break;
      case "location":
        setErrors({
          ...errors,
          location: "",
        });
        break;
      case "password2":
        setErrors({
          ...errors,
          password2: "",
        });
        break;
      case "fullname":
        setErrors({
          ...errors,
          fullname: "",
        });
        break;
      default:
        break;
    }
  };
  const validation = () => {
    let result = true;
    if (!specialInput.email.match(emailRegex)) {
      errors.email = "Enter valid email";
      result = false;
    }
    if (!specialInput.mobile.match(mobileRegex)) {
      errors.mobile = "Enter valid mobile number";
      result = false;
    }
    if (specialInput.password !== specialInput.password2) {
      errors.password2 = "Confirm password not match";
      result = false;
    }
    if (!specialInput.password.match(passwordRegex)) {
      errors.password =
        "Your Password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase and 1 Numeric";
      result = false;
    }
    if (specialInput.password==="") {
      errors.password = "Enter your password";
      result = false;
    }

    if (specialInput.password2 === "") {
      errors.password2 = "Enter confirm password";
      result = false;
    }
    if (specialInput.fullname === "") {
      errors.fullname = "Enter full name";
      result = false;
    }
    if (specialInput.specialization.length === 0) {
      errors.specialization = "Select specialist type";
      result = false;
    }
    if (specialInput.experience === "") {
      errors.experience = "Enter experience";
      result = false;
    }
    if (specialInput.location === "") {
      errors.location = "Enter location";
      result = false;
    }

    if (specialInput.profile_pic === "") {
      errors.profile_pic = "Add profile pic";
      result = false;
    }
    if (!specialInput.age) {
      errors.age = "Enter age";
      result = false;
    }
    setErrors({
      ...errors,
      email: errors.email,
      profile_pic: errors.profile_pic,
      password: errors.password,
      password2: errors.password2,
      fullname: errors.fullname,
      mobile: errors.mobile,
      age: errors.age,
      specialization: errors.specialization,
      location: errors.location,
      user_type: errors.user_type,
      experience: errors.experience,
    });
    return result;
  };
  const validationEdit = () => {
    let result = true;
    if(updateState==="detail"){
      if (!specialInput.email.match(emailRegex)) {
        errors.email = "Enter valid email";
        result = false;
      }
      if (!specialInput.mobile.match(mobileRegex)) {
        errors.mobile = "Enter valid mobile number";
        result = false;
      }
      if (specialInput.fullname === "") {
        errors.fullname = "Enter full name";
        result = false;
      }
      if (specialInput.specialization.length === 0) {
        errors.specialization = "Select specialist type";
        result = false;
      }
      if (specialInput.experience === "") {
        errors.experience = "Enter experience";
        result = false;
      }
      if (specialInput.location === "") {
        errors.location = "Enter location";
        result = false;
      }

      if (specialInput.profile_pic === "") {
        errors.profile_pic = "Add profile pic";
        result = false;
      }
      if (!specialInput.age) {
        errors.age = "Enter age";
        result = false;
      }
    }else if(updateState==="password"){
      if (specialInput.password !== specialInput.password2) {
        errors.password2 = "Confirm password not match";
        result = false;
      }
      if (!specialInput.password.match(passwordRegex)) {
        errors.password =
          "Your Password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase and 1 Numeric";
        result = false;
      }
      if (specialInput.password==="") {
        errors.password = "Enter your password";
        result = false;
      }
  
      if (specialInput.password2 === "") {
        errors.password2 = "Enter confirm password";
        result = false;
      }
    }
    setErrors({
      ...errors,
      email: errors.email,
      profile_pic: errors.profile_pic,
      password: errors.password,
      password2: errors.password2,
      fullname: errors.fullname,
      mobile: errors.mobile,
      age: errors.age,
      specialization: errors.specialization,
      location: errors.location,
      user_type: errors.user_type,
      experience: errors.experience,
    });
    return result;
  };
  const callSubmitSpecialist = async (e) => {
    e.preventDefault();
    if (validation()) {
      const name = specialInput.fullname;
      const data = {
        email: specialInput?.email,
        first_name: name,
        last_name: name,
        mobile: specialInput?.mobile,
        password: specialInput?.password,
        password2: specialInput?.password2,
        age: specialInput?.age,
        specialization: specialInput?.specialization?.value,
        location: specialInput?.location,
        user_type: specialInput?.user_type,
        profile_pic: specialInput?.profile_pic,
        experience: specialInput?.experience,
        lat: specialInput?.lat,
        lng: specialInput?.lng,
      };
      const responceSpecialist = await addSpecialistData(props.access, data);
      if (responceSpecialist.status === 201) {
        await responceSpecialist.json();
        //console.log(resultSpecialist);
        setSpecialInput(inputField);
        toast.success("Specialist added successfull");
      } else if (responceSpecialist.status === 400) {
         await responceSpecialist.json();
        //console.log(resultSpecialist);
        toast.error("This email specialist already exist");
      }
    }
  };
  const callEditSpecialist = async (e) => {
    e.preventDefault();
    if (validationEdit()) {
      const name = specialInput.fullname;
      const data = {
        email: specialInput?.email,
        first_name: name,
        last_name: name,
        fullname: specialInput?.fullname,
        mobile: specialInput?.mobile,
        age: specialInput?.age,
        specialization: specialInput?.specialization?.value,
        location: specialInput?.location,
        user_type: specialInput?.user_type,
        profile_pic: specialInput?.profile_pic,
        experience: specialInput?.experience,
        lat: specialInput?.lat,
        lng: specialInput?.lng,
        id: state,
      };
      //console.log("sp data", data);
      const responceSpecialist = await editSpecialistDetails(
        props.access,
        data
      );
      if (responceSpecialist.status === 200) {
         await responceSpecialist.json();

        setSpecialInput(inputField);
        toast.success("Specialist edit successfull");
        navigate("/bc-admin/specialist");
      } else if (responceSpecialist.status === 400) {
        await responceSpecialist.json();
        //console.log(resultSpecialist);
        toast.error("This email specialist already exist");
      }
    }
  };
  const callUpdatePassword = async (e) =>{
    e.preventDefault();
    if(validationEdit()){
      const data = {
        password1:specialInput.password,
        password2:specialInput.password2
      }
      const reponce = await updateSpecialistPassword(props.access,data,specialInput.id);
      if(reponce.status){
         await reponce.json();
        toast("Update password succeccfull");
        setUpdateState("detail")
      }
    }
  }
  const callUploadFile = async (e) => {
    const getUpload = await uploadFile(
      props.access,
      e.target.files[0],
      "profile"
    );
    const result = await getUpload.json();
    //setimages(result.data[0]);
    setSpecialInput({
      ...specialInput,
      profile_pic: result.data[0].name,
    });
    setErrors({
      ...errors,
      profile_pic: "",
    });
    e.target.value = "";
  };

  const specialisationChange = (value) => {
    setSpecialInput({
      ...specialInput,
      specialization: value,
    });
    setErrors({
      ...errors,
      specialization: "",
    });
  };

  if(loadState) 
    return <LoaderPage/>

  return (
    <div className="addspecialist-contents">
      <div class="admin content-page">
        <div class="admin content">
          <div class="admin container-fluid">
            <div class="admin row">
              <div class="admin col-12">
                <div class="admin card mt-2">
                  <div class="admin card-body">
                    <form>
                      <div class="admin row">
                        <div class="admin d-flex mb-3">
                          {specialInput.profile_pic !== "" && (
                            <img
                              class="admin flex-shrink-0 me-2 rounded-circle avatar-md"
                              alt="64x64"
                              src={props.profile_url + specialInput.profile_pic}
                              onError={(event) => {
                                event.target.src = user_circle;
                              }}
                            />
                          )}

                          {specialInput.profile_pic === "" && (
                            <div className="admin avatar-md me-2 border rounded-circle upload-image-title">
                              <i className="fa fa-plus" />
                            </div>
                          )}

                          <form onSubmit={callUploadFile}>
                            <div class="admin flex-grow-1">
                              <div class="admin file file--upload">
                                {!specialInput.profile_pic ? (
                                  <label for="file">Add Picture</label>
                                ) : (
                                  <label for="file">Change Picture</label>
                                )}

                                {/* {inputState === "Edit" && !images.file_path && (
                                  <label for="file">Change Picture</label>
                                )} */}

                                <input
                                  onChange={callUploadFile}
                                  value={inputField.profile_pic.name}
                                  name="file"
                                  id="file"
                                  type="file"
                                  
                                />
                                <div className="text-danger">
                                  {errors.profile_pic}
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label for="fullname" class="admin form-label">
                            Full Name{" "}
                            <span
                              className="text-danger"
                              style={{ fontSize: 16 }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            value={specialInput.fullname}
                            onChange={specialInputChange}
                            type="text"
                            id="fullname"
                            name="fullname"
                            class="admin form-control"
                            placeholder="Enter Full Name"
                            disabled={updateState==="password"?true:false}
                            
                          />
                          <div className="text-danger">{errors.fullname}</div>
                        </div>
                        <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label for="email" class="admin form-label">
                            Email{" "}
                            <span
                              className="text-danger"
                              style={{ fontSize: 16 }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            value={specialInput.email}
                            onChange={specialInputChange}
                            type="email"
                            id="email"
                            name="email"
                            class="admin form-control"
                            placeholder="Enter email address"
                            disabled={updateState==="password"?true:false}
                          />
                          <div className="text-danger">{errors.email}</div>
                        </div>
                        <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label for="mobile" class="admin form-label">
                            Mobile Number{" "}
                            <span
                              className="text-danger"
                              style={{ fontSize: 16 }}
                            >
                              *
                            </span>{" "}
                          </label>
                          <input
                            value={specialInput.mobile}
                            onChange={specialInputChange}
                            type="number"
                            id="mobile"
                            name="mobile"
                            class="admin form-control"
                            placeholder="Enter your mobile no."
                            onWheel={(e) => e.target.blur()}
                            disabled={updateState==="password"?true:false}
                          />
                          <div className="text-danger">{errors.mobile}</div>
                        </div>
                        <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label for="age" class="admin form-label">
                            Age{" "}
                            <span
                              className="text-danger"
                              style={{ fontSize: 16 }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            value={specialInput.age}
                            onChange={specialInputChange}
                            type="text"
                            name="age"
                            id="age"
                            class="admin form-control"
                            placeholder="Enter your age"
                            disabled={updateState==="password"?true:false}
                          />
                          <div className="text-danger">{errors.age}</div>
                        </div>
                        <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label for="specialization" class="admin form-label">
                            Specialist{" "}
                            <span
                              className="text-danger"
                              style={{ fontSize: 16 }}
                            >
                              *
                            </span>
                          </label>
                          <Select
                            options={specialisation}
                            value={specialInput.specialization}
                            onChange={specialisationChange}
                            placeholder="Select type"
                            className={"sepecialization-select"}
                            isDisabled={updateState==="password"?true:false}
                          />
                          {/*<select
                            onChange={specialInputChange}
                            value={specialInput.specialization}
                            class="admin form-select"
                            name="specialization"
                            id="specialization"
                          >
                            <option value={"Car Engine"} selected>
                              Car Engine
                            </option>
                            <option value={"Car Exterior"}>Car Exterior</option>
                            <option value={"Car Interior"}>Car Interior</option>
                          </select>*/}
                          <div className="text-danger">
                            {errors.specialization}
                          </div>
                        </div>
                        <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 valuecol-xl-6">
                          <label for="experience" class="admin form-label">
                            Experience{" "}
                            <span
                              className="text-danger"
                              style={{ fontSize: 16 }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            value={specialInput.experience}
                            onChange={specialInputChange}
                            type="text"
                            id="experience"
                            name="experience"
                            class="admin form-control"
                            placeholder="Enter your experience"
                            disabled={updateState==="password"?true:false}
                          />
                          <div className="text-danger">{errors.experience}</div>
                        </div>
                        <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label for="location" class="admin form-label">
                            Location{" "}
                            <span
                              className="text-danger"
                              style={{ fontSize: 16 }}
                            >
                              *
                            </span>
                          </label>
                          <PlacesAutocomplete
                            name="location"
                            value={specialInput.location}
                            onChange={locationOnchange}
                            onSelect={getSelectedlocation}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div className="vehicle-location-place">
                                <input
                                  type="text"
                                  className="admin form-control"
                                  {...getInputProps({
                                    placeholder:
                                      "Enter your car location/ Post Code",
                                  })}
                                  disabled={updateState==="password"?true:false}
                                />
                                <div className="text-danger location-err">
                                  {errors.vehicle_location}
                                </div>
                                <div className="autoplace-location">
                                  {loading ? <div>...loading</div> : null}
                                  {suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#fff"
                                        : "#fff",
                                      border: "1px solid #dbdcde",
                                      padding: "0px 10px",
                                    };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        {suggestion.description}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <div className="text-danger">{errors.location}</div>
                        </div>

                        {
                          inputState==="Add"?
                          <>
                          <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <label for="password" class="admin form-label">
                              Password{" "}
                              <span
                                className="text-danger"
                                style={{ fontSize: 16 }}
                              >
                                *
                              </span>
                            </label>
                            <div className="admin input-group">
                              <input
                                value={specialInput.password}
                                onChange={specialInputChange}
                                type={eyeIcon.password ? "text" : "password"}
                                id="password"
                                name="password"
                                class="admin form-control password-box"
                                placeholder="Enter your password"
                              />
                              <span
                                class="input-group-text"
                                name="eye_pass"
                                onClick={() =>
                                  setEyeicon({
                                    ...eyeIcon,
                                    password: !eyeIcon.password,
                                  })
                                }
                              >
                                <i
                                  className={
                                    !eyeIcon.password
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                />
                              </span>
                            </div>
                            <div className="text-danger">{errors.password}</div>
                          </div>
                          <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <label for="password2" class="admin form-label">
                              Confirm Password{" "}
                              <span
                                className="text-danger"
                                style={{ fontSize: 16 }}
                              >
                                *
                              </span>
                            </label>
                            <div className="admin input-group">
                              <input
                                value={specialInput.password2}
                                onChange={specialInputChange}
                                type={
                                  eyeIcon.confirmpassword ? "text" : "password"
                                }
                                id="password2"
                                name="password2"
                                class="admin form-control password-box"
                                placeholder="Enter confirm password"
                              />
                              <span
                                class="input-group-text"
                                name="eye_confpass"
                                onClick={() =>
                                  setEyeicon({
                                    ...eyeIcon,
                                    confirmpassword: !eyeIcon.confirmpassword,
                                  })
                                }
                              >
                                <i
                                  className={
                                    !eyeIcon.confirmpassword
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                />
                              </span>
                            </div>
                            <div className="text-danger">{errors.password2}</div>
                          </div>
                          </>
                          :
                          updateState==="password"&&
                          <>
                          <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <label for="password" class="admin form-label">
                              Password{" "}
                              <span
                                className="text-danger"
                                style={{ fontSize: 16 }}
                              >
                                *
                              </span>
                            </label>
                            <div className="admin input-group">
                              <input
                                value={specialInput.password}
                                onChange={specialInputChange}
                                type={eyeIcon.password ? "text" : "password"}
                                id="password"
                                name="password"
                                class="admin form-control password-box"
                                placeholder="Enter your password"
                              />
                              <span
                                class="input-group-text"
                                name="eye_pass"
                                onClick={() =>
                                  setEyeicon({
                                    ...eyeIcon,
                                    password: !eyeIcon.password,
                                  })
                                }
                              >
                                <i
                                  className={
                                    !eyeIcon.password
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                />
                              </span>
                            </div>
                            <div className="text-danger">{errors.password}</div>
                          </div>
                          <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <label for="password2" class="admin form-label">
                              Confirm Password{" "}
                              <span
                                className="text-danger"
                                style={{ fontSize: 16 }}
                              >
                                *
                              </span>
                            </label>
                            <div className="admin input-group">
                              <input
                                value={specialInput.password2}
                                onChange={specialInputChange}
                                type={
                                  eyeIcon.confirmpassword ? "text" : "password"
                                }
                                id="password2"
                                name="password2"
                                class="admin form-control password-box"
                                placeholder="Enter confirm password"
                              />
                              <span
                                class="input-group-text"
                                name="eye_confpass"
                                onClick={() =>
                                  setEyeicon({
                                    ...eyeIcon,
                                    confirmpassword: !eyeIcon.confirmpassword,
                                  })
                                }
                              >
                                <i
                                  className={
                                    !eyeIcon.confirmpassword
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                />
                              </span>
                            </div>
                            <div className="text-danger">{errors.password2}</div>
                          </div>
                          </>
                        }
                        <div class="admin mt-3 col-sm-6 text-left col-md-6 col-lg-6 col-xl-6 d-flex">
                          {updateState==="password"?
                          <>
                            <div class="admin px-1">
                              <button
                                type="submit"
                                class="admin btn btn-warning m-0 width-xl waves-effect waves-light"
                                onClick={callUpdatePassword}
                              >
                                Update Password
                              </button>
                            </div>
                            <div class="admin px-1">
                              <button
                                type="button"
                                class="admin btn btn-secondary m-0 width-xl waves-effect waves-light"
                                onClick={()=>setUpdateState("detail")}
                              >
                                cancel
                              </button>
                            </div>
                          </>
                          :
                          <div class="admin  px-1">
                            <button
                              type="submit"
                              class="admin btn btn-warning m-0 width-xl waves-effect waves-light"
                              onClick={
                                inputState !== "Add"
                                  ? callEditSpecialist
                                  : callSubmitSpecialist
                              }
                            >
                              {inputState !== "Add"?"Update":"Save"}
                            </button>
                          </div>
                          }
                          {inputState==="Edit"&&updateState==="detail"&&
                          <div class="admin  px-1">
                            <button
                              type="button"
                              class="admin btn btn-secondary m-0 width-xl waves-effect waves-light"
                              onClick={()=>setUpdateState("password")}
                            >
                              Change Password
                            </button>
                          </div>
                          }
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  access: state?.adminlogin?.access,
  profile_url: state.adminlogin.profile_pic_base_url,
});
export default connect(mapStateToProps)(AddspecialistPage);
