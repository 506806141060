import React, { useState } from "react";
import "./RegisterPage.css";
import { connect } from "react-redux";
import HeaderPage from "../../header/components/HeaderPage";
import loginpage_image from "./../../../assets/loginpage_image.jpg";
import FooterPage from "../../footer/components/FooterPage";
import { Link, useNavigate } from "react-router-dom";
import { FormControl, InputGroup, Button } from "react-bootstrap";
import user_icon from "./../../../assets/user_icon.svg";
import call_icon from "./../../../assets/call_icon.svg";
import email_icon from "./../../../assets/email_icon.svg";
import password_icon from "./../../../assets/password_icon.svg";
import { getRegisterdetails } from "../util/register";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function RegisterPage(props) {
  const primaryBtn = props.theme.primaryBtn;
  const inputType = props.theme.inputForm;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const emailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

  const passRegex =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\w\W]){1,})(?!.*\s).{8,}$/;

  const mobileNumRegex = /^\d{10}$/;

  const [registerInput, setRegisterInput] = useState({
    fullname: "",
    userType: "",
    email: "",
    mobile: "",
    password: "",
    cpassword: "",
  });

  const [errors, setErrors] = useState({
    fullname: "",
    userType: "",
    email: "",
    mobile: "",
    password: "",
    cpassword: "",
  });
  const [passwordState, setPasswordState] = useState({
    password: false,
    cpassword: false,
  });

  const registerinputChange = (e) => {
    setRegisterInput({
      ...registerInput,
      [e.target.name]: e.target.value,
    });

    switch (e.target.name) {
      case "fullname":
        setErrors({
          ...errors,
          fullname: "",
        });
        break;
      case "email":
        setErrors({
          ...errors,
          email: "",
        });
        break;
      case "mobile":
        setErrors({
          ...errors,
          mobile: "",
        });
        break;
      case "password":
        setErrors({
          ...errors,
          password: "",
        });
        break;
      case "cpassword":
        setErrors({
          ...errors,
          cpassword: "",
        });
        break;

      default:
        break;
    }
  };

  const selectType = (e) => {
    if (e.target.id === "seller-opt") {
      setRegisterInput({
        ...registerInput,
        userType: "seller",
      });
    } else {
      setRegisterInput({
        ...registerInput,
        userType: "buyer",
      });
    }
    setErrors({
      errors,
      userType: "",
    });
  };

  const validation = () => {
    let checkValue = true;

    if (registerInput.fullname === "") {
      errors.fullname = t('pages.register.fullname-warn');
      checkValue = false;
    }

    if (registerInput.userType === "") {
      errors.userType = t('pages.register.type-warn');
      checkValue = false;
    }

    if (!registerInput.email.match(emailRegex)) {
      errors.email =
        props.language === t('pages.register.email-valid');
      checkValue = false;
    }

    if (!registerInput.password.match(passRegex)) {
      errors.password = t('pages.register.password-valid');
      checkValue = false;
    }

    if (registerInput.email === "") {
      errors.email = t('pages.register.email-warn');
      checkValue = false;
    }

    if (!registerInput.mobile.match(mobileNumRegex)) {
      errors.mobile = t('pages.register.mobile-valid');
      checkValue = false;
    }

    if (registerInput.mobile === "") {
      errors.mobile =  t('pages.register.mobile-warn');
      checkValue = false;
    }

    if (registerInput.password === "") {
      errors.password = t('pages.register.password-warn');
      checkValue = false;
    }

    if (registerInput.password !== registerInput.cpassword) {
      errors.cpassword = t('pages.register.c-password-warn-match');
      checkValue = false;
    }

    if (registerInput.cpassword === "") {
      errors.cpassword = t('pages.register.c-password-warn');
      checkValue = false;
    }

    setErrors({
      ...errors,
      fullname: errors.fullname,
      userType: errors.userType,
      email: errors.email,
      mobile: errors.mobile,
      password: errors.password,
      cpassword: errors.cpassword,
    });

    if (checkValue === false) {
      return false;
    } else {
      return true;
    }
  };

  const callRegister = async (e) => {
    e.preventDefault();
    if (validation()) {
      let registerDetail = {
        username: registerInput.fullname,
        email: registerInput.email,
        mobile: registerInput.mobile,
        password: registerInput.password,
        password2: registerInput.cpassword,
        user_type: registerInput.userType,
        first_name: registerInput.fullname,
        last_name: registerInput.fullname,
      };
      const getRegisterresponse = await getRegisterdetails(registerDetail);
      if (getRegisterresponse.status === 201) {
        navigate("/login", { state: { regisiterSuccess: true } });
      } else if (getRegisterresponse.status === 400) {
        toast.error(t('pages.register.registered'));
      } else if (getRegisterresponse.status === 500) {
        toast.error(t('pages.server-err'));
      }
    }
  };

  return (
    <>
      <HeaderPage color={"white"} logo={"black"} background={"transparent"} />
      <div className={"register col-12 row m-0"}>
        <div className={"col-6 px-10 py-5"}>
          <div className={"register-box"} px={"28px"}>
            <div className={"register-heading"}>
              <h3 className={"register-title  m-4 "}>
                {t("pages.register.title")}
              </h3>
              <label className={"secoundry"}>
                {t("pages.register.subtitle")}
              </label>
            </div>
            <form className={"register-form"}>
              <div className={"form-group"}>
                <div className={"form-title"}>
                  {t("pages.register.fullname")}
                </div>
                <InputGroup style={inputType}>
                  <InputGroup.Text>
                    <img
                      src={user_icon}
                      alt={"."}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </InputGroup.Text>
                  <FormControl
                    placeholder={t("pages.register.fullname-place")}
                    name={"fullname"}
                    onChange={(e) => registerinputChange(e)}
                    value={registerInput.fullname}
                    type={"text"}
                    className={"input-field"}
                  />
                </InputGroup>
                <div className="text-danger">{errors.fullname}</div>
              </div>
              <div className={"form-group"}>
                <div className={"form-title"}>{t("pages.register.email")}</div>
                <InputGroup style={inputType}>
                  <InputGroup.Text>
                    <img
                      src={email_icon}
                      alt={"."}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </InputGroup.Text>
                  <FormControl
                    placeholder={t("pages.register.email-place")}
                    name={"email"}
                    onChange={(e) => registerinputChange(e)}
                    value={registerInput.email}
                    type={"email"}
                    className={"input-field"}
                  />
                </InputGroup>
                <div className="text-danger">{errors.email}</div>
              </div>
              <div className={"form-group"}>
                <div className={"form-title"}>{t("pages.register.mobile")}</div>
                <InputGroup style={inputType}>
                  <InputGroup.Text>
                    <img
                      src={call_icon}
                      alt={"."}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </InputGroup.Text>
                  <FormControl
                    placeholder={t("pages.register.mobile-place")}
                    name={"mobile"}
                    onChange={(e) => registerinputChange(e)}
                    value={registerInput.mobile}
                    type={"text"}
                    className={"input-field"}
                  />
                </InputGroup>
                <div className="text-danger">{errors.mobile}</div>
              </div>
              <div className={"form-group"}>
                <div className={"form-title"}>
                  {t("pages.register.password")}
                </div>
                <InputGroup style={inputType}>
                  <InputGroup.Text>
                    <img
                      src={password_icon}
                      alt={"."}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </InputGroup.Text>
                  <FormControl
                    placeholder={t("pages.register.password-place")}
                    name={"password"}
                    onChange={(e) => registerinputChange(e)}
                    value={registerInput.password}
                    type={!passwordState.password ? "password" : "text"}
                    className={"input-field"}
                  />
                  <InputGroup.Text
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPasswordState({
                        ...passwordState,
                        password: !passwordState.password,
                      })
                    }
                  >
                    <i
                      className={
                        !passwordState.password
                          ? "fa fa-eye-slash"
                          : "fa fa-eye"
                      }
                    />
                  </InputGroup.Text>
                </InputGroup>
                <div className="text-danger">{errors.password}</div>
              </div>
              <div className={"form-group"}>
                <div className={"form-title"}>
                  {t("pages.register.c-password")}
                </div>
                <InputGroup style={inputType}>
                  <InputGroup.Text>
                    <img
                      src={password_icon}
                      alt={"."}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </InputGroup.Text>
                  <FormControl
                    placeholder={t("pages.register.c-password-place")}
                    name={"cpassword"}
                    onChange={(e) => registerinputChange(e)}
                    value={registerInput.cpassword}
                    type={!passwordState.cpassword ? "password" : "text"}
                    className={"input-field"}
                  />
                  <InputGroup.Text
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPasswordState({
                        ...passwordState,
                        cpassword: !passwordState.cpassword,
                      })
                    }
                  >
                    <i
                      className={
                        !passwordState.cpassword
                          ? "fa fa-eye-slash"
                          : "fa fa-eye"
                      }
                    />
                  </InputGroup.Text>
                </InputGroup>
                <div className="text-danger">{errors.cpassword}</div>
              </div>
              <div className={"form-group"}>
                <div className={"form-title"}>{t("pages.register.type")}</div>
                <div className={`selection ${props.language}`}>
                  <input
                    type="radio"
                    name="select"
                    id="seller-opt"
                    checked={registerInput.userType === "seller" ? true : false}
                    onChange={(e) => selectType(e)}
                  />
                  <input
                    type="radio"
                    name="select"
                    id="buyer-opt"
                    checked={registerInput.userType === "buyer" ? true : false}
                    onChange={(e) => selectType(e)}
                  />
                  <label htmlFor="seller-opt" className="option seller-opt">
                    <div className="dot"></div>
                    <span>{t("pages.register.seller")}</span>
                  </label>
                  <label htmlFor="buyer-opt" className="option buyer-opt buyer">
                    <div className="dot"></div>
                    <span>{t("pages.register.buyer")}</span>
                  </label>
                </div>
              </div>
              <div className="text-danger">{errors.userType}</div>
              <Button
                className={"mt-4 shadow-btn register-btn"}
                style={primaryBtn}
                onClick={(e) => callRegister(e)}
              >
                {t("pages.register.button")}
              </Button>
            </form>
            <center>
              <Link
                to={"/login"}
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <label className={"alter-login"}>
                  {t("pages.register.logtitle")}
                  <strong> {t("pages.register.login")}</strong>
                </label>
              </Link>
              <br />
              <br />
              <label className={"all-right-reserve"}>
                © 2022 ConfyCar. {t("pages.footer.rights")}
              </label>
            </center>
            <br />
          </div>
        </div>
        <div className={"col p-0"}>
          <img
            src={loginpage_image}
            alt={"."}
            className={"user-select-none"}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
      <FooterPage />
    </>
  );
}
const mapStateToProps = (store) => ({
  theme: store.theme?.primaryTheme,
  language: store?.theme?.language,
});
export default connect(mapStateToProps)(RegisterPage);
