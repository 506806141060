import React, { useEffect, useState } from "react";
import "./PaymenthistoryPage.css";
import { connect } from "react-redux";
import { Container, Table } from "react-bootstrap";
import paypal from "./../../../assets/paypal.png";
import { getPaymentlist } from "../util/paymentlist";
import moment from "moment";
import { useTranslation } from "react-i18next";

function PaymenthistoryPage(props) {
  const [paymentlist, setPaymentlist] = useState([]);
  const [base_url, setBase_url] = useState("");
  const [nodata, setNodata] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.tabkey === "payment") {
      paymentHistory();
    }
    // eslint-disable-next-line
  }, [props.tabkey]);

  const paymentHistory = async () => {
    let getPaymentlistresponse = await getPaymentlist(props?.access);
    if (getPaymentlistresponse.status === 200) {
      let getResponse = await getPaymentlistresponse.json();
      if (getResponse.data.length > 0) {
        setNodata(false);
        setBase_url(getResponse.payment_receipt_base_url)
        const reverce = getResponse.data.reverse()
        setPaymentlist(reverce);
      } else {
        setNodata(true);
      }
    }
  };

  return (
    <div className={"payment"}>
      {nodata ? (
        <div className="nodata">
          <h5>{t("pages.no-data")}</h5>
        </div>
      ) : (
        <Container>
          <div className={"pay-list"}>
            <div className={"pay-list-div"}>
              <div className={"collapsible desktop "}>
                <Table striped borderless size="sm" className={"payment-list"}>
                  <thead>
                    <tr>
                      <th>{t("pages.payment.id")}</th>
                      <th>{t("pages.payment.date")}</th>
                      <th>{t("pages.payment.method")} </th>
                      <th>{t("pages.payment.status")}</th>
                      <th>{t("pages.payment.amount")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentlist.map((item, index) => (
                      <tr key={index.toString()} className={"primaryTr"}>
                        <td>{item?.payment_id}</td>
                        <td>{moment(item?.updated_at).format("DD/MM/YYYY")}</td>
                        <td>
                          <div className="payment-method">
                            {item?.payment_receipt&&
                              <img src={base_url+item?.payment_receipt} alt={"."} />
                            }
                          </div>
                        </td>
                        <td>
                          <div className={"Paid"}>{item?.payment_status==="done"?t('pages.paid'):t('pages.pending')}</div>
                        </td>
                        <td>{item?.final_amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className={"collapsible mobile "}>
                {paymentlist.map((item, index) => (
                  <div key={index.toString()} className="history-list">
                    <Table
                      striped
                      borderless
                      size="sm"
                      className={"payment-list"}
                    >
                      <tbody>
                        <tr className={"primaryTr"}>
                          <td className="secount">{t("pages.payment.id")}</td>
                          <td className="primary">{item?.payment_id}</td>
                        </tr>
                        <tr>
                          <td className="secount">{t("pages.payment.date")}</td>
                          <td className="primary">
                            {moment(item?.updated_at).format("dd/mm/yyyy")}
                          </td>
                        </tr>
                        <tr>
                          <td className="secount">
                            {t("pages.payment.method")}{" "}
                          </td>
                          <td className="primary">
                            <div className="payment-method">
                              <img src={paypal} alt={"."} />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="secount">
                            {t("pages.payment.status")}
                          </td>
                          <td className="primary">
                            <div className={"Paid"}>{item?.payment_status==="done"?t('pages.paid'):t('pages.pending')}</div>
                          </td>
                        </tr>
                        <tr>
                          <td className="secount">
                            {t("pages.payment.amount")}
                          </td>
                          <td className="primary">{item?.final_amount}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  access: state?.login?.access,
});
export default connect(mapStateToProps)(PaymenthistoryPage);
