import React, { useEffect, useState } from "react";
import "./FooterPage.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Fb from "./../../../assets/fb.svg";
import Tw from "./../../../assets/tw.svg";
import In from "./../../../assets/in.svg";
import { getComponyInfo } from "./../../contactus/util/getcompany";
import { useTranslation } from "react-i18next";
import { getSubscribedetatil } from "../../home/util/subscribe";
import { toast } from "react-toastify";

function FooterPage(props) {
  const { t } = useTranslation();

  const [companyinfo, setCompanyinfo] = useState({});

  const emailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

  const [subscribe, setSubscribe] = useState({
    email: "",
  });

  useEffect(() => {
    callGetCompanyinfo();
    // eslint-disable-next-line
  }, []);
  const callGetCompanyinfo = async () => {
    const responceInfo = await getComponyInfo();
    if (responceInfo.status === 200) {
      const resultComponyInfo = await responceInfo.json();
      setCompanyinfo(resultComponyInfo[0]);
      //console.log(resultComponyInfo);
    }
  };

  const subscribeInputchange = (e) => {
    setSubscribe({
      ...subscribe,
      email: e.target.value,
      errorEmail: "",
    });
  };

  const subscribeClick = async () => {
    if (subscribe.email === "") {
      toast.error(t("pages.login.email-warn"));
    } else if (!subscribe.email.match(emailRegex)) {
      toast.error(t("pages.login.email-valid"));
    } else {
      let subscribeDetail = {
        email: subscribe.email,
      };

      let getSubscribedetatilresponse = await getSubscribedetatil(
        subscribeDetail
      );

      if (getSubscribedetatilresponse.status === 201) {
        setSubscribe({
          ...subscribe,
          email: "",
          errorEmail: "",
        });
        toast.success(t('pages.dashboard.subscrib-success'));
      }
    }
  };

  return (
    <div className={"footer"}>
      <div className={"footer-img"}>
        <div className={"container "}>
          <div className={"row col-12"}>
            <div className={"footer-4 col-3 w-50"}>
              <div className={"footer-info bounceIn"}>
                <ul className={"footer-lists "}>
                  <li>
                    <h6>{t("pages.footer.contact")}</h6>
                  </li>
                  <li className={"contact-list"}>{companyinfo.address}</li>
                  <a
                    href={`mailto:${companyinfo.email}`}
                    style={{ textDecoration: "none" }}
                  >
                    <li className={"contact-list"}>{companyinfo.email}</li>
                  </a>
                  <a
                    href={`tel:${companyinfo.mobile}`}
                    style={{ textDecoration: "none" }}
                  >
                    <li className={"contact-list"}>{companyinfo.mobile}</li>
                  </a>
                </ul>
              </div>
            </div>
            <div className={"footer-4 col-4 w-50"}>
              <div className={"footer-info bounceIn"}>
                <ul className={"footer-lists"}>
                  <li>
                    <h6>{t("pages.footer.menu")}</h6>
                  </li>
                  <Link to={"/terms"} style={{ textDecoration: "none" }}>
                    <li className={"contact-list"}>
                      {t("pages.footer.terms")}
                    </li>
                  </Link>
                  <Link to={"/privacy"} style={{ textDecoration: "none" }}>
                    <li className={"contact-list"}>
                      {t("pages.footer.privacy")}
                    </li>
                  </Link>
                  <Link to={"/about"} style={{ textDecoration: "none" }}>
                    <li className={"contact-list"}>
                      {t("pages.footer.about")}
                    </li>
                  </Link>
                  <Link to={"/contact"} style={{ textDecoration: "none" }}>
                    <li className={"contact-list"}>
                      {t("pages.footer.contact")}
                    </li>
                  </Link>
                  
                </ul>
              </div>
            </div>
            <div className={"footer-4 col-4"}>
              <div className={"footer-info bounceIn"}>
                <ul className={"footer-lists"}>
                  <li>
                    <h6 className="subscib">{t("pages.footer.subscrib")}</h6>
                  </li>
                  <li style={{ display: "flex" }} className={"subscirb"}>
                    <div>
                      <input
                        className={"input-field"}
                        placeholder={`${t("pages.footer.input")}`}
                        value={subscribe.email}
                        onChange={(e) => subscribeInputchange(e)}
                      />
                    </div>
                    <button
                      style={props?.theme?.subscribeBtn}
                      onClick={() => subscribeClick()}
                    >
                      {t("pages.footer.button")}
                    </button>
                  </li>
                  <li className={"social-list"}>
                    <div className={"social-div"}>
                      <img src={Fb} alt={"."} className={"social-img"} />
                    </div>
                    <div className={"social-div"}>
                      <img src={Tw} alt={"."} className={"social-img"} />
                    </div>
                    <div className={"social-div"}>
                      <img src={In} alt={"."} className={"social-img"} />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"rights-divs"}>
        <label>© 2022 ConfyCar. {t("pages.footer.rights")}</label>
      </div>
    </div>
  );
}
const mapStateToProps = (store) => ({
  theme: store.theme.primaryTheme,
});
export default connect(mapStateToProps)(FooterPage);
