import "./MyaccountPage.css";
import user from "./../../../../assets/profile_pic.svg";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getProfiledetail } from "../../../profile/util/profileUpdate";
import { toast } from "react-toastify";
import { uploadFile } from "../../addspecialist/util/uploadFile";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchAdminLogindata } from "../../login/redux/actions";
import LoaderPage from "../../loader/components/LoaderPage";

function MyaccountPage(props) {
  const emailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  // const mobileNumRegex = /^\d{10}$/;
  const { t } = useTranslation();
  const [loadState, setLoadState] = useState(true)
  const navigation = useNavigate();
  const [accountinfo, setAccountinfo] = useState({
    first_name: "",
    email: "",
    mobile: "",
    profile_pic_name: "",
  });
  const is_sp = props?.admin_data[0].user_type==="sp"?true:false;

  const [errors, setErrors] = useState({
    first_name: "",
    email: "",
    mobile: "",
  });

  useEffect(() => {
    //console.log("admin data", props.admin_data);
    setAccountinfo({
      ...accountinfo,
      first_name: props?.admin_data[0]?.first_name,
      email: props?.admin_data[0]?.email,
      mobile: props?.admin_data[0]?.mobile,
      profile_pic_name: props?.admin_data[0]?.profile_pic,
    });
    setTimeout(()=>setLoadState(false), 500)
    // eslint-disable-next-line
  }, []);

  const profileInputchange = (e) => {
    setAccountinfo({
      ...accountinfo,
      [e.target.name]: e.target.value,
    });

    switch (e.target.name) {
      case "first_name":
        setErrors({
          ...errors,
          first_name: "",
        });
        break;

      case "email":
        setErrors({
          ...errors,
          email: "",
        });
        break;

      case "mobile":
        setErrors({
          ...errors,
          mobile: "",
        });
        break;

      default:
        break;
    }
  };

  const validation = () => {
    let checkValue = true;

    if (accountinfo.first_name === "") {
      errors.first_name = is_sp?t('pages.specialist.profile.fullname-warn'):"Enter your full name";
      checkValue = false;
    }

    if (!accountinfo.email.match(emailRegex)) {
      errors.email = is_sp?t('pages.specialist.profile.email-valid'):"Enter your valid email";
      checkValue = false;
    }

    if (accountinfo.email === "") {
      errors.email = is_sp?t('pages.specialist.profile.email-warn'):"Enter your email";
      checkValue = false;
    }

    // if (!accountinfo.mobile.match(mobileNumRegex)) {
    //   errors.mobile = "Enter your 10 digit valid mobile number";
    //   checkValue = false;
    // }

    if (accountinfo.mobile === "") {
      errors.mobile = is_sp?t('pages.specialist.profile.mobile-warn'):"Enter your mobile number";
      checkValue = false;
    }

    setErrors({
      ...errors,
      first_name: errors.first_name,
      email: errors.email,
      mobile: errors.mobile,
    });

    if (checkValue === true) {
      return true;
    } else {
      return false;
    }
  };

  const profilepicChange = async (e, type) => {
    if(e.target.files.length){
      const file_type = e.target.files[0].name.split(".").pop();
      if(       
          file_type==="png"||
          file_type==="jpg"||
          file_type==="jpeg"||
          file_type==="jfif"||
          file_type==="svg"||
          file_type==="apng"
      ){
        const uploadFileResponse = await uploadFile(
          props.access,
          e.target.files[0],
          type
        );
        if (uploadFileResponse.status === 201) {
          let getData = await uploadFileResponse.json();
         // console.log("profile pic", getData);
          setAccountinfo({
            ...accountinfo,
            profile_pic_name: getData?.data[0]?.name,
          });
        }
      }else{
        //console.log(file_type);
        toast.info(is_sp?props.language==="Sp"?"tipo de imagen inválido":"invalid image type":"invalid image type")
      }
    }
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    if (validation()) {
      let profileDetail = {
        fullname: accountinfo.first_name,
        email: accountinfo.email,
        mobile: accountinfo.mobile,
        profile_pic: accountinfo.profile_pic_name,
      };

      let getProfiledetailresponse = await getProfiledetail(
        profileDetail,
        props.access
      );

      if (getProfiledetailresponse.status === 200) {
        let getUserdata = await getProfiledetailresponse.json();
       // console.log("Response my account page", getUserdata);
        props.fetchAdminLogindata(props.access,getUserdata,props.profile_url)
        setAccountinfo({
          ...accountinfo,
          first_name: getUserdata[0]?.first_name,
          email: getUserdata[0]?.email,
          mobile: getUserdata[0]?.mobile,
        });

        toast.success(is_sp?t('pages.profile.profile-update'):"Your profile updated successfully");
      }
    }
  };
  if(loadState) 
    return <LoaderPage/>

  return (
    <div className="myaccount-contents">
      <div class="admin content-page">
        <div class="admin content">
          <div class="admin container-fluid">
            <div class="admin row">
              <div class="admin col-12">
                <div class="admin card mt-2">
                  <div class="admin card-body">
                    <form>
                      <div class="admin row">
                        <div class="admin d-flex mb-3">
                          <img
                            class="admin flex-shrink-0 me-2 rounded-circle avatar-md"
                            alt="64x64"
                            src={
                              accountinfo.profile_pic_name === ""
                                ? user
                                : props.profile_url+accountinfo?.profile_pic_name
                            }
                          />

                          {/* <div className="admin avatar-md me-2 border rounded-circle upload-image-title">
                            <i className="fa fa-plus" />
                          </div> */}

                          <form>
                            <div class="admin flex-grow-1">
                              <div class="admin file file--upload">
                                <label for="admin_profile">
                                  {is_sp?
                                    accountinfo.profile_pic_name===""?
                                      t('pages.specialist.profile.add-picture'):
                                      t('pages.specialist.profile.change-picture')
                                    :
                                    accountinfo.profile_pic_name===""?
                                      "Add Picture":
                                      "Change Picture"
                                  }
                                  </label>
                                <input
                                  name="file"
                                  id="admin_profile"
                                  type="file"
                                  onChange={(e) =>
                                    profilepicChange(e, "profile")
                                  }
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label for="first_name" class="admin form-label">
                            {is_sp?t('pages.specialist.profile.fullname'):"Full Name"}
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            class="admin form-control"
                            placeholder={is_sp?t('pages.specialist.profile.fullname-place'):"Enter Full Name"}
                            value={accountinfo.first_name}
                            onChange={profileInputchange}
                          />
                          <div class="text-danger">{errors.first_name}</div>
                        </div>
                        <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label for="email" class="admin form-label">
                            {is_sp?t('pages.specialist.profile.email'):"Email"}
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            class="admin form-control"
                            placeholder={is_sp?t('pages.specialist.profile.email-place'):"Enter email address"}
                            value={accountinfo.email}
                            onChange={profileInputchange}
                          />
                          <div class="text-danger">{errors.email}</div>
                        </div>
                        <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label for="mobile" class="admin form-label">
                            {is_sp?t('pages.specialist.profile.mobile'):"Mobile Number"}
                          </label>
                          <input
                            id="mobile"
                            name="mobile"
                            class="admin form-control"
                            placeholder={is_sp?t('pages.specialist.profile.mobile-place'):"Enter your mobile no"}
                            value={accountinfo.mobile}
                            onChange={profileInputchange}
                          />
                          <div class="text-danger">{errors.mobile}</div>
                        </div>

                        <div class="admin mt-3 col-sm-6 text-left col-md-6 col-lg-6 col-xl-6">
                          <div className="flex-row">
                            <div className="col-6 px-2">
                              <button
                                type="submit"
                                class="admin btn btn-warning mt-1 waves-effect waves-light"
                                onClick={(e) => updateProfile(e)}
                              >
                                {is_sp?t('pages.specialist.profile.save'):"Save"}
                              </button>
                            </div>
                            <div className="col-6 px-2">
                              <button
                                class="admin btn btn-secondary mt-1 waves-effect waves-light"
                                onClick={(e) => navigation(is_sp?"/specialist/change-password":"/bc-admin/change-password")}
                              >
                                {is_sp?t('pages.specialist.profile.change-password'):"Change Password"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  admin_data: state?.adminlogin?.admin_data,
  access: state?.adminlogin?.access,
  language:state?.theme?.language,
  profile_url:state.adminlogin.profile_pic_base_url,
});

export default connect(mapStateToProps,{fetchAdminLogindata})(MyaccountPage);
