import { CHANGE_SELECT_LANGUAGE } from "./actionTypes";
const initialState = {
  primaryTheme: {
    primaryBtn: {
      background: "#FCBB52",
      borderRadius: "7px",
      color: "#152232",
      fontSize: 14,
      width: "100%",
      height: "39px",
      fontWeight: "500",
      padding: "6px 20px",
    },
    headerBtn: {
      background: "#FCBB52",
      borderRadius: "7px",
      color: "#152232",
      fontSize: 14,
      fontWeight: "500",
      padding: "6px 20px",
    },
    accountBtn: {
      color: "#FCBB52",
    },
    inputForm: {
      height: "43px",
      borderRadius: "7px",
      color: "#7B818D",
      fontWeight: "400",
    },
    subscribeBtn: {
      background: "#FCBB52",
      borderRadius: "7px",
      color: "#152232",
      fontSize: 12,
      fontWeight: "500",
      padding: "6px 20px",
    },
    secoundryBtn: {
      background: "#282D3B",
      borderRadius: "7px",
      color: "#FFFFFF",
      fontSize: 13,
      fontWeight: "500",
      width: "100%",
    },
  },
    language:"Sp"
};

export const theme = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SELECT_LANGUAGE:
      return {
        ...state,
        language:action.payload
      }
    default:
      return state;
  }
};
