import React, { useEffect, useState } from "react";
import "./DashboardPage.css";
import "../../../admin/dashboard/components/DashboardPage.css";
import { Tab, Tabs, Table } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import user from "../../../../assets/profile_pic.svg";
import "../../../admin/request/components/RequestPage.css";
import { getDashboardDetail } from "../util/dashboard";
import { connect } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { getRequeststatusdetail } from "../util/requestAccept";
import { useTranslation } from "react-i18next";
import { fetchRequestcount } from "../../../admin/dashboard/redux/actions";
import RequestrejectPage from "../../../admin/modal/components/RequestrejectPage";
import { changeSearchInput } from "../../../admin/headernav/redux/actions";
import LoaderPage from "../../../admin/loader/components/LoaderPage";

function DashboardPage(props) {
  const [dashboardData, setDashboarddata] = useState();
  const [newRequest, setNewrequest] = useState([]);
  const [newRequeststatus, setNewrequeststatus] = useState("");
  const [showRequestReject, setShowRequestReject] = useState(false);
  const [reason, setReason] = useState('');
  const [error, seterror] = useState('');
  const { t } = useTranslation();
  const [loadState, setLoadState] = useState(true)
  useEffect(() => {
    callDashboardDetail();
    // eslint-disable-next-line
  }, []);

  const callDashboardDetail = async () => {
    const getDashboardDetailresponse = await getDashboardDetail(props.access);
    if (getDashboardDetailresponse?.status === 200) {
      let getResponse = await getDashboardDetailresponse.json();
     // console.log("res", getResponse);
      setDashboarddata(getResponse);
      props.fetchRequestcount(getResponse.pending_orders);
      if (getResponse?.newly_assign_orders?.length > 0) {
        setNewrequest(getResponse?.newly_assign_orders[0]);
      } else {
        setNewrequest([]);
      }
    }
    setTimeout(()=>{
      setLoadState(false)
    }, 1000)
  };

  const requestStatuschange = async (status, id) => {
    let statusDetail = {
      order_id: id.toString(),
      order_status: status,
      reject_reason:null
    };

    let getRequeststatusdetailresponse = await getRequeststatusdetail(
      props.access,
      statusDetail
    );

    if (getRequeststatusdetailresponse.status === 200) {
      //console.log("req status", await getRequeststatusdetailresponse.json());
      if (status === "reject") {
        setNewrequeststatus(status);
        toast.success(t("pages.specialist.dashboard.req-reject"));
      } else {
        setNewrequeststatus(status);
        toast.success(t("pages.specialist.dashboard.req-accept"));
      }
    }
  };
  const submitRejectRequest = async (e) =>{
    e.preventDefault();
    if(reason===""){
      seterror(t("pages.specialist.dashboard.reject-pop.input"))
    }else{
      const status = "reject"
      let statusDetail = {
        order_id: newRequest?.id.toString(),
        order_status: status,
        reject_reason:reason
      };
  
      let getRequeststatusdetailresponse = await getRequeststatusdetail(
        props.access,
        statusDetail
      );
  
      if (getRequeststatusdetailresponse.status === 200) {
       // console.log("req status", await getRequeststatusdetailresponse.json());
        if (status === "reject") {
          setNewrequeststatus(status);
          toast.success(t("pages.specialist.dashboard.req-reject"));
        } else {
          setNewrequeststatus(status);
          toast.success(t("pages.specialist.dashboard.req-accept"));
        }
        setShowRequestReject(false)
      }
    }
  }
  if(loadState)
    return <LoaderPage/>

  return (
    <div className="admin-content specialist-dashboards">
      <div class="admin content-page">
        <div class="admin content">
          <div class="admin container-fluid">
            <div class="admin row">
              <div class="admin col-xl-4 col-md-6">
                <NavLink
                  to={"/specialist/request"}
                  className={"text-decoration-none"}
                  onClick={()=>props.changeSearchInput("")}
                >
                  <div class="admin card">
                    <div class="admin card-body">
                      <h4 class="admin header-title mt-0 mb-4">
                        {t("pages.specialist.dashboard.total-request-assign")}
                      </h4>

                      <div class="admin widget-chart-1">
                        <div
                          class="admin widget-chart-box-1 float-start"
                          dir="ltr"
                        >
                          <CircularProgressbar
                            className="progress-circles"
                            value={58}
                            text={`${58}%`}
                            styles={buildStyles({
                              rotation: 0.5,
                              strokeLinecap: "butt",
                              textSize: "20px",
                              pathTransitionDuration: 0.5,
                              pathColor: `#f05050`,
                              textColor: "#f9b9b9",
                              trailColor: "#f9b9b9",
                              backgroundColor: "#3e98c7",
                            })}
                          />
                        </div>

                        <div class="admin widget-detail-1 text-end">
                          <h2 class="admin fw-normal pt-2 mb-1">
                            {dashboardData?.total_orders}
                          </h2>
                          <p class="admin text-muted mb-1 fw-5" hidden>
                            {t("pages.specialist.dashboard.revenue")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div class="admin col-xl-4 col-md-6">
                <NavLink
                  to={"/specialist/request"}
                  className={"text-decoration-none"}
                  onClick={()=>props.changeSearchInput("Completed")}
                >
                  <div class="admin card">
                    <div class="admin card-body">
                      <h4 class="admin header-title mt-0 mb-4">
                        {t("pages.specialist.dashboard.complete-request")}
                      </h4>

                      <div class="admin widget-chart-1">
                        <div
                          class="admin widget-chart-box-1 float-start"
                          dir="ltr"
                        >
                          <CircularProgressbar
                            className="progress-circles"
                            value={40}
                            text={`${40}`}
                            styles={buildStyles({
                              rotation: 0.5,
                              strokeLinecap: "butt",
                              textSize: "20px",
                              pathTransitionDuration: 0.5,
                              pathColor: `#ffbd4a`,
                              textColor: "#ffbd4a",
                              trailColor: "#ffe6ba",
                              backgroundColor: "#ffe6ba",
                            })}
                          />
                        </div>
                        <div class="admin widget-detail-1 text-end">
                          <h2 class="admin fw-normal pt-2 mb-1">
                            {dashboardData?.completed_orders}
                          </h2>
                          <p class="admin text-muted mb-1 fw-5" hidden>
                            {t("pages.specialist.dashboard.revenue")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div class="admin col-xl-4 col-md-6">
                <NavLink
                  to={"/specialist/request"}
                  onClick={()=>props.changeSearchInput("Assigned")}
                  className={"text-decoration-none"}
                >
                  <div class="admin card">
                    <div class="admin card-body">
                      <h4 class="admin header-title mt-0 mb-3">
                        {t("pages.specialist.dashboard.pending-request")}
                      </h4>

                      <div class="admin widget-box-2">
                        <div class="admin widget-detail-2 text-end">
                          <span class="admin badge bg-success rounded-pill float-start mt-3">
                            30% <i class="admin mdi mdi-trending-up"></i>{" "}
                          </span>
                          <h2 class="admin fw-normal mb-2 pb-2">
                            {dashboardData?.pending_orders}
                          </h2>
                          <p class="admin text-muted mb-3 fw-5" hidden>
                            {t("pages.specialist.dashboard.revenue")}
                          </p>
                        </div>
                        <div class="admin progress progress-bar-alt-success progress-sm mt-4">
                          <div
                            class="admin progress-bar bg-success"
                            aria-valuenow="30"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "30%" }}
                          >
                            <span class="admin visually-hidden">
                              30% Complete
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            {dashboardData?.newly_assign_orders?.length > 0 && (
              <div class="admin row">
                <div class="admin col-6">
                  <h4 className="row-title">
                    {t("pages.specialist.dashboard.recently-assign-requst")}
                  </h4>
                </div>
                <div class="admin col-6 text-end text-decoration-underline">
                  {" "}
                  <NavLink
                    to="/specialist/request"
                    className="admin btn-link text-secondary fw-5"
                  >
                    {t("pages.specialist.dashboard.view-all-request")}
                  </NavLink>
                </div>

                <div className={"request specialist"}>
                  <div className={"req-list desktop specialist current-work"}>
                    <div className={"req-list-div specialist"}>
                      <div>
                        <div className={"collapsible"}>
                          <div>
                            <Table
                              size="sm"
                              borderless
                              className={"request-list"}
                            >
                              <thead>
                                <tr>
                                  <th>
                                    {t("pages.specialist.dashboard.request-id")}
                                  </th>
                                  {newRequest?.trn_id && (
                                    <th>
                                      {t(
                                        "pages.specialist.dashboard.transaction-id"
                                      )}
                                    </th>
                                  )}
                                  <th>
                                    {t(
                                      "pages.specialist.dashboard.payment-amount"
                                    )}
                                  </th>
                                  <th>
                                    {t(
                                      "pages.specialist.dashboard.payment-status"
                                    )}
                                  </th>
                                  <th
                                    style={{
                                      verticalAlign: "middle",
                                      width: "100%",
                                    }}
                                    align={"right"}
                                    colspan={4}
                                    rowspan={2}
                                  >
                                    <div className={"table-status"}>
                                      <div className={"progress-section"}>
                                        <div
                                          className={
                                            "progress-btn processing accept-btn"
                                          }
                                          onClick={() =>
                                            requestStatuschange(
                                              "accept",
                                              newRequest?.id
                                            )
                                          }
                                          style={{
                                            display:
                                              newRequeststatus === "reject"
                                                ? "none"
                                                : "initial",
                                          }}
                                        >
                                          {newRequeststatus === "accept"
                                            ? t(
                                                "pages.specialist.dashboard.accepted"
                                              )
                                            : t(
                                                "pages.specialist.dashboard.accept"
                                              )}
                                        </div>
                                      </div>
                                      <div
                                        className="req-reject-btn"
                                        onClick={() =>setShowRequestReject(true)}
                                        style={{
                                          display:
                                            newRequeststatus === "accept"
                                              ? "none"
                                              : "initial",
                                        }}
                                      >
                                        {newRequeststatus === "reject"
                                          ? t(
                                              "pages.specialist.dashboard.rejected"
                                            )
                                          : t(
                                              "pages.specialist.dashboard.reject"
                                            )}
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr className={"primaryTr"}>
                                  <td>{newRequest?.order_id}</td>
                                  <td>{newRequest?.final_amount}</td>
                                  <td>{newRequest?.trn_id}</td>
                                </tr>
                              </thead>
                              <tbody></tbody>
                            </Table>
                          </div>
                        </div>
                        <div className={"collapsible-bottom "}>
                          <Table
                            size="sm"
                            borderless
                            className={"request-list"}
                          >
                            <thead>
                              <tr>
                                <th>
                                  {t("pages.specialist.dashboard.car-brand")}
                                </th>
                                <th>
                                  {t("pages.specialist.dashboard.car-type")}
                                </th>
                                <th>
                                  {t("pages.specialist.dashboard.car-colour")}
                                </th>
                                <th>
                                  {t("pages.specialist.dashboard.car-number")}
                                </th>
                                <th>{t("pages.specialist.dashboard.type")}</th>
                              </tr>
                              <tr className={"primaryTr"}>
                                <td>{newRequest?.vehicle_brand}</td>
                                <td>{newRequest?.vehicle_type}</td>
                                <td>{newRequest?.vehicle_color}</td>
                                <td>{newRequest?.vehicle_number_plate}</td>
                                <td>
                                  {newRequest?.o_type === "Seller"
                                    ? t("pages.register.seller")
                                    : t("pages.register.buyer")}
                                </td>
                              </tr>
                            </thead>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="req-list mobile">
                    <div class="req-list-div ">
                      <div className="collapsible">
                        <div className="row-div">
                          <div className="col">
                            <label class="secound flex-1">
                              {t("pages.specialist.dashboard.request-id")}
                            </label>
                            <label class="primary flex-1">
                              {newRequest?.order_id}
                            </label>
                          </div>
                          <div className="col">
                            <div class="table-status">
                              <div
                                class="progress-btn processing accept-btn"
                                onClick={() =>
                                  requestStatuschange("accept", newRequest?.id)
                                }
                                style={{
                                  display:
                                    newRequeststatus === "reject"
                                      ? "none"
                                      : "initial",
                                }}
                              >
                                {" "}
                                {newRequeststatus === "accept"
                                  ? t("pages.specialist.dashboard.accepted")
                                  : t("pages.specialist.dashboard.accept")}
                              </div>
                              <div
                                class="req-reject-btn"
                                onClick={() =>
                                  setShowRequestReject(true)
                                }
                                style={{
                                  display:
                                    newRequeststatus === "accept"
                                      ? "none"
                                      : "initial",
                                }}
                              >
                                {newRequeststatus === "reject"
                                  ? t("pages.specialist.dashboard.rejected")
                                  : t("pages.specialist.dashboard.reject")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {newRequest?.trn_id && (
                            <div className="col-12">
                              <label class="secound flex-1">
                                {t("pages.specialist.dashboard.transaction-id")}
                              </label>
                              <label class="primary flex-1">
                                {newRequest?.trn_id}
                              </label>
                            </div>
                          )}
                          <div className="col-12">
                            <label class="secound flex-1">
                              {t("pages.specialist.dashboard.payment-amount")}
                            </label>
                            <label class="primary flex-1">
                              {newRequest?.final_amount}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="collapsible-bottom ">
                        <div class="request-list">
                          <div class="row">
                            <div class="col-6">
                              <label class="secound flex-1">
                                {t("pages.specialist.dashboard.car-brand")}
                              </label>
                              <label class="primary flex-1">
                                {newRequest?.vehicle_brand}
                              </label>
                            </div>
                            <div class="col-6">
                              <label class="secound flex-1">
                                {t("pages.specialist.dashboard.car-type")}
                              </label>
                              <label class="primary flex-1">
                                {newRequest?.vehicle_type}
                              </label>
                            </div>
                            <div class="col-6">
                              <label class="secound flex-1">
                                {t("pages.specialist.dashboard.car-colour")}
                              </label>
                              <label class="primary flex-1">
                                {newRequest?.vehicle_color}
                              </label>
                            </div>
                            <div class="col-6">
                              <label class="secound flex-1">
                                {t("pages.specialist.dashboard.car-number")}
                              </label>
                              <label class="primary flex-1">
                                {newRequest?.vehicle_number_plate}
                              </label>
                            </div>
                            <div class="col-6">
                              <label class="secound flex-1">
                                {t("pages.specialist.dashboard.type")}
                              </label>
                              <label class="primary flex-1">
                                {newRequest?.o_type === "Seller"
                                  ? t("pages.register.seller")
                                  : t("pages.register.buyer")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div class="admin row">
              <div class="admin col-xl-12">
                <div class="admin card">
                  <div class="admin card-body">
                    <h4 class="admin header-title mt-0 mb-3">
                      {t("pages.specialist.dashboard.request-list")}
                    </h4>

                    <div class="admin table-responsive">
                      <table class="admin table table-hover mb-0">
                        <thead>
                          <tr>
                            <th>
                              {t("pages.specialist.dashboard.request-id")}
                            </th>
                            <th>
                              {t("pages.specialist.dashboard.request-type")}
                            </th>
                            <th>{t("pages.specialist.dashboard.date")}</th>
                            <th>
                              {t("pages.specialist.dashboard.amount-paid")}
                            </th>
                            <th>{t("pages.specialist.dashboard.status")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dashboardData?.order_list.length === 0 ? (
                            <tr>
                              <td colSpan={5} align={"center"}>
                                {t('pages.no-data')}
                              </td>
                            </tr>
                          ) : (
                            dashboardData?.order_list?.map(
                              (item, index) =>
                                index < 6 && (
                                  <tr>
                                    <td>{item?.order_id}</td>
                                    <td>
                                      {item?.o_type.charAt(0).toUpperCase() +
                                        item?.o_type.slice(1)}
                                    </td>
                                    <td>
                                      {moment(item.date_time).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>{item.final_amount}</td>
                                    <td>
                                      <span class="admin badge bg-success">
                                        {item?.current_status
                                          .charAt(0)
                                          .toUpperCase() +
                                          item?.current_status.slice(1)}
                                      </span>
                                    </td>
                                  </tr>
                                )
                            )
                          )}
                        </tbody>
                        
                      </table>
                    </div>
                    <div className="text-decoration-none text-right">
                      <Link to={"/specialist/request"}>
                        <span style={{textAlign:"right"}}>{props.language==="Sp"?"Ver todo":"View all"}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* Hidden Feedback */}
              <div class="admin col-xl-4" hidden>
                <div class="admin card">
                  <div class="admin card-body">
                    <Tabs
                      id={`controlled-tab-example`}
                      className="admin nav nav-tabs total-cnt nav-bordered"
                      variant={"pills tabs-inner-div"}
                    >
                      <Tab
                        className="admin tab-pane  inbox-widget"
                        tabClassName="hi"
                        eventKey="buyers"
                        title={
                          <div data-bs-toggle="tab" class="admin header-title">
                            {t("pages.specialist.dashboard.rating")}
                          </div>
                        }
                      >
                        <div class="admin inbox-item">
                          {dashboardData?.ratings?.length > 0 ? (
                            dashboardData?.ratings?.map((item) => (
                              <a href>
                                <div class="admin inbox-item-img">
                                  <img
                                    src={user}
                                    class="admin rounded-circle"
                                    alt=""
                                  />
                                </div>
                                <h5 class="admin inbox-item-author mt-0 mb-1">
                                  Shree
                                </h5>
                                <p class="admin inbox-item-text">
                                  Platea lorem, habitasse ac tincidunt mi. ipsum
                                </p>
                              </a>
                            ))
                          ) : (
                            <center>
                              <p class="admin inbox-item-text">
                                {t("pages.no-data")}
                              </p>
                            </center>
                          )}
                        </div>
                      </Tab>
                      <Tab
                        className="admin tab-pane  inbox-widget"
                        tabClassName="hi"
                        eventKey="sellers"
                        title={
                          <div data-bs-toggle="tab" class="admin header-title">
                            {t("pages.specialist.dashboard.feedback")}
                          </div>
                        }
                      >
                        {dashboardData?.feedback?.map((item) => (
                          <div class="admin inbox-item">
                            <a href>
                              <h5 class="admin inbox-item-author mt-0 mb-1">
                                {item?.feedback}
                              </h5>
                            </a>
                          </div>
                        ))}
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
              {/* Hidden Close */}
            </div>
          </div>
        </div>
      </div>
      <RequestrejectPage
        showRequestReject={showRequestReject}
        setShowRequestReject={setShowRequestReject}
      >
        <form onSubmit={(e)=>submitRejectRequest(e)} className={"reject-form"}>
          <div className="center p-2">
            <i className="fa fa-exclamation-triangle" />
          </div>

          <div className=" admin col-12 p-2  center">
            <div>
              <label className=" admin center form-label">{t("pages.specialist.dashboard.reject-pop.title-1")}</label>
            </div>
            <label htmlFor="reason" className=" admin pb-3 form-label">{t("pages.specialist.dashboard.reject-pop.title")}</label>
            <textarea rows={4} onChange={(e)=>{
              setReason(e.target.value)
              seterror("")
            }} value={reason} type="text" id="reason" className=" admin form-control" placeholder={t("pages.specialist.dashboard.reject-pop.input")} name="reason" />
            <div className="text-danger">{error}</div>
          </div>
          <div className="admin float-right p-2">
            <button type="submit" className="admin me-1 btn btn-warning m-0 width-xl waves-effect waves-light">{t("pages.specialist.dashboard.reject-pop.submit")}</button>
            <button type="button" onClick={()=>setShowRequestReject(false)} className="admin btn btn-secondary m-0 width-xl waves-effect waves-light">{t("pages.specialist.dashboard.reject-pop.cancel")}</button>
          </div>
        </form>
      </RequestrejectPage>
    </div>
  );
}

const mapStateToProps = (state) => ({
  language:state?.theme?.language,
  access: state?.adminlogin?.access,
});

export default connect(mapStateToProps, { fetchRequestcount, changeSearchInput })(DashboardPage);
