import React, { useEffect, useState } from "react";
import "./RequestPage.css";
import { getRequestList } from "../util/getRequest";
import { connect } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoaderPage from "../../loader/components/LoaderPage";

function Adminrequests(props) {
  const [requestlist, setRequestlist] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loadState, setLoadState] = useState(true)

  let type = props?.admin_data[0]?.user_type;
  const is_Sp = type === "sp" ? true : false;
  useEffect(() => {
    getOrderList();
    // eslint-disable-next-line
  }, []);

  const getOrderList = async () => {
    let getRequestListresponse = await getRequestList(props.access, type);

    if (getRequestListresponse?.status === 200) {
      let getResponse = await getRequestListresponse.json();
      if (type === "sp") {
        setRequestlist(getResponse);
      } else {
        getResponse = Object.values(getResponse);
        console.log(getResponse);
        setRequestlist(getResponse);
      }
    }
    setTimeout(()=>setLoadState(false), 100)
  };

  const viewDetail = (id) => {
    navigate(`${id}`);
  };
  if(loadState) 
  return <LoaderPage/>

  return (
    <div className="request-contents">
      <div class=" admin content-page">
        <div class=" admin content">
          <div class=" admin container-fluid">
            <div class=" admin row">
              <div class=" admin col-12">
                <div class=" admin card mt-2">
                  <div class=" admin card-body">
                    <div className="row col-12 mb-2">
                      <div className="col-12 pt-2">
                        <h4 class=" admin mt-0 header-title">
                          {is_Sp
                            ? t("pages.specialist.req_list.title")
                            : "Request List"}
                        </h4>
                      </div>
                    </div>
                    <div class=" admin table-responsive">
                      <table class=" admin table table-hover mb-0">
                        <thead>
                          <tr>
                            <th>
                              {is_Sp
                                ? t("pages.specialist.req_list.id")
                                : "Request id"}
                            </th>
                            <th>
                              {is_Sp
                                ? t("pages.specialist.req_list.cust-name")
                                : "Customer name"}
                            </th>
                            <th>
                              {is_Sp
                                ? t("pages.specialist.req_list.type")
                                : "Type"}
                            </th>
                            <th>
                              {is_Sp
                                ? t("pages.specialist.req_list.mobile")
                                : "Mobile Number"}
                            </th>
                            <th>
                              {is_Sp
                                ? t("pages.specialist.req_list.status")
                                : "Status"}
                            </th>
                            <th>
                              {is_Sp
                                ? t("pages.specialist.req_list.date")
                                : "Date"}
                            </th>
                            <th>
                              {is_Sp
                                ? t("pages.specialist.req_list.paid")
                                : "Amount paid"}
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {requestlist.length === 0 ? (
                            <tr>
                              <td align="center" colSpan={8}>
                                {t("pages.no-data")}
                              </td>
                            </tr>
                          ) : requestlist?.filter((e) => {
                              const data =
                                e?.user_details[0]?.first_name
                                  ?.toLowerCase()
                                  ?.includes(
                                    props?.searchInput?.toLowerCase()
                                  ) ||
                                e?.user_details[0]?.mobile
                                  ?.toLowerCase()
                                  ?.includes(props?.searchInput?.toLowerCase())||
                                e?.current_status
                                  ?.toLowerCase()
                                  ?.includes(props?.searchInput?.toLowerCase())||
                                e?.order_info?.current_status
                                  ?.toLowerCase()
                                  ?.includes(props?.searchInput?.toLowerCase())||
                                e?.sp_status
                                  ?.toLowerCase()
                                  ?.includes(props?.searchInput?.toLowerCase())
                            return data;
                            }).length ? (
                            requestlist
                              .filter((e) => {
                                const data =
                                  e?.user_details[0]?.first_name
                                    ?.toLowerCase()
                                    ?.includes(
                                      props?.searchInput?.toLowerCase()
                                    ) ||
                                  e?.user_details[0]?.mobile
                                    ?.toLowerCase()
                                    ?.includes(
                                      props?.searchInput?.toLowerCase()
                                    )||
                                  e?.current_status
                                    ?.toLowerCase()
                                    ?.includes(props?.searchInput?.toLowerCase())||
                                  e?.order_info?.current_status
                                    ?.toLowerCase()
                                    ?.includes(props?.searchInput?.toLowerCase())||
                                  e?.sp_status
                                    ?.toLowerCase()
                                    ?.includes(props?.searchInput?.toLowerCase())
                                return data;
                              })
                              ?.map((item) => (
                                <tr>
                                  <td>
                                    {type === "sp"
                                      ? item?.order_info?.order_id
                                      : item?.order_id}
                                  </td>
                                  <td>{item.user_details[0]?.first_name}</td>
                                  <td>
                                    {type === "sp"
                                      ? item.o_type === "sp"
                                        ? "Specialist"
                                        : item?.order_info?.o_type
                                            ?.charAt(0)
                                            ?.toUpperCase() +
                                          item?.order_info?.o_type?.slice(1)
                                      : item.o_type === "sp"
                                      ? "Specialist"
                                      : item?.o_type?.charAt(0)?.toUpperCase() +
                                        item?.o_type?.slice(1)}
                                  </td>
                                  <td>{item?.user_details[0]?.mobile}</td>
                                  <td>
                                    <span
                                      class={
                                        item.sp_status==="reject"?
                                        "admin badge reject"
                                      :
                                        type === "sp"
                                          ? " admin badge " + item?.order_info?.current_status 
                                          : " admin badge " + item?.current_status
                                      }
                                    >
                                      {item.sp_status==="reject"?
                                        "Reject"
                                      :
                                        type === "sp"
                                          ? item?.order_info?.current_status
                                              ?.charAt(0)
                                              ?.toUpperCase() +
                                            item?.order_info?.current_status?.slice(
                                              1
                                            )
                                          : item?.current_status
                                              ?.charAt(0)
                                              ?.toUpperCase() +
                                            item?.current_status?.slice(1)
                                      }
                                    </span>
                                  </td>
                                  <td>
                                    {
                                    type === "sp"?
                                      moment(item?.order_info?.date_time).format("DD/MM/YYYY"):
                                      moment(item?.date_time).format("DD/MM/YYYY")
                                    }
                                  </td>
                                  <td>
                                    {type === "sp"
                                      ? item?.order_info?.final_amount
                                      : item?.final_amount}
                                  </td>
                                  <td class=" admin text-end">
                                    <button
                                      type="button"
                                      class=" ripple admin btn btn-link waves-effect"
                                      onClick={() =>
                                        viewDetail(
                                          type === "sp"
                                            ? item?.order_info?.id
                                            : item?.id
                                        )
                                      }
                                    >
                                      {is_Sp
                                        ? t("pages.specialist.req_list.view")
                                        : "View"}
                                    </button>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td align={"center"} colSpan={7}>
                                {t("pages.no-data")}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  access: state?.adminlogin?.access,
  admin_data: state?.adminlogin?.admin_data,
  searchInput: state?.searchInput?.search,
});

export default connect(mapStateToProps)(Adminrequests);
