import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getLogindetails } from "../../../login/util/login";
import { getUserdetail } from "../../../profile/util/getUserdetail";
import { fetchAdminLogindata } from "../redux/actions";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { fetchChangeLanguage } from "../../../header/redux/actions";

function LoginPage(props) {
  const [loginInput, setloginInput] = useState({ email: "", password: "" });
  const [passwordState, setPasswordState] = useState(false);
  let getLastnamefromurl = window.location.pathname.split("/").pop();
  const { t } = useTranslation();
  const emailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    changeChange(props.language);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (props.access !== "" && props.admin_data[0].user_type === "admin") {
      navigate("/bc-admin/dashboard");
    } else if (
      props.access !== "" &&
      props?.admin_data[0]?.user_type === "sp"
    ) {
      navigate("/specialist/dashboard");
    }
    // eslint-disable-next-line
  }, [props.access]);
  const changeChange = (e) => {
    props.fetchChangeLanguage(e);
    i18next.changeLanguage(e);
  };
  const loginInputChange = (e) => {
    setloginInput({
      ...loginInput,
      [e.target.name]: e.target.value,
    });

    switch (e.target.name) {
      case "email":
        setErrors({
          ...errors,
          email: "",
        });
        break;
      case "password":
        setErrors({
          ...errors,
          password: "",
        });
        break;

      default:
        break;
    }
  };
  const validation = () => {
    let checkValue = true;

    if (!loginInput.email.match(emailRegex)) {
      errors.email =
        getLastnamefromurl === "specialist"
          ? t("pages.specialist-login.email-valid")
          : "Enter your valid email";
      checkValue = false;
    }

    if (loginInput.email === "") {
      errors.email =
        getLastnamefromurl === "specialist"
          ? t("pages.specialist-login.email-warn")
          : "Enter your email";
      checkValue = false;
    }

    if (loginInput.password === "") {
      errors.password =
        getLastnamefromurl === "specialist"
          ? t("pages.specialist-login.password-warn")
          : "Enter your password";
      checkValue = false;
    }

    setErrors({
      ...errors,
      email: errors.email,
      password: errors.password,
    });

    if (checkValue === false) {
      return false;
    } else {
      return true;
    }
  };

  const callLogin = async (e) => {
    e.preventDefault();
    if (validation()) {
      let loginDetail = {
        username: loginInput.email,
        password: loginInput.password,
      };
      let getLoginresponse = await getLogindetails(loginDetail);
      if (getLoginresponse.status === 200) {
        let getLogindata = await getLoginresponse.json();
       // console.log("Specialist design", getLogindata);
        getDetails(getLogindata?.access);
      } else if (getLoginresponse.status === 401) {
        let getLogindata = await getLoginresponse.json();
        toast.error(getLogindata?.detail);
      } else if (getLoginresponse.status === 501) {
      }
    }
  };

  const getDetails = async (acc) => {
    let adminResponce = await getUserdetail(acc);
    if (adminResponce.status === 200) {
      const getData = await adminResponce.json();
      const type = getData?.data[0]?.user_type;
      const status = getData?.data[0]?.is_deleted;
     // console.log(getData?.data[0]);
      if (type === "admin" || type === "sp") {
        if(status===false){
          props.fetchAdminLogindata(acc, getData?.data, getData?.url);
        }else{
          toast.warning("dont authorized to login please contact administrator");
        }
      } else {
        toast.warning("Invalid user crediatial");
      }
    }
  };

  return (
    <>
      <div class="admin account-pages">
        <div class="admin container">
          <div class="admin row justify-content-center">
            <div class="admin col-md-8 col-lg-6 col-xl-4">
              <div class="admin text-center">
                <a href>
                  <h5 className="logo-title">
                    Confy<label>Car</label>
                  </h5>
                </a>
                <p class="admin text-muted mt-2 mb-4 fw-5">
                  {getLastnamefromurl === "specialist"
                    ? t("pages.specialist-login.subtitle")
                    : "Admin Dashboard"}
                </p>
              </div>
              <div class="admin card">
                <div class="admin card-body p-4">
                  <div class="admin text-center mb-4">
                    <h4 class="admin text-uppercase mt-0 fw-7">
                      {getLastnamefromurl === "specialist"
                        ? t("pages.specialist-login.title")
                        : "SIGN IN"}
                    </h4>
                  </div>

                  <form>
                    <div class="admin mb-3">
                      <label class="admin form-label">
                        {getLastnamefromurl === "specialist"
                          ? t("pages.specialist-login.email")
                          : "Email Address"}
                      </label>
                      <input
                        value={loginInput.email}
                        name={"email"}
                        onChange={(e) => loginInputChange(e)}
                        class="admin form-control"
                        type="email"
                        placeholder={
                          getLastnamefromurl === "specialist"
                            ? t("pages.specialist-login.email-place")
                            : "Enter your email"
                        }
                      />
                      <div className="text-danger">{errors.email}</div>
                    </div>

                    <div class="admin mb-3">
                      <label class="admin form-label">
                        {getLastnamefromurl === "specialist"
                          ? t("pages.specialist-login.password")
                          : "Password"}
                      </label>
                      <div className="input-group">
                        <input
                          name="password"
                          value={loginInput.password}
                          class="admin form-control"
                          onChange={(e) => loginInputChange(e)}
                          type={passwordState ? "text" : "password"}
                          placeholder={
                            getLastnamefromurl === "specialist"
                              ? t("pages.specialist-login.password-place")
                              : "Enter your password"
                          }
                        />
                        <span
                          className="input-group-text"
                          onClick={() => setPasswordState(!passwordState)}
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            className={
                              passwordState ? "fa fa-eye" : "fa fa-eye-slash"
                            }
                          />
                        </span>
                      </div>
                      <div className="text-danger">{errors.password}</div>
                    </div>

                    <div class="admin mb-3">
                      {/* <div class="admin form-check">
                        <input
                          type="checkbox"
                          class="admin form-check-input"
                          id="checkbox-signin"
                        />
                        <label
                          class="admin form-check-label"
                          for="checkbox-signin"
                        >
                          {getLastnamefromurl === "specialist"
                            ? t("pages.specialist-login.remember")
                            : "Remember me"}
                        </label>
                      </div> */}
                    </div>

                    <div class="admin mb-3 d-grid text-center">
                      <button
                        class="admin btn btn-submit"
                        type="submit"
                        onClick={(e) => callLogin(e)}
                      >
                        {getLastnamefromurl === "specialist"
                          ? t("pages.specialist-login.button")
                          : "Log In"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div class="admin row mt-3 mb-5">
                <div class="admin col-12 text-center">
                  <p>
                    {" "}
                    <a href class="admin text-muted text-black-50 ms-1">
                      <i class="admin fa fa-lock me-1"></i>
                      {getLastnamefromurl === "specialist"
                        ? t("pages.specialist-login.forgot")
                        : "Forgot your password?"}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  access: state?.adminlogin?.access,
  admin_data: state?.adminlogin?.admin_data,
  language: state?.theme?.language,
});
export default connect(mapStateToProps, {
  fetchAdminLogindata,
  fetchChangeLanguage,
})(LoginPage);
