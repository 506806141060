import { Tab } from 'bootstrap';
import React, { useEffect, useState } from 'react';
import { Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getFaqdetail } from '../../../faq/util/getFaq';
import LoaderPage from '../../loader/components/LoaderPage';
import { addFaqsData } from '../util/addfaq';
import { deleteFaq } from '../util/deleteFaq';
import { updateFAQ } from '../util/updatefaq';
import "./AddfaqPage.css";

function AddfaqPage(props) {
    const field = {
        title:"",
        description:"",
        title_sp:"",
        description_sp:""
    }
    const [tabKey, setTabKey] = useState("english");
    const [faqCollaps, setFaqCollaps] = useState("");
    const [faqState, setFaqState] = useState("Save");
    const [faqInputState, setFaqInputState] = useState("Read");
    const [selectFaq, setSelectFaq] = useState({})
    const [addfaqInput,setAddfaqInput] = useState(field);
    const [faqList, setFaqList] = useState([]);
    const [errors, setErrors] = useState(field)
    const [loadState, setLoadState] = useState(true)

    useEffect(()=>{
        callGetAllfaq()
    // eslint-disable-next-line
    },[])

    const validation = () =>{
        let value = true;
        if(addfaqInput.title===""){
            errors.title="Enter english quetion"
            value = false;
        }
        if(addfaqInput.description===""){
            errors.description="Enter english answer"
            value = false;
        }
        if(addfaqInput.title_sp===""){
            errors.title_sp="Enter spanish quetion"
            value = false;
        }
        if(addfaqInput.description_sp===""){
            errors.description_sp="Enter spanish answer"
            value = false;
        }
        setErrors({
            ...errors,
            title:errors.title,
            title_sp:errors.title_sp,
            description:errors.description,
            description_sp:errors.description_sp
        })
        return value;
    }
    const callAddfaq = async (e) =>{
        e.preventDefault();
        if(validation()){
                const data = {
                    title:addfaqInput.title,
                    description:addfaqInput.description,
                    description_sp:addfaqInput.description_sp,
                    title_sp:addfaqInput.title_sp
                }
            const responceFAQ = await addFaqsData(props.access,data);
                if(responceFAQ.status===201){
                    await responceFAQ.json();
                    //console.log(resultFAQ);
                    callGetAllfaq()
                    setAddfaqInput({
                        title:"",
                        description:"",
                        title_sp:"",
                        description_sp:""
                    })
                }
        }
    }
    const callUpdatefaq = async (e)=>{
        e.preventDefault();
        if(validation()){
            const data = {
                title:addfaqInput.title,
                description:addfaqInput.description,
                description_sp:addfaqInput.description_sp,
                title_sp:addfaqInput.title_sp,
                id:selectFaq?.id,
            }
        const responceFAQ = await updateFAQ(props.access,data);
            if(responceFAQ.status===200){
                await responceFAQ.json();
                //console.log(resultFAQ);
                toast.success("Successfull update faq contents")
                callGetAllfaq()
                setAddfaqInput({ title:"",description:"",title_sp:"",description_sp:""})
                setSelectFaq({})
            }
        }
    }
    const callGetAllfaq = async () =>{
        const responceFaq = await getFaqdetail();
            if(responceFaq.status===200){
                const resultFaq = await responceFaq.json();
                if(resultFaq.length===0){
                    setFaqInputState("Save");
                    setFaqState("Write")
                    setFaqList(resultFaq)
                }else{
                    setFaqList(resultFaq)
                    setFaqInputState("Update");
                    setFaqState("Read");
                }
            }else{
                await responceFaq.json();
                //console.log(resultFaq);
            }
        setTimeout(()=>setLoadState(false), 100)
    }
    const callDeleteFaq = async (e,id) =>{
        e.preventDefault();
        const responceFAQ = await deleteFaq(props.access,id);
        if(responceFAQ.status===200){
            await responceFAQ.json();
           // console.log(resultFAQ);
        }
        callGetAllfaq()
    }
    const callChangeInput = (e) =>{
        setAddfaqInput((res)=>({...res,[e.target.name]:e.target.value}))
        setErrors((res)=>({...errors,[e.target.name]:""}))
    }

  if(loadState) 
    return <LoaderPage/>

  return (
    <div className='addfaq-contents'>
       <div class="admin content-page">
            <div class="admin content">
                <div class="admin container-fluid">
                    <div class="admin row">
                    {faqState==="Write"|| selectFaq?.id!==undefined?
                        <div class="admin col-12">
                            <div class="admin card mt-3">
                                <div class="admin card-body">
                                        {faqList.length!==0&&
                                        <div className='col align-right'>
                                            <button className='btn-action-plus ml-1 ' onClick={()=>{
                                                setSelectFaq({})
                                                setFaqState("Read")
                                            }} >
                                                <i className='fa fa-times'></i>
                                            </button>
                                        </div>}
                                    <form onSubmit={selectFaq?.id!==undefined?callUpdatefaq:callAddfaq}>
                                    <Tabs id={`controlled-tab-example`}  activeKey={tabKey} onSelect={(k) => setTabKey(k)} className="admin nav nav-tabs total-cnt about-tab" variant={"pills tabs-inner-div"}>
                                        <Tab className="admin tab-pane  inbox-widget" tabClassName='hi' eventKey="english" title={"English"}>
                                            <h4 class=" admin mt-3 mb-2 header-title mt-2">English Faqs</h4>
                                           {selectFaq?.id!==undefined? 
                                                <div class="admin row">
                                                    <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <label for="title" class="admin form-label">Question</label>
                                                        <input value={addfaqInput.title} onChange={(e)=>callChangeInput(e)} type="text" id="title" name="title" class="admin form-control" placeholder="Enter Question " />
                                                        <span className='text-danger'>{errors.title}</span>
                                                    </div>
                                                    <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <label for="description" class="admin form-label">Answer</label>
                                                        <textarea rows={5} value={addfaqInput.description} onChange={(e)=>callChangeInput(e)} type="text" id="description" name="description" class="admin form-control" placeholder="Enter Answer" />
                                                        <span className='text-danger'>{errors.description}</span>
                                                    </div>
                                                </div>
                                                :
                                                <div class="admin row">
                                                    <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <label for="title" class="admin form-label">Question</label>
                                                        <input value={addfaqInput.title} onChange={(e)=>callChangeInput(e)} type="text" id="title" name="title" class="admin form-control" placeholder="Enter Question " />
                                                        <span className='text-danger'>{errors.title}</span>
                                                    </div>
                                                    <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <label for="description" class="admin form-label">Answer</label>
                                                        <textarea rows={5} value={addfaqInput.description} onChange={(e)=>callChangeInput(e)} type="text" id="description" name="description" class="admin form-control" placeholder="Enter Answer" />
                                                        <span className='text-danger'>{errors.description}</span>
                                                    </div>
                                                </div>
                                            }
                                        </Tab>
                                        <Tab className="admin tab-pane  inbox-widget mt-2" tabClassName='hi' eventKey="spanish" title={"Spanish"}>
                                            <h4 class=" admin mt-3 mb-2 header-title">Spanish Faqs</h4>

                                            <div class="admin row">
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="title_sp" class="admin form-label">Question</label>
                                                    <input value={addfaqInput.title_sp} onChange={(e)=>callChangeInput(e)} type="text" id="title_sp" name="title_sp" class="admin form-control" placeholder="Enter Question " />
                                                    <span className='text-danger'>{errors.title_sp}</span>
                                                </div>
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="description_sp" class="admin form-label">Answer</label>
                                                    <textarea rows={5} value={addfaqInput.description_sp} onChange={(e)=>callChangeInput(e)} type="text" id="description_sp" name="description_sp" class="admin form-control" placeholder="Enter Answer" />
                                                    <span className='text-danger'>{errors.description_sp}</span>
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                        
                                        <div class="admin mt-3 col-sm-12 text-left col-md-12 col-lg-12 col-xl-12">
                                            <button type="submit" class="admin btn btn-warning mt-1 width-xl waves-effect waves-light">{faqInputState}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        :
                        <div class="admin col-12 ">
                            <div class="admin card mt-3">
                                <div class="admin card-body">
                                
                                <div className='row'>
                                    <h4 class=" admin mt-2 mb-2 header-title col">Faqs List</h4>
                                    <div className='col align-right'>
                                        <button onClick={()=>{
                                                setFaqState("Write")
                                                setFaqInputState("Save")
                                                setAddfaqInput(field);
                                                setErrors(field)
                                            }} className='btn-action-plus ml-1 ' >
                                            <i className='fa fa-plus'></i>
                                        </button>
                                    </div>
                                                    
                                </div>
                                    <Tabs id={`controlled-tab-example`}  activeKey={tabKey} onSelect={(k) => setTabKey(k)} className="admin nav nav-tabs total-cnt about-tab" variant={"pills tabs-inner-div"}>
                                        <Tab className="admin tab-pane  inbox-widget mt-2" tabClassName='hi' eventKey="english" title={"English"}>
                                           
                                            <div class=" admin table-responsive">
                                                
                                                    <div className='faq-list-outer'>
                                                        {faqList.map((item, index)=>(
                                                            <div className={index===faqCollaps?'faq-list-inner active':'faq-list-inner'}>
                                                                
                                                                <div className='admin collapsible'>
                                                                    <label onClick={()=>index===faqCollaps?setFaqCollaps(""):setFaqCollaps(index)} >{item.title}</label>
                                                                    <div className='admin action-bottom-right'>
                                                                        <button className='admin btn-action-edit ml-1' onClick={()=>{
                                                                            setSelectFaq(item)
                                                                            setAddfaqInput(item)
                                                                            setFaqInputState("Update")
                                                                            setErrors(field)
                                                                        }} >
                                                                            <i className='admin fa fa-pencil'></i>
                                                                        </button> 
                                                                        <button className='admin btn-action-delete' onClick={(e)=>callDeleteFaq(e,item.id)}>
                                                                            <span className=''>delete</span>
                                                                        </button>
                                                                        <i  onClick={()=>index===faqCollaps?setFaqCollaps(""):setFaqCollaps(index)} className={index===faqCollaps?"fa fa-minus":'fa fa-plus '} />
                                                                    </div>
                                                                </div>
                                                                <div className={index===faqCollaps?'admin collapsible-bottom ':'admin  collapsible-bottom active'}>
                                                                    <span>
                                                                        {item.description}
                                                                    </span>
                                                                </div>
                                                            
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab className="admin tab-pane  inbox-widget mt-2" tabClassName='hi' eventKey="spanish" title={"Spanish"}>
                                            <div class=" admin table-responsive">
                                                
                                                    <div className='faq-list-outer'>
                                                        {faqList.map((item, index)=>(
                                                            <div className={index===faqCollaps?'faq-list-inner active':'faq-list-inner'}>
                                                                
                                                                <div className='admin collapsible'>
                                                                    <label onClick={()=>index===faqCollaps?setFaqCollaps(""):setFaqCollaps(index)} >{item.title_sp}</label>
                                                                    <div className='admin action-bottom-right'>
                                                                        <button className='admin btn-action-edit ml-1' onClick={()=>{
                                                                            setSelectFaq(item)
                                                                            setAddfaqInput(item)
                                                                            setFaqInputState("Update")
                                                                            setErrors(field)
                                                                        }} >
                                                                            <i className='admin fa fa-pencil'></i>
                                                                        </button> 
                                                                        <button className='admin btn-action-delete' onClick={(e)=>callDeleteFaq(e,item.id)}>
                                                                            <span className=''>delete</span>
                                                                        </button>
                                                                        <i  onClick={()=>index===faqCollaps?setFaqCollaps(""):setFaqCollaps(index)} className={index===faqCollaps?"fa fa-minus":'fa fa-plus '} />
                                                                    </div>
                                                                </div>
                                                                <div className={index===faqCollaps?'admin collapsible-bottom ':'admin  collapsible-bottom active'}>
                                                                    <span>
                                                                        {item.description_sp}
                                                                    </span>
                                                                </div>
                                                            
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}
const mapStateToProps = (store) =>({
    access:store?.adminlogin?.access
})
export default connect(mapStateToProps)(AddfaqPage);