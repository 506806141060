export const updateFAQ = async (access, data) => {
    try {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${access}`);
  
      headers.append("Content-Type", "application/json");
  
      const getResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/post_faqs/${data.id}`,
        {
          headers,
          method: "PUT",
          body:JSON.stringify(data)
        }
      );
  
      return getResponse;
    } catch (error) {}
  };
  