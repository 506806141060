export const updateaboutus = async (access, data) => {
    try {
      const headers = new Headers();
        console.log(data)
      headers.append("Authorization", `Bearer ${access}`);
  
      headers.append("Accept", "application/json");
  
      const getResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/about_us/${data.id}`,
        {
          headers,
          method: "PUT",
          body:JSON.stringify(data)
        }
      );
  
      return getResponse;
    } catch (error) {}
};