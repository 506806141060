export const getPostnotificationdetails = async (postDetails, access) => {
  try {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    headers.append("Authorization", `Bearer ${access}`);

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/notifications`,
      {
        headers,
        method: "POST",
        body: JSON.stringify(postDetails),
      }
    );

    return getResponse;
  } catch (error) {}
};
