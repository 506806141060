import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setShowpackagelist } from '../../home/redux/actions';
import { Logout } from '../../login/redux/actions';
import { getUserdetail } from '../../profile/util/getUserdetail';

function Loginexpire(props) {
  const title = window.location.pathname;

    useEffect(()=>{
        checkUserloginState()
    // eslint-disable-next-line
    }, [title!==window.location.pathname]);

    const checkUserloginState = async () =>{
      if(props?.access!==""){
        const response = await getUserdetail(props?.access);
        if(response.status === 200){
          // console.log("login")
        }else{
            props.Logout();
            props.setShowpackagelist(false);
        }
      }
    }
  return (
    <div/>
  )
}
const mapStateToProps = (state) =>({
    access: state?.login?.access,
})
export default connect(mapStateToProps,{Logout, setShowpackagelist})(Loginexpire)