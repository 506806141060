export const getSpecialist = async (access, id) => {
    try {
      const headers = new Headers();
  
      headers.append("Authorization", `Bearer ${access}`);
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
  
      const getResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/manage-specialist/${id}/`,
        {
          headers,
          method: "GET",
        }
      );
  
      return getResponse;
    } catch (error) {}
};