import React, { useState, useEffect } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import "./SpecialistmapPage.css";
import { getNearbyspecialist } from "../util/nearbySpecialist";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Row, Card, Form, InputGroup } from "react-bootstrap";
import HeaderPage from "../../header/components/HeaderPage";
import FooterPage from "../../footer/components/FooterPage";
import sp_icon from "../../../assets/sp_map.png";
import { setSpecialistid } from "../../home/redux/actions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import search_icon from "../../../assets/search_icon.png";
import marker_icon from "../../../assets/marker_icon.png";
import map_profile from "../../../assets/map_profile.svg";
import default_marker from "../../../assets/default_marker.png";
import close_icon from "../../../assets/close_icon.svg";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useLocation } from "react-router-dom";

function SpecialistmapPage(props) {
  const navigate = useNavigate();

  const location = useLocation();

  const [specialistSelected, setSpecialistselected] = useState([]);

  const [specialistList, setSpecialistlist] = useState([]);

  const [selectedIndex, setSelectedindex] = useState();

  const [showLocation, setShowlocation] = useState(false);

  const [isChecked, setIschecked] = useState(false);

  const [viewlist, setViewlist] = useState(false);

  const { t } = useTranslation();

  const [searchlabel, setSearchlabel] = useState("");

  const [noSpecialist, setNospecialist] = useState(false);

  const [currentlocation, setCurrentlocation] = useState({
    location: "",
    lat: "",
    lng: "",
  });

  const defaultProps = {
    center: {
      lat: 18.5248904,
      lng: 73.7228773,
    },
    zoom: 13,
  };

  const [isshow, setIsshow] = useState(defaultProps);

  useEffect(() => {
    getCurrentLocation();
    // eslint-disable-next-line
  }, []);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(currentLocation, showError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        getCurrentLocation();
        setShowlocation(true);
        break;
      case error.POSITION_UNAVAILABLE:
        break;
      case error.TIMEOUT:
        break;
      case error.UNKNOWN_ERROR:
        break;
      default:
        break;
    }
  };

  // After change location

  const locationOnchange = (value) => {
    setCurrentlocation({
      ...currentlocation,
      location: value,
    });
  };

  const getSelectedlocation = async (value) => {
    const results = await geocodeByAddress(value);
    const latlng = await getLatLng(results[0]);
    setCurrentlocation({
      ...currentlocation,
      location: value,
      lat: latlng.lat,
      lng: latlng.lng,
    });
    setIsshow({
      center: {
        lat: latlng.lat,
        lng: latlng.lng,
      },
      zoom: 14,
    });
    callNearbyspecialist(latlng.lat, latlng.lng);
    setSelectedindex();
  };

  // Get current location
  const currentLocation = async (position) => {
    setShowlocation(false);
    var lat = location?.state?.vehicaldetail?.lat?location?.state?.vehicaldetail?.lat:position?.coords?.latitude;
    var lng = location?.state?.vehicaldetail?.lng?location?.state?.vehicaldetail?.lng:position?.coords?.longitude;
    // console.log(location?.state?.vehicaldetail)
    callNearbyspecialist(lat, lng);
  };

  const callNearbyspecialist = async (current_lat, current_lng) => {
    let currentLatlng = {
      lat: current_lat,
      lng: current_lng,
    };
    const getNearbyspecialistresponse = await getNearbyspecialist(
      props.access,
      currentLatlng
    );
    if (getNearbyspecialistresponse?.status === 200) {
      let getResponse = await getNearbyspecialistresponse.json();
      // console.log("Response", getResponse);
      let object = {};
      let list = [];
      setIsshow({
        center: {
          lat: current_lat,
          lng: current_lng,
        },
        zoom: 14,
      });
      getResponse?.data.forEach((item) => {
        object = {
          id: item?.id,
          name: item?.fullname,
          mobile: item?.mobile,
          specialization: item?.specialization,
          experience: item?.experience,
          location: item?.location,
          profile_pic: item?.profile_pic,
          position: { lat: Number(item.lat), lng: Number(item.lng) },
          icon: sp_icon,
        };
        list.push(object);
      });
      if (list.length === 0) {
        setNospecialist(true);
      } else {
        setNospecialist(false);
      }
      object = {
        position: { lat: current_lat, lng: current_lng },
        icon: sp_icon,
      };
      list.push(object);
      setSpecialistselected(list);
      setSpecialistlist(list);
    }
  };

  const selectSpecialist = (index, id) => {
    setSelectedindex(index);
    props.setSpecialistid(id);
    setIschecked(false);
  };

  const handleOnLoad = async (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
  };

  const callCardetail = () => {
    if (isChecked === true || props.specialist_id !== "") {
      navigate("/detail/card", {
        state: { vehicaldetail: location.state.vehicaldetail },
      });
    } else {
        toast.error(t('pages.map.select-sp'));
    }
  };

  const allowAdminselect = (e) => {
    if (e.target.checked === true) {
      setSelectedindex();
      props.setSpecialistid("");
      setIschecked(true);
    } else {
      setIschecked(false);
    }
  };

  const serchLabelchange = (e) => {
    e.preventDefault();
    setSearchlabel(e.target.value);
  };

  const viewSpecialistlist = () => {
    setViewlist(true);
  };

  const closeList = () => {
    setViewlist(false);
  };
  // console.log(props)
  return (
    <>
      <HeaderPage color={"black"} logo={"black"} background={"#F7F7F7"} />

      <div className="container sp-google-map">
        <Row>
          <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8">
            <Row className="pt-3">
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <h5
                  className={props.language === "Sp" ? "spanish-chloc" : "h5"}
                >
                  {t("pages.map.changelocation")}
                </h5>
              </div>
              <div className="col-sm-12 col-md-10 col-lg-10 col-xl-10">
                <PlacesAutocomplete
                  name="location"
                  value={currentlocation.location}
                  onChange={locationOnchange}
                  onSelect={getSelectedlocation}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="vehicle-location-place">
                      <div className="location-search">
                        <InputGroup className="input-group">
                          <InputGroup.Text>
                            <img src={search_icon} alt={"."} />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            {...getInputProps({
                              placeholder: t("pages.map.location"),
                            })}
                          ></Form.Control>
                        </InputGroup>
                      </div>
                      <div className="autoplace-location">
                        {loading ? <div>...loading</div> : null}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#fff"
                              : "#fff",
                            border: "1px solid #dbdcde",
                            padding: "0px 10px",
                          };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                style,
                              })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            </Row>

            <div className="main-map">
              <GoogleMap
                onLoad={handleOnLoad}
                center={isshow.center}
                zoom={isshow.zoom}
                className={"google-map"}
                mapContainerStyle={{
                  width: "100%",
                  height: "60vh",
                  borderRadius: "20px",
                  marginTop: "0.5rem",
                }}
              >
                {specialistList.map((item, index) => (
                  <Marker
                    key={item.id}
                    position={item.position}
                    icon={
                      selectedIndex === index ? marker_icon : default_marker
                    }
                  >
                    {specialistSelected[index]?.id === item?.id &&
                    typeof item?.id !== "undefined" ? (
                      <InfoWindow position={item.position}>
                        <div className="specialist-map-tol-info">
                          <input
                            type="radio"
                            checked={selectedIndex === index ? true : false}
                            name="select"
                            id="sp-opt"
                          />
                          <div htmlFor="sp-opt" className="selected-sp">
                            <h6>
                              Address :-
                              <br />
                              {item.location}
                            </h6>
                          </div>
                        </div>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                ))}
              </GoogleMap>

              <button
                className="view-sp-list-btn"
                onClick={() => viewSpecialistlist()}
              >
                {t("pages.map.view-sp")}
              </button>
              <div className="proceed-div mt-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    checked={isChecked === false ? false : true}
                    id="flexCheckDefault"
                    onChange={(e) => allowAdminselect(e)}
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    {t("pages.map.skip")}
                  </label>
                </div>
                <button
                  className="continue-btn"
                  onClick={() => callCardetail()}
                >
                  {t("pages.map.proceed")}
                </button>
              </div>
            </div>
          </div>
          <div
            className={
              viewlist
                ? "specialist-list"
                : "col-sm-12 col-md-12 col-lg-4 col-xl-4 collapse-list"
            }
          >
            <div className="sp-list pt-3">
              <div className="sp-list-header">
                <h5 className="sp-title">{t("pages.map.specialist")}</h5>
                <img
                  className={viewlist ? "sp-list-close" : "d-none"}
                  src={close_icon}
                  onClick={() => closeList()}
                  alt=""
                ></img>
              </div>
              <div className="splist-search">
                <Form.Group className="form-group">
                  <InputGroup className="input-group">
                    <InputGroup.Text>
                      <img src={search_icon} alt={"."} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      className="form-control"
                      placeholder={t("pages.map.search")}
                      value={searchlabel}
                      onChange={(e) => serchLabelchange(e)}
                    ></Form.Control>
                  </InputGroup>
                </Form.Group>
              </div>

              {noSpecialist === true ? (
                <div className="no-specialist-found">
                  <h5>{t("pages.map.no-specialist")}</h5>
                </div>
              ) : (
                <div className="overflow-list">
                  {specialistList
                    .filter((value) => {
                      if (searchlabel === "") {
                        return value;
                      }
                      if (
                        value?.specialization
                          ?.toLowerCase()
                          ?.includes(searchlabel?.toLowerCase()) ||
                        value?.mobile
                          ?.toLowerCase()
                          ?.includes(searchlabel?.toLowerCase())
                      ) {
                        return value;
                      }
                      return null;
                    })
                    .map((item, index) => (
                      <div
                        className="col-xl-12"
                        onClick={() => selectSpecialist(index, item.id)}
                        style={{
                          display:
                            typeof item.id === "undefined" ? "none" : "initial",
                        }}
                      >
                        <input
                          type="radio"
                          checked={selectedIndex === index ? true : false}
                          name="select_opt"
                          id="sp-list-opt"
                        />
                        <Card className="splist-card" htmlFor="sp-list-opt">
                          <Card.Img
                            src={item.profile_pic}
                            onError={(event) => {
                              event.target.src = map_profile;
                            }}
                          />
                          <Card.Body>
                            <div className="sp-info">
                              <label>{item.name}</label>
                              <label>{item.mobile}</label>

                              <label className="sp-list-year pr-5">
                                <span>{t("pages.map.year")} -</span>
                                {item.experience}
                              </label>
                              <label className="sp-list-specialisation">
                                <span>{t("pages.map.specialisation")} -</span>
                                {item.specialization}
                              </label>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </Row>
        {showLocation && (
          <Modal show={showLocation}>
            <Modal.Body>{t("pages.map.location-allow")}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary">{t("pages.map.ok-btn")}</Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
      <FooterPage />
    </>
  );
}
const mapStateToProps = (state) => ({
  access: state?.login?.access,
  specialist_id: state?.home?.specialist_id,
  language: state?.theme?.language,
});
export default connect(mapStateToProps, { setSpecialistid })(SpecialistmapPage);
