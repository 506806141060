import "./SpecialistdetailPage.css";
import { Image } from "react-bootstrap";
import user from "./../../../../assets/profile_pic.svg";
import { getSpecialistdetail } from "../util/specialistDetail";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import moment from "moment";
import LoaderPage from "../../loader/components/LoaderPage";

function SpecialistdetailPage(props) {
  let getId = window.location.pathname.split("/").pop();
  const [loadState, setLoadState] = useState(true)
  const [specialistdetail, setSpecialistdetail] = useState();
 // const url = props?.profile_url;
  useEffect(() => {
    callSpecialistdetail();
    // eslint-disable-next-line
  }, [getId]);

  const callSpecialistdetail = async () => {
    let getSpecialistdetailresponse = await getSpecialistdetail(
      props.access,
      getId
    );
    if (getSpecialistdetailresponse?.status === 200) {
      let getResponse = await getSpecialistdetailresponse.json();
      setSpecialistdetail(getResponse);
    }
    setTimeout(()=>setLoadState(false), 100)

  };
  if(loadState) 
    return <LoaderPage/>
  return (
    <div className="specialist-detail">
      <div className="admin-content">
        <div class="admin content-page">
          <div class="admin content">
            <div class="admin container-fluid">
              <div class="admin row">
                <div class="admin col-xl-4 col-md-6">
                  <div class="admin card">
                    <div class="admin card-body spdetail-card-body">
                      <h4 class="admin header-title mt-0">Total Request</h4>
                      <h1 className="mt-2">{specialistdetail?.total_orders}</h1>
                      <h5>Total</h5>
                    </div>
                  </div>
                </div>

                <div class="admin col-xl-4 col-md-6">
                  <div class="admin card">
                    <div class="admin card-body spdetail-card-body">
                      <h4 class="admin header-title mt-0">Completed Request</h4>
                      <h1 className="mt-2">
                        {specialistdetail?.completed_orders}
                      </h1>
                      <h5>Completed</h5>
                    </div>
                  </div>
                </div>

                <div class="admin col-xl-4 col-md-6">
                  <div class="admin card">
                    <div class="admin card-body spdetail-card-body">
                      <h4 class="admin header-title mt-0">Pending Request</h4>
                      <h1 className="mt-2">
                        {specialistdetail?.pending_orders}
                      </h1>
                      <h5>Pending</h5>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-xl-12 col-lg-12">
                  <div class="admin card">
                    <div className="sp-user-info">
                      <div class="row">
                        <div className="col-sm-1">
                          <Image className="sp-profile-img" src={specialistdetail?.personal_details?.profile?specialistdetail?.personal_details?.profile:user}></Image>
                        </div>
                        <div className="col-sm-2">
                          <label>Name</label>
                          <h6>
                            {specialistdetail?.personal_details?.fullname}
                          </h6>
                        </div>
                        <div className="col-sm-3">
                          <label>Email</label>
                          <h6>{specialistdetail?.personal_details?.email}</h6>
                        </div>
                        <div className="col-sm-2">
                          <label>Mobile</label>
                          <h6>{specialistdetail?.personal_details?.mobile}</h6>
                        </div>
                        <div className="col-sm-2">
                          <label>Location</label>
                          <h6>
                            {specialistdetail?.personal_details?.location}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="admin col-xl-12 mt-1">
                  <div class="admin card">
                    <div class="admin card-body">
                      <h4 class="admin header-title mt-0 mb-3">
                        Current Request
                      </h4>

                      <div class="admin table-responsive">
                        <table class="admin table table-hover mb-0">
                          <thead>
                            <tr>
                              <th>Request id</th>
                              <th>Customer name</th>
                              <th>Type</th>
                              <th>Mobile Number</th>
                              <th>Status</th>
                              <th>Date</th>
                              <th>Amount paid</th>
                            </tr>
                          </thead>
                          <tbody>
                            {specialistdetail?.current_working_order?.map(
                              (item) => (
                                <tr>
                                  <td>{item.order_id}</td>
                                  <td>Sanket</td>
                                  <td>
                                    {item?.o_type?.charAt(0).toUpperCase() +
                                      item?.o_type?.slice(1)}
                                  </td>
                                  <td>6303098704</td>
                                  <td>
                                    <span class="admin badge bg-success">
                                      {item?.current_status
                                        ?.charAt(0)
                                        .toUpperCase() +
                                        item?.current_status?.slice(1)}
                                    </span>
                                  </td>
                                  <td>
                                    {moment(item?.date_time).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>{item?.final_amount}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="admin col-xl-12 mt-1">
                  <div class="admin card">
                    <div class="admin card-body">
                      <h4 class="admin header-title mt-0 mb-3">
                        Previoues Request
                      </h4>

                      <div class="admin table-responsive">
                        <table class="admin table table-hover mb-0">
                          <thead>
                            <tr>
                              <th>Request id</th>
                              <th>Customer name</th>
                              <th>Type</th>
                              <th>Mobile Number</th>
                              <th>Status</th>
                              <th>Date</th>
                              <th>Amount paid</th>
                            </tr>
                          </thead>
                          <tbody>
                            {specialistdetail?.order_list?.map((item) => (
                              <tr>
                                <td>{item?.order_id}</td>
                                <td>Sanket</td>
                                <td>
                                  {item?.o_type?.charAt(0).toUpperCase() +
                                    item?.o_type?.slice(1)}
                                </td>
                                <td>6303098704</td>
                                <td>
                                  <span class="admin badge bg-success">
                                    {item?.current_status
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      item?.current_status?.slice(1)}
                                  </span>
                                </td>
                                <td>
                                  {moment(item?.date_time).format("DD/MM/YYYY")}
                                </td>
                                <td>{item?.final_amount}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  access: state?.adminlogin?.access,
  profile_url: state.adminlogin.profile_pic_base_url,
});

export default connect(mapStateToProps)(SpecialistdetailPage);
