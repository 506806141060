import React, { useState } from "react";
import { Modal, Button, Form, Image } from "react-bootstrap";
import { getFeedbackdetail } from "../../request/util/submitFeedback";
import { connect } from "react-redux";
import angry_gray from "../../../assets/angry_gray.png";
import poor_gray from "../../../assets/poor_gray.png";
import confused_gray from "../../../assets/confused_gray.png";
import good_gray from "../../../assets/good_gray.png";
import excellent_gray from "../../../assets/excellent_gray.png";
import angry_emoji from "../../../assets/angry_emoji.png";
import poor_emoji from "../../../assets/poor_emoji.png";
import confused_emoji from "../../../assets/confused_emoji.png";
import good_emoji from "../../../assets/good_emoji.png";
import excellent_emoji from "../../../assets/excellent_emoji.png";
import "./AddreviewPage.css";
import { toast } from "react-toastify";
import { getOrderlist } from "../../details/util/orderList";
import { useTranslation } from "react-i18next";

function AddreviewPage(props) {
  const { t } = useTranslation();

  const emoji = [
    {
      img: angry_emoji,
      title: t('pages.very-poor'),
      value: 1,
    },
    {
      img: poor_emoji,
      title: t('pages.poor'),
      value: 2,
    },
    {
      img: confused_emoji,
      title: t('pages.average'),
      value: 3,
    },
    {
      img: good_emoji,
      title: t('pages.good'),
      value: 4,
    },
    {
      img: excellent_emoji,
      title: t('pages.excellent'),
      value: 5,
    },
  ];

  const prevEmoji = [
    {
      img: angry_gray,
      className: "angry_gray",
    },
    {
      img: poor_gray,
      className: "poor_gray",
    },
    {
      img: confused_gray,
      className: "confused_gray",
    },
    {
      img: good_gray,
      className: "good_gray",
    },
    {
      img: excellent_gray,
      className: "excellent_gray",
    },
  ];

  const [currentEmoji, setCurrentemoji] = useState(prevEmoji);
  const [emojistatus, setEmojistatus] = useState("");
  const [feedbackInput, setFeedbackinput] = useState({
    feedback: "",
  });
  const [errors, setErrors] = useState({
    feedback: "",
    rating: "",
  });

  const openReview = () => {
    let getRequestdetail = props.orderlist.filter(
      (item) => item.id === props.requestId
    );
    //console.log("requ detail", getRequestdetail);
    if (getRequestdetail[0]?.user_feedback_rating?.length > 0) {
      setFeedbackinput({
        feedback: getRequestdetail[0]?.user_feedback_rating[0]?.feedback,
      });
      let updatedList = [];
      emoji.forEach((item, index) => {
        if (
          item.value ===
          Number(getRequestdetail[0]?.user_feedback_rating[0]?.rating)
        ) {
          prevEmoji[index].img = item?.img;
          setEmojistatus(item?.title);
        }
        updatedList.push(prevEmoji[index]);
      });
      setCurrentemoji(updatedList);
    } else {
      setCurrentemoji(prevEmoji);
      setFeedbackinput({
        feedback: "",
      });
      setEmojistatus("");
    }
  };

  const closeReview = () => {
    props.setShowreview(false);
    setCurrentemoji([]);
    setErrors({
      feedback: "",
      rating: "",
    });
  };

  const emojiOnchange = (getIndex) => {
    let updatedList = [];
    emoji.forEach((item, index) => {
      if (getIndex === index) {
        currentEmoji[index].img = item.img;
        setEmojistatus(item.title);
      } else {
        currentEmoji[index].img = prevEmoji[index].img;
      }
      updatedList.push(currentEmoji[index]);
    });
    setCurrentemoji(updatedList);
    setErrors({
      ...errors,
      rating: "",
    });
  };

  const feedbackChange = (e) => {
    setFeedbackinput({
      ...feedbackInput,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      feedback: "",
    });
  };

  const validation = () => {
    let checkValue = true;

    if (feedbackInput.feedback === "") {
      if (props.language === "Sp") {
        errors.feedback = "Introduce tu comentarios";
      } else {
        errors.feedback = "Enter your feedback";
      }
      checkValue = false;
    }

    if (emojistatus === "") {
      if (props.language === "Sp") {
        errors.rating = "Seleccione su calificación";
      } else {
        errors.rating = "Select your rating";
      }
      checkValue = false;
    }

    setErrors({
      ...errors,
      feedback: errors.feedback,
      rating: errors.rating,
    });

    if (checkValue === false) {
      return false;
    } else {
      return true;
    }
  };

  const submitFeedback = async () => {
    if (validation()) {
      let getRequestdetail = props.orderlist.filter(
        (item) => item.id === props.requestId
      );
      let getRating = emoji.filter((item) => item.title === emojistatus);
      if (getRequestdetail.length > 0) {
        let feedbackDetail = {
          receiver_id: getRequestdetail[0]?.assign_id,
          order_id: getRequestdetail[0]?.order_details[0]?.order_id_id,
          feedback: feedbackInput.feedback,
          rating: getRating[0]?.value,
        };

        let getFeedbackdetailresponse = await getFeedbackdetail(
          props.access,
          feedbackDetail
        );
        if (getFeedbackdetailresponse.status === 201) {
          if (props.language === "Sp") {
            toast.success("Comentario enviado");
          } else {
            toast.success("Your feedback submitted");
          }
          props.setShowreview(false);
          let getOrderlistresponse = await getOrderlist(props.access, "");
          if (getOrderlistresponse.status === 200) {
            let getResponse = await getOrderlistresponse.json();
            getResponse = Object.values(getResponse);
           // console.log("Order list", getResponse);
            if (getResponse.length > 0) {
              props.setOrderlist(getResponse);
            }
          }
        }else if(getFeedbackdetailresponse.status===400){
          toast.info(props.language === "Sp"?"La palabra es demasiado larga ingrese un máximo de 200 palabras":"Word is to longo enter maximum 200 word");
        }
      }
    }
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showreview}
      className="review-modal"
      onHide={() => closeReview()}
      onShow={() => openReview()}
    >
      <Modal.Body>
        <div className="review">
          <div className="review-header">
            <h5>{t("pages.request.request-popup.title")} </h5>
          </div>
          <div>
            <div>
              <ul>
                {currentEmoji.map((item, index) => (
                  <li  key={index.toString()}>
                    <Image
                      src={item?.img}
                      className={item?.className}
                      onClick={() => emojiOnchange(index)}
                    ></Image>
                  </li>
                ))}
              </ul>
            </div>
            <div className="text-danger">{errors.rating}</div>
            <h6>{emojistatus}</h6>
            <Form.Group className="form-group">
              <Form.Control
                type="text"
                placeholder={t("pages.request.request-popup.input-place")}
                as="textarea"
                rows="5"
                value={feedbackInput.feedback}
                name="feedback"
                onChange={(e) => feedbackChange(e)}
              ></Form.Control>
            </Form.Group>
            <div className="text-danger">{errors.feedback}</div>
            
          </div>
          <Button className="review-btn" onClick={() => submitFeedback()}>
            {t("pages.request.request-popup.send")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
const mapStateToProps = (state) => ({
  access: state?.login?.access,
  language: state?.theme?.language,
});
export default connect(mapStateToProps)(AddreviewPage);
