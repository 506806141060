export const getMarkcomplete = async (markcomplete, access) => {
  try {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    headers.append("Authorization", `Bearer ${access}`);

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/specialist/request_mark_complete`,
      {
        headers,
        method: "POST",
        body: JSON.stringify(markcomplete),
      }
    );

    return getResponse;
  } catch (error) {}
};
