import React, { useEffect, useState } from "react";
import "./MyaccountPage.css";
import HeaderPage from "../../header/components/HeaderPage";
import FooterPage from "../../footer/components/FooterPage";
import { Tabs, Tab, Container } from "react-bootstrap";
import user_icon from "./../../../assets/user_icon_black.svg";
import request from "./../../../assets/request.svg";
import payment from "./../../../assets/payment.svg";
import notifications from "./../../../assets/notifications.svg";
import ProfilePage from "../../profile/components/ProfilePage";
import RequestPage from "../../request/components/RequestPage";
import PaymenthistoryPage from "../../paymenthistory/components/PaymenthistoryPage";
import NotificationPage from "../../notification/components/NotificationPage";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MyaccountPage() {
  const [tabkey, setTabkey] = useState("profile");

  let url = window.location.pathname.split("/").pop();

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (url === "request") {
      setTabkey("request");
    } else if (url === "payment") {
      setTabkey("payment");
    } else if (url === "notification") {
      setTabkey("notification");
    } else if (url === "profile") {
      setTabkey("profile");
    }
  }, [url]);

  const onSelecttabchange = (key) => {
    if (key === "request") {
      setTabkey("request");
      navigate("request");
    } else if (key === "payment") {
      setTabkey("payment");
      navigate("payment");
    } else if (key === "notification") {
      setTabkey("notification");
      navigate("notification");
    } else if (key === "profile") {
      setTabkey("profile");
      navigate("profile");
    }
  };

  return (
    <>
      <HeaderPage logo={"black"} color={"black"} background={"#F7F7F7"} />
      <div className={"myaccount"}>
        <Container className="myaccount-container">
          <div className={"myaccount-tabs"}>
            <Tabs
              id={`controlled-tab-example`}
              activeKey={tabkey}
              onSelect={(key) => onSelecttabchange(key)}
              className="mb-3"
              variant={"pills tabs-inner-div"}
            >
              <Tab
                tabClassName="hi"
                eventKey="profile"
                title={
                  <div className={"tabs-list"}>
                    <div className={"tabs-img-div"}>
                      <img src={user_icon} alt={"."} className={"tabs-image"} />
                    </div>
                    <label>{t("pages.account.profile")}</label>
                  </div>
                }
              >
                <ProfilePage tabkey={tabkey} setTabkey={setTabkey} />
              </Tab>
              <Tab
                eventKey="request"
                title={
                  <div className={"tabs-list"}>
                    <div className={"tabs-img-div"}>
                      <img src={request} alt={"."} className={"tabs-image"} />
                    </div>
                    <label>{t("pages.account.request")}</label>
                  </div>
                }
              >
                <RequestPage tabkey={tabkey} setTabkey={setTabkey} />
              </Tab>
              <Tab
                eventKey="payment"
                title={
                  <div className={"tabs-list"}>
                    <div className={"tabs-img-div"}>
                      <img src={payment} alt={"."} className={"tabs-image"} />
                    </div>
                    <label>{t("pages.account.payment")}</label>
                  </div>
                }
                className={""}
              >
                <PaymenthistoryPage tabkey={tabkey} setTabkey={setTabkey} />
              </Tab>
              <Tab
                eventKey="notification"
                title={
                  <div className={"tabs-list"}>
                    <div className={"tabs-img-div"}>
                      <img
                        src={notifications}
                        alt={"."}
                        className={"tabs-image"}
                      />
                    </div>
                    <label>{t("pages.account.notification")}</label>
                  </div>
                }
                className={""}
              >
                <NotificationPage tabkey={tabkey} setTabkey={setTabkey} />
              </Tab>
            </Tabs>
          </div>
        </Container>
      </div>
      <FooterPage />
    </>
  );
}

export default MyaccountPage;
