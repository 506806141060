import React from 'react'
import { Spinner } from "react-bootstrap";

export default function LoaderPage() {
  return (
    <div className="backend-loader">
        <div className=" admin content-page">
        <Spinner animation="border" variant="warning" />
        </div>
    </div>
  )
}
