import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import LoaderPage from '../../../admin/loader/components/LoaderPage';
import { getDashboardDetail } from './../../dashboard/util/dashboard';
import "./FeedbackPage.css"


function SellerPage(props) {
    const { t } = useTranslation();
    const [feedbackList, setFeedbackList] = useState([]);
    const [loadState, setLoadState] = useState(true)

    useEffect(()=>{
        callFeedbackList();
        // eslint-disable-next-line
    },[])
    const callFeedbackList = async () =>{
        const responceFeedback = await getDashboardDetail(props.access, 'sp');
        if(responceFeedback.status===200){
            const resultFeedback = await responceFeedback.json();
            setFeedbackList(resultFeedback?.feedback)
        }
        setTimeout(()=>setLoadState(false), 1000)
    }
    if(loadState) 
        return <LoaderPage/>

    return (
    <div className={"feedback-content"}>
        <div class=" admin content-page">
            <div class=" admin content">
                <div class=" admin container-fluid">
                    <div class=" admin row">
                        <div class=" admin col-12">
                            <div class=" admin card mt-2">
                                <div class=" admin card-body">
                                    <h4 class=" admin mt-0 header-title">{t('pages.specialist.feedback.title')}</h4>
                                    <div class=" admin table-responsive">
                                        <table class=" admin table table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th>{t('pages.specialist.feedback.requestid')}</th>
                                                    <th>{t('pages.specialist.feedback.cust-name')}</th>
                                                    <th>{t('pages.specialist.feedback.feedback')}</th>
                                                    <th>{t('pages.specialist.feedback.rating')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {feedbackList.map((item)=>(
                                                    <tr>
                                                        <td>{item.order_id}</td>
                                                        <td>{item?.['sender data'][0].first_name}</td>
                                                        <td>{item?.user_feedback}</td>
                                                        <td>
                                                            <div className='rating'>
                                                                <span className={"span stars"+item?.rating} >
                                                                    <i className={"tomato mdi mdi-star"}/>
                                                                    <label >
                                                                        {item?.rating?item?.rating:0}
                                                                    </label>
                                                                </span>
                                                            </div>{console.log(Number(item.rating),5)}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}
const mapStateToProps = (state) =>({
    access: state?.adminlogin?.access,
    searchInput:state?.searchInput?.search
})
export default connect(mapStateToProps)(SellerPage)