import { LOGIN_SUCCESS, LOG_OUT } from "./actionTypes";
import { USER_ID } from "./actionTypes";

export const fetchLogindata = (access) => async (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    access: access,
  });
};

export const fetchUserid = (userId, userType) => async (dispatch) => {
  dispatch({
    type: USER_ID,
    userId: userId,
    userType: userType,
  });
};
export const Logout = () => (dispatch) =>{
  dispatch({type:LOG_OUT})
}