export const getSpecialistdetail = async (access, spId) => {
  try {
    const headers = new Headers();

    headers.append("Authorization", `Bearer ${access}`);

    headers.append("Accept", "application/json");

    console.log("access", access, "url", process.env.REACT_APP_BASE_URL);

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/specialist-details/${spId}/`,
      {
        headers,
        method: "GET",
      }
    );

    return getResponse;
  } catch (error) {}
};
