import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux'
import LoaderPage from '../../loader/components/LoaderPage';
import { actionSeller } from '../util/actionspecialist';
import { getSellerList } from '../util/sellerlist';
import "./SellerPage.css";


export const ModalPage = (props) =>{
    return (
        <Modal
            show={props.modalShow}
            size="md"
            aria-labelledby="contained-modal-title-vcenter "
            centered
            onHide={() => props.setModalShow({show:false,id:""})}
            className={`${props.class}-modal`}
        >
            <Modal.Header closeButton className='border-0 '>
              
            </Modal.Header>
            <Modal.Body>
            <center>
                <i className='fa fa-times-circle mb-3 text-danger' style={{ fontWeight:500, fontSize:40 }}/>
                <h6>{props.title}</h6>
                {props.body}
            </center>
            </Modal.Body>
            <Modal.Footer className='border-0 '>
                {props.children}
            </Modal.Footer>
    </Modal>
  )
}
function SellerPage(props) {
    const [sellerList, setSellerList] = useState([]);
    const [modalShow, setModalShow] =useState({
        show:false,
        id:""
    });
    const [loadState, setLoadState] = useState(true)

    useEffect(()=>{
        callSellerList()
    // eslint-disable-next-line
    },[!sellerList]);
    const callSellerList = async () =>{
        const sellerResponce = await getSellerList(props?.access);
        if(sellerResponce.status===200){
            const sellerResult = await sellerResponce.json();
            setSellerList(sellerResult);
        }
    setTimeout(()=>setLoadState(false), 100)
    }
    const callStatusChange = async (e,item) =>{
        e.preventDefault();
        const data = {
            action:item.is_active?"deactivate":"activate",
            user_id:item.id
        }
        const responceSeller = await actionSeller(props.access,data);
        if(responceSeller.status===200){
            const sellerResult =await responceSeller.json();
            console.log(sellerResult)
            callSellerList()
        }else{
            const sellerResult =await responceSeller.json();
            console.log(sellerResult)
        }
    }
    const callDeleteSeller = async (item) =>{
        const data = {
            action:"delete",
            user_id:item
        }
        const responceSeller = await actionSeller(props.access,data);
        if(responceSeller.status===200){
            const sellerResult =await responceSeller.json();
            console.log(sellerResult)
            setModalShow({show:false, id:""});
            callSellerList()
        }else{
            const sellerResult =await responceSeller.json();
            console.log(sellerResult)
        }
    }
    if(loadState) 
        return <LoaderPage/>

    return (
        <div className={"seller-content"}>
            <div class=" admin content-page">
                <div class=" admin content">
                    <div class=" admin container-fluid">
                        <div class=" admin row">
                            <div class=" admin col-12">
                                <div class=" admin card mt-2">
                                    <div class=" admin card-body">
                                        <h4 class=" admin mt-0 header-title">Seller List</h4>
                                        <div class=" admin table-responsive">
                                            <table class=" admin table table-hover mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Mobile Number</th>
                                                        <th>Date</th>
                                                        <th>Status</th>
                                                        <th class=" admin text-center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sellerList.length===0?
                                                        (
                                                            <tr>
                                                                <td colSpan={7} align={"center"}>No Data found</td>
                                                            </tr>
                                                        )
                                                    :
                                                        sellerList.filter((e)=>{
                                                            const data = e?.first_name?.toLowerCase()?.includes(props?.searchInput?.toLowerCase()) 
                                                            || e?.mobile?.toLowerCase()?.includes(props?.searchInput?.toLowerCase())
                                                            || e?.email?.toLowerCase()?.includes(props?.searchInput?.toLowerCase())
                                                            return data
                                                        }).length?
                                                        sellerList.filter((e)=>{
                                                            const data = e?.first_name?.toLowerCase()?.includes(props?.searchInput?.toLowerCase()) 
                                                            || e?.mobile?.toLowerCase()?.includes(props?.searchInput?.toLowerCase())
                                                            || e?.email?.toLowerCase()?.includes(props?.searchInput?.toLowerCase())
                                                            return data
                                                        }).map((item, index)=>(
                                                        <tr key={index.toString()}>
                                                            <td>{index+1}</td>
                                                            <td>{item?.first_name}</td>
                                                            <td>{item?.email}</td>
                                                            <td>{item?.mobile}</td>
                                                            <td>{moment(item.created_at).format("DD/MM/YYYY")}</td>
                                                            <td>
                                                                <div class="admin form-check form-switch">
                                                                    <input class="form-check-input" type="checkbox" checked={item.is_active} onClick={(e)=>callStatusChange(e,item)} role="switch" id="flexSwitchCheckDefault"/>
                                                                    <label class="form-check-label" for="flexSwitchCheckDefault" ></label>
                                                                    </div>
                                                                </td>
                                                            <td class=" admin text-center">
                                                                <button type="button" onClick={()=>setModalShow({show:true,id:item.id})} class=" admin btn btn-link text-danger ripple">Delete</button>
                                                            </td>
                                                        </tr>
                                                    )):(
                                                        <tr>
                                                            <td colSpan={7} align={"center"}> No data found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <ModalPage
                setModalShow={setModalShow}
                body={"Do you want to delete Seller"}
                title={"Are you sure"}
                class={"seller"}
                modalShow={modalShow.show}
            >
                <button className='delete-btn' onClick={()=>callDeleteSeller(modalShow.id)}>Delete</button>
                <button  className='cancel-btn' onClick={()=>setModalShow({show:false,id:""})}>Cancel</button>
            </ModalPage>

        </div>
    )
}
const mapStateToProps = (state) =>({
    access: state?.adminlogin?.access,
    searchInput:state?.searchInput?.search
})
export default connect(mapStateToProps)(SellerPage)