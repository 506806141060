import "./ChangepasswordPage.css";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getChangepassworddetail } from "../../../changepassword/util/changepassword";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LoaderPage from "../../loader/components/LoaderPage";

function ChangepasswordPage(props) {
  const passRegex =
  /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\w\W]){1,})(?!.*\s).{8,}$/;
  const [passwordInput, setPasswordInput] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [loadState, setLoadState] = useState(true)

  useEffect(() => {
    setTimeout(()=>setLoadState(false), 500)

    // eslint-disable-next-line
  }, []);
  const is_sp = props?.admin_data[0].user_type==="sp"?true:false;
  const { t } = useTranslation();
  const [passwordState, setPasswordState] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  })
  const [errors, setErrors] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

 
  const passwordInputchange = (e) => {
    setPasswordInput({
      ...passwordInput,
      [e.target.name]: e.target.value,
    });

    switch (e.target.name) {
      case "old_password":
        setErrors({
          ...errors,
          old_password: "",
        });
        break;

      case "new_password":
        setErrors({
          ...errors,
          new_password: "",
        });
        break;

      case "confirm_password":
        setErrors({
          ...errors,
          confirm_password: "",
        });
        break;

      default:
        break;
    }
  };

  const validation = () => {
    let checkValue = true;
   
    if (passwordInput.old_password === "") {
      errors.old_password =is_sp?t('pages.specialist.change.c-warn'): "Enter your old password";
      checkValue = false;
    }
    if (!passwordInput.new_password.match(passRegex)) {
      errors.new_password =is_sp? t("pages.change.n-password-valid"): "Your Password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase and 1 Numeric";
      checkValue = false;
    }
    if (passwordInput.new_password === "") {
      errors.new_password = is_sp?t('pages.specialist.change.new-warn'):"Enter your new password";
      checkValue = false;
    }
    if (passwordInput.confirm_password !== passwordInput.new_password) {
      errors.confirm_password = is_sp?t('pages.specialist.change.c-valid'):"Confirm password not match ";
      checkValue = false;
    }
   
    if (passwordInput.confirm_password === "") {
      errors.confirm_password = is_sp?t('pages.specialist.change.old-warn'):"Enter your confirm password ";
      checkValue = false;
    }
   

    setErrors({
      ...errors,
      old_password: errors.old_password,
      new_password: errors.new_password,
      confirm_password: errors.confirm_password,
    });

    if (checkValue === true) {
      return true;
    } else {
      return false;
    }
  };
 const callChangepassword =async (e) =>{
   e.preventDefault();
   if(validation()){
      let passworddetail = {
        old_password: passwordInput.old_password,
        new_password: passwordInput.new_password,
        confirm_password: passwordInput.confirm_password,
      };

      let getChangepasswordresponse = await getChangepassworddetail(
        passworddetail,
        props.access
      );
      if(getChangepasswordresponse.status===200){
        //console.log("res 201", await getChangepasswordresponse.json());
        toast.success(is_sp?t('pages.specialist.change.update-password'):"Your password updated successfully");
        setPasswordInput({
          old_password: "",
          new_password: "",
          confirm_password: "",
        });
      }else if(getChangepasswordresponse.status===400){
        const result = await getChangepasswordresponse.json()
        toast.error(result.old_password[0]);
      }
   }
 }


  if(loadState) 
    return <LoaderPage/>

  return (
    <div className="changepass-contents">
      <div class="admin content-page">
        <div class="admin content">
          <div class="admin container-fluid">
            <div class="admin row">
              <div class="admin col-12">
                <div class="admin card mt-2">
                  <div class="admin card-body">
                    <form onSubmit={callChangepassword}>
                      <div class="admin row">
                        <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label for="old_password" class="admin form-label">
                            {is_sp?t('pages.specialist.change.old-password'):"Old Password"} <span className="text-danger">*</span>
                          </label>
                          <div className="admin input-group">
                            <input
                              type={passwordState.old_password?"text":"password"}
                              id="old_password"
                              name="old_password"
                              class="admin form-control "
                              placeholder={is_sp?t('pages.specialist.change.old-password-place'):"Enter old password"}
                              value={passwordInput.old_password}
                              onChange={passwordInputchange}
                            />
                            <span onClick={()=>setPasswordState({...passwordState,old_password:!passwordState.old_password})} class="input-group-text" id="basic-addon1">
                              <i className={!passwordState.old_password?"fa fa-eye-slash":"fa fa-eye"}/>
                            </span>
                          </div>
                          <div class="text-danger input-warning">{errors.old_password}</div>
                        </div>
                        <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label for="new_password" class="admin form-label">
                            {is_sp?t('pages.specialist.change.new-password'):"New Password"} <span className="text-danger">*</span>
                          </label>
                          <div className="admin input-group">
                            <input
                              type={passwordState.new_password?"text":"password"}
                              id="new_password"
                              name="new_password"
                              class="admin form-control"
                              placeholder={is_sp?t('pages.specialist.change.new-password-place'):"Enter new password address"}
                              value={passwordInput.new_password}
                              onChange={passwordInputchange}
                            />
                           <span onClick={()=>setPasswordState({...passwordState,new_password:!passwordState.new_password})} class="input-group-text" id="basic-addon1">
                              <i className={!passwordState.new_password?"fa fa-eye-slash":"fa fa-eye"}/>
                            </span>
                          </div>
                          <div class="text-danger input-warning">{errors.new_password}</div>
                        </div>
                        <div class="admin mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label for="confirm_password" class="admin form-label">
                           {is_sp?t('pages.specialist.change.c-password'):" Confirm Password"} <span className="text-danger">*</span>
                          </label>
                          <div className="admin input-group">
                            <input
                              id="confirm_password"
                              name="confirm_password"
                              class="admin form-control"
                              placeholder={is_sp?t('pages.specialist.change.new-password-place'):"Enter your confirm password"}
                              value={passwordInput.confirm_password}
                              onChange={passwordInputchange}
                              type={passwordState.confirm_password?"text":"password"}
                            />
                           <span onClick={()=>setPasswordState({...passwordState,confirm_password:!passwordState.confirm_password})} class="input-group-text" id="basic-addon1">
                              <i className={!passwordState.confirm_password?"fa fa-eye-slash":"fa fa-eye"}/>
                            </span>
                          </div>
                          <div class="text-danger input-warning">{errors.confirm_password}</div>
                        </div>

                        <div class="admin mt-3 col-sm-6 text-left col-md-6 col-lg-6 col-xl-6">
                          <button
                            type="submit"
                            class="admin btn btn-warning mt-1 width-xl waves-effect waves-light"
                          >
                            {is_sp?t('pages.specialist.change.button'):"Update Password"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  admin_data: state?.adminlogin?.admin_data,
  access: state?.adminlogin?.access,
  language:state?.theme?.language,
  profile_url:state.adminlogin.profile_pic_base_url,
});

export default connect(mapStateToProps)(ChangepasswordPage);
