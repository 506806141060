import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./NotificationPage.css";
import notifiyellow from "../../../../assets/notifiyellow.png";
import { connect } from "react-redux";
import { getNotificationlist } from "../../../notification/util/getnotification";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getReadNotification } from "../util/getreadnotification";
import LoaderPage from "../../../admin/loader/components/LoaderPage";

function NotificationPage(props) {
  const [notificationlist, setNotificationlist] = useState([]);
  const [loadState, setLoadState] = useState(true)
  
  const { t } = useTranslation()
  useEffect(() => {
    notificationList();
    // eslint-disable-next-line
  }, []);

  const notificationList = async () => {
    let getNotificationlistresponse = await getNotificationlist(
      props?.access,
      props?.admin_data[0]?.id
    );
    if (getNotificationlistresponse?.status === 200) {
      let getResponse = await getNotificationlistresponse.json();
      console.log("notification response", getResponse);
      setNotificationlist(getResponse.reverse());
      const readNotiResponce = await getReadNotification(props.access)
        if(readNotiResponce.status===200){
          const resultRead = await readNotiResponce.json();
          if(resultRead?.data){
          }
        }else{
          const resultRead = await readNotiResponce.json();
          console.log(resultRead)
        }
    }
    setTimeout(()=>setLoadState(false), 1000)
  };

  const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return moment(date).format("DD/MM/YYYY HH:mm A");
    }

    interval = seconds / 2592000;

    if (interval > 1) {
      return moment(date).format("DD/MM/YYYY HH:mm A");
    }

    interval = seconds / 86400;

    if (interval > 1) {
      console.log("inter", interval);
      return moment(date).format("DD/MM/YYYY HH:mm A");
    }

    interval = seconds / 3600;

    if (interval > 1) {
      return props.language ==="Sp"?"face " + Math.floor(interval) + t('pages.hours-ago'):Math.floor(interval) + t('pages.hours-ago');
    }

    interval = seconds / 60;

    if (interval > 1) {
      return props.language ==="Sp"?"face " + Math.floor(interval) + t('pages.minutes-ago'):Math.floor(interval) + t('pages.minutes-ago');

    }

    return props.language ==="Sp"?"face " + Math.floor(seconds) + t('pages.seconds-ago'):Math.floor(seconds) + t('pages.seconds-ago');
 
  };
  if(loadState) 
  return <LoaderPage/>

  return (
    <div class=" admin content-page">
      <div class=" admin content">
        <div class=" admin container-fluid">
          <div class=" admin row">
            <div class=" admin col-12">
              <div className={"sp_notification"}>
                <Container>
                  {notificationlist?.map((item) => (
                    <div className={"noti-list-div"}>
                      <div className={"noti-list"}>
                        <div className="noti-left">
                          <div className={"noti-img"}>
                            <img src={notifiyellow} alt={"."} />
                          </div>
                          <div className="noti-info">
                            <label className="primaryTxt">
                              {item?.content}
                            </label>
                            <label className="secoundTxt"></label>
                          </div>
                        </div>
                        <div className="noti-right">
                          <label>{timeSince(new Date(item.created_at))}</label>
                        </div>
                      </div>
                    </div>
                  ))}
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  access: state?.adminlogin?.access,
  admin_data: state?.adminlogin?.admin_data,
  language:state?.theme?.language,
});

export default connect(mapStateToProps)(NotificationPage);
