import React, { useEffect, useState } from "react";
import "./AboutusPage.css";
import { connect } from "react-redux";
import { Container, Card } from "react-bootstrap";
import HeaderPage from "../../header/components/HeaderPage";
import FooterPage from "../../footer/components/FooterPage";
import about_1 from "./../../../assets/about_1.png";
import double_quotet from "./../../../assets/double-quote-serif-left.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { getAboutusdetail } from "../util/aboutus";
import draftjsToHtml from "draftjs-to-html";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

SwiperCore.use([Navigation]);

function AboutusPage(props) {
  const prnBtn = props.theme.primaryBtn;
  const navigation = useNavigate();
  const [aboutus, setAboutus] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    getAboutus();
    // eslint-disable-next-line
  }, []);

  const getAboutus = async () => {
    let getAboutusdetailresponse = await getAboutusdetail();
    let getResponse = await getAboutusdetailresponse.json();
    //console.log("response", getResponse);
    setAboutus(getResponse);
  };

  return (
    <>
      <HeaderPage logo={"black"} color={"black"} background={"#F7F7F7"} />
      <div className={"aboutus"}>
        <Container>
          <div className="aboutus-inners">
            <div className=" col-12 aboutus-content aboutus-details">
              <div className={"aboutus-div slideout"}>
                
                {aboutus.map((item, index) => (
                  <div key={index.toString()}>
                    <p className="title-secount" hidden>{props.language==="Sp"?item?.title_sp:item?.title}</p>
                    <h5 className="title">{props.language==="Sp"?item?.title_sp:item?.title}</h5>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: draftjsToHtml(JSON.parse(props.language==="Sp"?item?.description_sp:item?.description)),
                      }}
                    ></div>
                  </div>
                ))}

                <div className="contact-btn-div ">
                  <button className="contact-btn shadow-btn " onClick={()=>navigation('/contact')} style={prnBtn}>
                    {t('pages.about.button')}
                  </button>
                </div>
              </div>
            </div>
            <div hidden className="aboutus-content aboutus-img-details">
              <div className="img_section">
                <div className="squer_pic"></div>
                <div className="pic_1">
                  <img src={about_1} alt={"."} />
                </div>
              </div>
            </div>
          </div>
          {/* Hidden 4 boxs  */}
          <div hidden className="col-12 company-info ">
            <div className="customer-infos col-3 p-1">
              <div className="cust-info">
                <h4 className="primary">2012</h4>
                <h5 className="secoundry">{t('pages.about.boxs.found')}</h5>
              </div>
            </div>
            <div className="customer-infos col-3 p-1">
              <div className="cust-info">
                <h4 className="primary">125+</h4>
                <h5 className="secoundry">{t('pages.about.boxs.client')}</h5>
              </div>
            </div>
            <div className="customer-infos col-3 p-1">
              <div className="cust-info">
                <h4 className="primary">10+</h4>
                <h5 className="secoundry">{t('pages.about.boxs.experience')}</h5>
              </div>
            </div>
            <div className="customer-infos col-3 p-1">
              <div className="cust-info">
                <h4 className="primary">20</h4>
                <h5 className="secoundry">{t('pages.about.boxs.staff')}</h5>
              </div>
            </div>
          </div>

        </Container>
        <div className="abouts-footer">
          <div className={"ab-footer-innner"}>
            <h5 className="ab-footer-head">{t('pages.about.bottom-title')}</h5>
            <h5 className="footer-title-secound">
              {t('pages.about.bottom-subtitle')}
            </h5>
          </div>
          <Container>
            <Swiper
              className={"aboutus-carosoul desktop swiper-container-category"}
              modules={[Navigation, Pagination]}
              spaceBetween={10}
              slidesPerView={3}
              loop={true}
              navigation
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                <Card className={"cart-details"} style={{ width: "18rem" }}>
                  <Card.Body>
                    <div className={"cart-top"}>
                      <div className={"flex-row"}>
                        <Card.Img variant="top" src={about_1} />
                        <div className={"flex-col"}>
                          <Card.Title>Card Title</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <label className={"rate"}>5.0</label>
                          </Card.Subtitle>
                        </div>
                      </div>
                      <div className={"ab-top-right-div"}>
                        <img
                          src={double_quotet}
                          alt={"."}
                          className={"ab-top-right"}
                        />
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    Platea lorem, habitasse ac tincidunt mi. ipsum nostrud
                    aliquet eiusmod esse a sapien adipis cing mollit bibendum
                    orci neque. cillum duis ut in elit dolore esse curabitur
                    lorem, labore dolor diam cillum loremnt
                  </Card.Footer>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className={"cart-details"} style={{ width: "18rem" }}>
                  <Card.Body>
                    <div className={"cart-top"}>
                      <div className={"flex-row"}>
                        <Card.Img variant="top" src={about_1} />
                        <div className={"flex-col"}>
                          <Card.Title>Card Title</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <label className={"rate"}>5.0</label>
                          </Card.Subtitle>
                        </div>
                      </div>
                      <div className={"ab-top-right-div"}>
                        <img
                          src={double_quotet}
                          alt={"."}
                          className={"ab-top-right"}
                        />
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    Platea lorem, habitasse ac tincidunt mi. ipsum nostrud
                    aliquet eiusmod esse a sapien adipis cing mollit bibendum
                    orci neque. cillum duis ut in elit dolore esse curabitur
                    lorem, labore dolor diam cillum loremnt
                  </Card.Footer>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className={"cart-details"} style={{ width: "18rem" }}>
                  <Card.Body>
                    <div className={"cart-top"}>
                      <div className={"flex-row"}>
                        <Card.Img variant="top" src={about_1} />
                        <div className={"flex-col"}>
                          <Card.Title>Card Title</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <label className={"rate"}>5.0</label>
                          </Card.Subtitle>
                        </div>
                      </div>
                      <div className={"ab-top-right-div"}>
                        <img
                          src={double_quotet}
                          alt={"."}
                          className={"ab-top-right"}
                        />
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    Platea lorem, habitasse ac tincidunt mi. ipsum nostrud
                    aliquet eiusmod esse a sapien adipis cing mollit bibendum
                    orci neque. cillum duis ut in elit dolore esse curabitur
                    lorem, labore dolor diam cillum loremnt
                  </Card.Footer>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className={"cart-details"} style={{ width: "18rem" }}>
                  <Card.Body>
                    <div className={"cart-top"}>
                      <div className={"flex-row"}>
                        <Card.Img variant="top" src={about_1} />
                        <div className={"flex-col"}>
                          <Card.Title>Card Title</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <label className={"rate"}>5.0</label>
                          </Card.Subtitle>
                        </div>
                      </div>
                      <div className={"ab-top-right-div"}>
                        <img
                          src={double_quotet}
                          alt={"."}
                          className={"ab-top-right"}
                        />
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    Platea lorem, habitasse ac tincidunt mi. ipsum nostrud
                    aliquet eiusmod esse a sapien adipis cing mollit bibendum
                    orci neque. cillum duis ut in elit dolore esse curabitur
                    lorem, labore dolor diam cillum loremnt
                  </Card.Footer>
                </Card>
              </SwiperSlide>
            </Swiper>
            <Swiper
              className={"aboutus-carosoul mobile swiper-container-category"}
              modules={[Navigation, Pagination]}
              spaceBetween={10}
              slidesPerView={1}
              loop={true}
              navigation
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                <Card className={"cart-details"} style={{ width: "18rem" }}>
                  <Card.Body>
                    <div className={"cart-top"}>
                      <div className={"flex-row"}>
                        <Card.Img variant="top" src={about_1} />
                        <div className={"flex-col"}>
                          <Card.Title>Card Title</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <label className={"rate"}>5.0</label>
                          </Card.Subtitle>
                        </div>
                      </div>
                      <div className={"ab-top-right-div"}>
                        <img
                          src={double_quotet}
                          alt={"."}
                          className={"ab-top-right"}
                        />
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    Platea lorem, habitasse ac tincidunt mi. ipsum nostrud
                    aliquet eiusmod esse a sapien adipis cing mollit bibendum
                    orci neque. cillum duis ut in elit dolore esse curabitur
                    lorem, labore dolor diam cillum loremnt
                  </Card.Footer>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className={"cart-details"} style={{ width: "18rem" }}>
                  <Card.Body>
                    <div className={"cart-top"}>
                      <div className={"flex-row"}>
                        <Card.Img variant="top" src={about_1} />
                        <div className={"flex-col"}>
                          <Card.Title>Card Title</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <label className={"rate"}>5.0</label>
                          </Card.Subtitle>
                        </div>
                      </div>
                      <div className={"ab-top-right-div"}>
                        <img
                          src={double_quotet}
                          alt={"."}
                          className={"ab-top-right"}
                        />
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    Platea lorem, habitasse ac tincidunt mi. ipsum nostrud
                    aliquet eiusmod esse a sapien adipis cing mollit bibendum
                    orci neque. cillum duis ut in elit dolore esse curabitur
                    lorem, labore dolor diam cillum loremnt
                  </Card.Footer>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className={"cart-details"} style={{ width: "18rem" }}>
                  <Card.Body>
                    <div className={"cart-top"}>
                      <div className={"flex-row"}>
                        <Card.Img variant="top" src={about_1} />
                        <div className={"flex-col"}>
                          <Card.Title>Card Title</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <label className={"rate"}>5.0</label>
                          </Card.Subtitle>
                        </div>
                      </div>
                      <div className={"ab-top-right-div"}>
                        <img
                          src={double_quotet}
                          alt={"."}
                          className={"ab-top-right"}
                        />
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    Platea lorem, habitasse ac tincidunt mi. ipsum nostrud
                    aliquet eiusmod esse a sapien adipis cing mollit bibendum
                    orci neque. cillum duis ut in elit dolore esse curabitur
                    lorem, labore dolor diam cillum loremnt
                  </Card.Footer>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className={"cart-details"} style={{ width: "18rem" }}>
                  <Card.Body>
                    <div className={"cart-top"}>
                      <div className={"flex-row"}>
                        <Card.Img variant="top" src={about_1} />
                        <div className={"flex-col"}>
                          <Card.Title>Card Title</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <i className={"fa fa-star"} />
                            <label className={"rate"}>5.0</label>
                          </Card.Subtitle>
                        </div>
                      </div>
                      <div className={"ab-top-right-div"}>
                        <img
                          src={double_quotet}
                          alt={"."}
                          className={"ab-top-right"}
                        />
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    Platea lorem, habitasse ac tincidunt mi. ipsum nostrud
                    aliquet eiusmod esse a sapien adipis cing mollit bibendum
                    orci neque. cillum duis ut in elit dolore esse curabitur
                    lorem, labore dolor diam cillum loremnt
                  </Card.Footer>
                </Card>
              </SwiperSlide>
            </Swiper>
          </Container>
        </div>
      </div>
      <FooterPage />
    </>
  );
}
const mapStateToProps = (store) => ({
  theme: store.theme?.primaryTheme,
  language: store?.theme?.language
});
export default connect(mapStateToProps)(AboutusPage);
