import React, { useEffect, useState } from "react";
import "./HomePage.css";
import how_it_work_first from "../../../assets/how_it_work_first.png";
import how_it_work_second from "../../../assets/how_it_work_second.png";
import how_it_work_third from "../../../assets/how_it_work_third.png";
import feature_second from "../../../assets/home_search.svg";
import feature_third from "../../../assets/feature_third.svg";
import feature_five from "../../../assets/feature_five.svg";
import feature_six from "../../../assets/feature_six.svg";
import why_choose_us from "../../../assets/why_choose.jpg";
import subscribe_icon from "../../../assets/home_bottom_subscribe.png";
import user_five from "../../../assets/user_five.jpg";
import user_six from "../../../assets/user_six.jpg";
import user_three from "../../../assets/user_three.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Carousel, Container } from "react-bootstrap";
import HeaderPage from "../../header/components/HeaderPage";
import FooterPage from "../../footer/components/FooterPage";
import { getPackagelist } from "../util/packages";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storePackagelist } from "../redux/actions";
import { setShowpackagelist } from "../redux/actions";
import { useTranslation } from "react-i18next";
import { getSubscribedetatil } from "../util/subscribe";
import PackageinformationPage from "../../modal/components/PackageinformationPage";

SwiperCore.use([Navigation]);

function HomePage(props) {
  const {
    storePackagelist,
    access,
    packagelist,
    showPackagelist,
    setShowpackagelist,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();

  const [showpackageinfo, setShowpackageinfo] = useState(false);

  const [subscribe, setSubscribe] = useState({
    email: "",
    errorEmail: "",
  });

  const emailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

  //const [stateCheck, setStateCheck] = useState(1);

  useEffect(() => {
    if (location?.state?.loginFirst === true) {
      setShowpackagelist(true);
      toast.success(t('pages.dashboard.login-success'));
      navigate(location.pathname, { replace: true });
    }
    fetchPackagelist();
    // eslint-disable-next-line
  }, []);

  const fetchPackagelist = async () => {
    let getPackagelistresponse = await getPackagelist(access);
    if (getPackagelistresponse.status === 200) {
      let getPackagelist = await getPackagelistresponse.json();
      let getPackage = {};
      let updateList = [];
      getPackagelist.forEach((item, index) => {
        getPackage = {
          amount: item.amount,
          created_at: item.created_at,
          description: item.description,
          description_sp: item.description_sp,
          id: item.id,
          status: item.status,
          title: item.title,
          title_sp: item.title_sp,
          updated_at: item.updated_at,
          is_checked: item.status ? (index === 0 ? true : false) : false,
        };
        updateList.push(getPackage);
      });

      storePackagelist(updateList);
    }
  };

  const selectPackagefromlist = (e, getindex) => {
    let updateList = [];
    packagelist.forEach((item, index) => {
      if (index === getindex) {
        if (packagelist[index].is_checked === true) {
          packagelist[index].is_checked = false;
          updateList.push(packagelist[index]);
        } else {
          packagelist[index].is_checked = true;
          updateList.push(packagelist[index]);
        }
      } else {
        packagelist[index].is_checked = false;
        updateList.push(packagelist[index]);
      }
    });

    storePackagelist(updateList);
  };

  const callDetailpage = () => {
    setShowpackageinfo(true);
  };

  const subscribeInputchange = (e) => {
    setSubscribe({
      ...subscribe,
      email: e.target.value,
      errorEmail: "",
    });
  };

  const subscribeClick = async () => {
    if (subscribe.email === "") {
      setSubscribe({
        ...subscribe,
        email: "",
        errorEmail: t("pages.login.email-warn"),
      });
    } else if (!subscribe.email.match(emailRegex)) {
      setSubscribe({
        ...subscribe,
        email: "",
        errorEmail: t("pages.login.email-valid"),
      });
    } else {
      let subscribeDetail = {
        email: subscribe.email,
      };

      let getSubscribedetatilresponse = await getSubscribedetatil(
        subscribeDetail
      );

      if (getSubscribedetatilresponse.status === 201) {
        setSubscribe({
          ...subscribe,
          email: "",
          errorEmail: "",
        });
        toast.success(t('pages.dashboard.subscrib-success'));
      }
    }
  };

  return (
    <>
      <HeaderPage
        background={showPackagelist === true ? "#F7F7F7" : "transparent"}
        color={showPackagelist === true ? "black" : "white"}
        logo={showPackagelist === true ? "black" : "white"}
      />

      <section id={showPackagelist === true ? "slider d-none" : "slider"}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-13 col-lg-6 col-xl-4">
              <div
                className={
                  showPackagelist === true
                    ? "slider_content d-none"
                    : "slider_content"
                }
              >
                <h3 className="wow bounceInLeft" data-wow-duration="2s">
                  <p>{t("pages.dashboard.title1")}</p>
                  <p>{t("pages.dashboard.title2")} </p>
                  <p>{t("pages.dashboard.title3")} </p>
                  <span>
                    <p>{t("pages.dashboard.title4")}</p>
                    <p>{t("pages.dashboard.title5")}</p>
                  </span>
                </h3>
                <button
                  type="button"
                  className="btn-more wow bounceIn"
                  data-wow-duration="1s"
                  onClick={() => navigate("about")}
                >
                  {t("pages.dashboard.title-btn")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Plans Packages */}
      <div className={showPackagelist === true ? "package-login" : ""}>
        <section id={"packages"}>
          <div className="container-fluid">
            <div className="row">
              <h2 className="wow fadeInDown">{t("pages.dashboard.plan")}</h2>
              <div className="packages-list-div">
                {packagelist?.map((item, index) => (
                  <div
                    key={index.toString()}
                    className={"col-sm-12 col-md-6 col-lg-3 col-xl-3 "}
                  >
                    <div
                      className={
                        item.status
                          ? "package rdio rdio-primary radio-inline enabled"
                          : "package rdio rdio-primary radio-inline disabled"
                      }
                    >
                      <input
                        name="payment_type"
                        id={item.id}
                        type="radio"
                        checked={item.is_checked ? true : false}
                        onChange={(e) =>
                          item.status
                            ? item.is_checked
                              ? null
                              : selectPackagefromlist(e, index)
                            : null
                        }
                      />
                      <label htmlFor={item.id}>
                        <h5>
                          {item.status
                            ? props.language === "Sp"
                              ? item.title_sp
                              : item.title
                            : t("pages.dashboard.coming-soon")}
                        </h5>
                        <h4
                          style={{
                            visibility: item.status ? "visible" : "hidden",
                          }}
                        >
                          {item.amount}
                        </h4>
                        <hr />
                        <h6>
                          {" "}
                          {props.language === "Sp" ? item.title_sp : item.title}
                        </h6>
                        <div
                          style={{
                            visibility: item.status ? "visible" : "visible",
                          }}
                          className="process-details"
                        >
                          {props.language === "Sp"
                            ? item.description_sp
                            : item.description}
                        </div>
                        <div className="flex-center">
                          <button
                            className="btn-package"
                            onClick={(e) =>
                              item.status ? callDetailpage(e) : null
                            }
                          >
                            {item.status
                              ? t("pages.dashboard.select-remove")
                              : t("pages.dashboard.select")}
                          </button>
                        </div>
                      </label>
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-sm-12 text-center">
                <button
                  onClick={callDetailpage}
                  type="button"
                  className="btn-common wow bounceIn"
                >
                  {t("pages.dashboard.procced")}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* How it work */}
      <div className={showPackagelist === true ? "d-none" : "home-content"}>
        {/* Hidden How It Works Divs */}
        <section hidden id={"how_it_work"}>
          <div className="container">
            <div className="row">
              <h2 className="wow fadeInDown">
                {t("pages.dashboard.work-title")}
              </h2>
              <Container>
                <Swiper
                  modules={[Navigation]}
                  spaceBetween={20}
                  slidesPerView={3}
                  className="work-div desktop"
                >
                  <SwiperSlide className="workdiv-inner">
                    <div className="steps">
                      <img src={how_it_work_first} alt="" />
                      <h5>{t("pages.dashboard.work.title")}</h5>
                      <p>{t("pages.dashboard.work.subtitle")}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="workdiv-inner">
                    <div className="steps">
                      <img src={how_it_work_second} alt="" />
                      <h5>{t("pages.dashboard.work.title")}</h5>
                      <p>{t("pages.dashboard.work.subtitle")}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="workdiv-inner">
                    <div className="steps">
                      <img src={how_it_work_third} alt="." />
                      <h5>{t("pages.dashboard.work.title")}</h5>
                      <p>{t("pages.dashboard.work.subtitle")}</p>
                    </div>
                  </SwiperSlide>
                  {/* </div> */}
                </Swiper>
                <Carousel className="work-div mobile">
                  <Carousel.Item className="workdiv-inner">
                    <div className="steps">
                      <img src={how_it_work_first} alt="" />
                      <h5>{t("pages.dashboard.work.title")}</h5>
                      <p>{t("pages.dashboard.work.subtitle")}</p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item className="workdiv-inner">
                    <div className="steps">
                      <img src={how_it_work_second} alt="" />
                      <h5>{t("pages.dashboard.work.title")}</h5>
                      <p>{t("pages.dashboard.work.subtitle")}</p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item className="workdiv-inner">
                    <div className="steps">
                      <img src={how_it_work_third} alt="" />
                      <h5>{t("pages.dashboard.work.title")}</h5>
                      <p>{t("pages.dashboard.work.subtitle")}</p>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </Container>
            </div>
          </div>
        </section>
        {/* Why choose us */}
        <section id="why_choose_us">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 text-center">
                <img
                  src={why_choose_us}
                  className="left-img wow bounceInLeft"
                  data-wow-duration="2.5s"
                  alt="."
                />
              </div>
              <div
                className="col-sm-12 col-md-12 col-lg-6 col-xl-5 wow "
                data-wow-duration="2.5s"
              >
                <h2>{t("pages.dashboard.why.title")}</h2>
                <div className="row">
                  <div className="col-12 ">
                    <p className="text-primary">
                      {t("pages.dashboard.why.location")}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="feature">
                      <img src={feature_six} alt="." />
                      <div className="content-panel">
                        <h5> {t("pages.dashboard.why.call-title")}</h5>
                        <p>{t("pages.dashboard.why.call-subtitle")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="feature">
                      <img src={feature_second} alt="." />
                      <div className="content-panel">
                        <h5>{t("pages.dashboard.why.location-title")}</h5>
                        <p>{t("pages.dashboard.why.location-subtitle")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <p className="text-primary">
                      {t("pages.dashboard.why.best")}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="feature">
                      <img src={feature_third} alt="." />
                      <div className="content-panel">
                        <h5>{t("pages.dashboard.why.price-title")}</h5>
                        <p>{t("pages.dashboard.why.price-subtitle")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="feature">
                      <img src={feature_five} alt="." />
                      <div className="content-panel">
                        <h5>{t("pages.dashboard.why.experience-title")}</h5>
                        <p>{t("pages.dashboard.why.experience-subtitle")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Subscribe */}
        <section id="subscribe">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4 offset-xl-1 offset-md-3 wow ">
                <h3>{t("pages.dashboard.subscrib.title")}</h3>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={`${t("pages.dashboard.subscrib.input")}`}
                    value={subscribe.email}
                    onChange={(e) => subscribeInputchange(e)}
                  />
                  <button
                    className="btn btn-subscribe"
                    type="button"
                    id=""
                    onClick={() => subscribeClick()}
                  >
                    {t("pages.dashboard.subscrib.button")}
                  </button>
                </div>
                <div className="text-danger">{subscribe.errorEmail}</div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-6 offset-xl-1 text-end">
                <img
                  src={subscribe_icon}
                  className="right-img wow "
                  data-wow-duration="2.5s"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section id="testimonial">
          <div className="container">
            <div className="row">
              <h2>{t("pages.dashboard.about.title")}</h2>
              <p className="sub-text">{t("pages.dashboard.about.subtitle")}</p>

              <Swiper
                className="home-wrapper desktop"
                loop={true}
                modules={[Navigation, Pagination]}
                spaceBetween={20}
                slidesPerView={3}
                navigation={true}
              >
                <SwiperSlide>
                  <div className="content-wrapper">
                    <div className="content">
                      <div className="swiper-avatar">
                        <img src={user_three} alt="" />
                      </div>
                      <div className="user_details">
                        <div className="name">Juliet Elliott</div>
                        <div className="rating">
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i> <span>5.0</span>
                        </div>
                      </div>
                      <img
                        src="images/double-quote-serif-left.png"
                        className="quote"
                        alt=""
                      />
                    </div>
                    <p>
                      Platea lorem, habitasse ac tincidunt mi. ipsum nostrud
                      aliquet eiusmod esse a sapien adipis cing mollit bibendum
                      orci neque. cillum duis ut in elit dolore esse curabitur
                      lorem, labore dolor diam cillum loremnt
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="content-wrapper">
                    <div className="content">
                      <div className="swiper-avatar">
                        <img src={user_five} alt="" />
                      </div>
                      <div className="user_details">
                        <div className="name">Juliet Elliott</div>
                        <div className="rating">
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i> <span>5.0</span>
                        </div>
                      </div>
                      <img
                        src="images/double-quote-serif-left.png"
                        className="quote"
                        alt=""
                      />
                    </div>
                    <p>
                      Platea lorem, habitasse ac tincidunt mi. ipsum nostrud
                      aliquet eiusmod esse a sapien adipis cing mollit bibendum
                      orci neque. cillum duis ut in elit dolore esse curabitur
                      lorem, labore dolor diam cillum loremnt
                    </p>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="content-wrapper">
                    <div className="content">
                      <div className="swiper-avatar">
                        <img src={user_six} alt="" />
                      </div>
                      <div className="user_details">
                        <div className="name">Juliet Elliott</div>
                        <div className="rating">
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i> <span>5.0</span>
                        </div>
                      </div>
                      <img
                        src="images/double-quote-serif-left.png"
                        className="quote"
                        alt=""
                      />
                    </div>
                    <p>
                      Platea lorem, habitasse ac tincidunt mi. ipsum nostrud
                      aliquet eiusmod esse a sapien adipis cing mollit bibendum
                      orci neque. cillum duis ut in elit dolore esse curabitur
                      lorem, labore dolor diam cillum loremnt
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="content-wrapper">
                    <div className="content">
                      <div className="swiper-avatar">
                        <img src={user_three} alt="" />
                      </div>
                      <div className="user_details">
                        <div className="name">Juliet Elliott</div>
                        <div className="rating">
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i> <span>5.0</span>
                        </div>
                      </div>
                      <img
                        src="images/double-quote-serif-left.png"
                        className="quote"
                        alt=""
                      />
                    </div>
                    <p>
                      Platea lorem, habitasse ac tincidunt mi. ipsum nostrud
                      aliquet eiusmod esse a sapien adipis cing mollit bibendum
                      orci neque. cillum duis ut in elit dolore esse curabitur
                      lorem, labore dolor diam cillum loremnt
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="content-wrapper">
                    <div className="content">
                      <div className="swiper-avatar">
                        <img src={user_six} alt="" />
                      </div>
                      <div className="user_details">
                        <div className="name">Juliet Elliott</div>
                        <div className="rating">
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i>{" "}
                          <i className="bi bi-star-fill"></i> <span>5.0</span>
                        </div>
                      </div>
                      <img
                        src="images/double-quote-serif-left.png"
                        className="quote"
                        alt=""
                      />
                    </div>
                    <p>
                      Platea lorem, habitasse ac tincidunt mi. ipsum nostrud
                      aliquet eiusmod esse a sapien adipis cing mollit bibendum
                      orci neque. cillum duis ut in elit dolore esse curabitur
                      lorem, labore dolor diam cillum loremnt
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
              <Swiper
                className="home-wrapper mobile"
                loop={true}
                modules={[Navigation, Pagination]}
                spaceBetween={20}
                slidesPerView={1}
                navigation={true}
              >
                <SwiperSlide>
                  <div className="feedback">
                    <div className="content-wrapper">
                      <div className="content">
                        <div className="swiper-avatar">
                          <img src={user_three} alt="" />
                        </div>
                        <div className="user_details">
                          <div className="name">Juliet Elliott</div>
                          <div className="rating">
                            <i className="bi bi-star-fill"></i>{" "}
                            <i className="bi bi-star-fill"></i>{" "}
                            <i className="bi bi-star-fill"></i>{" "}
                            <i className="bi bi-star-fill"></i>{" "}
                            <i className="bi bi-star-fill"></i> <span>5.0</span>
                          </div>
                        </div>
                        <img
                          src="images/double-quote-serif-left.png"
                          className="quote"
                          alt=""
                        />
                      </div>
                      <p>
                        Platea lorem, habitasse ac tincidunt mi. ipsum nostrud
                        aliquet eiusmod esse a sapien adipis cing mollit
                        bibendum orci neque. cillum duis ut in elit dolore esse
                        curabitur lorem, labore dolor diam cillum loremnt
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>
        <PackageinformationPage
          showpackageinfo={showpackageinfo}
          setShowpackageinfo={setShowpackageinfo}
        />
        <FooterPage />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  access: state?.login?.access,
  packagelist: state?.home?.packagelist,
  showPackagelist: state?.home?.showPackagelist,
  language: state?.theme?.language,
});

export default connect(mapStateToProps, {
  storePackagelist,
  setShowpackagelist,
})(HomePage);
