import { ADMIN_LOGIN_SUCCESS } from "./actionTypes";

export const fetchAdminLogindata =
  (access, admin_data, profile_pic_base_url) => async (dispatch) => {
    dispatch({
      type: ADMIN_LOGIN_SUCCESS,
      access: access,
      admin_data: admin_data,
      profile_pic_base_url: profile_pic_base_url,
    });
  };
