import React, { useEffect, useState } from "react";
import "./NotificationPage.css";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
//import notifiorange from "./../../../assets/notifiorange.png";
import notifiyellow from "./../../../assets/notifiyellow.png";
import { getNotificationlist } from "../util/getnotification";
import { useTranslation } from "react-i18next";
import moment from "moment";

function NotificationPage(props) {
  const [notificatiolist, setNotificationlist] = useState([]);
  const [nodata, setNodata] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.tabkey === "notification") {
      getNotification();
    }
    // eslint-disable-next-line
  }, [props.tabkey]);

  const getNotification = async () => {
    let getNotificationlistresponse = await getNotificationlist(
      props.access,
      props.userId
    );
    if (getNotificationlistresponse.status === 200) {
      let getNotificationlist = await getNotificationlistresponse.json();
    //  console.log("notificationlist", getNotificationlist);
      if (getNotificationlist.length > 0) {
        setNodata(false);
        setNotificationlist(getNotificationlist.reverse());
      } else {
        setNodata(true);
      }
    } else if (getNotificationlistresponse.status === 401) {
    } else if (getNotificationlistresponse.status === 404) {
      setNodata(true);
    }
  };

  const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return  moment(date).format("DD/MM/YYYY MM:SS A");
    }

    interval = seconds / 2592000;

    if (interval > 1) {
      return  moment(date).format("DD/MM/YYYY MM:SS A");
    }

    interval = seconds / 86400;

    if (interval > 1) {
     // console.log("inter", interval);
      return  moment(date).format("DD/MM/YYYY MM:SS A");
    }

    interval = seconds / 3600;


    if (interval > 1) {
      return props.language ==="Sp"?"face " + Math.floor(interval) + t('pages.hours-ago'):Math.floor(interval) + t('pages.hours-ago');
    }

    interval = seconds / 60;

    if (interval > 1) {
      return props.language ==="Sp"?"face " + Math.floor(interval) + t('pages.minutes-ago'):Math.floor(interval) + t('pages.minutes-ago');

    }

    return props.language ==="Sp"?"face " + Math.floor(seconds) + t('pages.seconds-ago'):Math.floor(seconds) + t('pages.seconds-ago');
 };

  return (
    <div className={"notification"}>
      {nodata ? (
        <div className="nodata">
          <h5>{t("pages.no-data")}</h5>
        </div>
      ) : (
        <Container>
          <div className={"noti-list-div"}>
            {notificatiolist.map((item, index) => (
              <div  key={index.toString()} className={"noti-list"}>
                <div className="noti-left">
                  <div className={"noti-img"}>
                    <img src={notifiyellow} alt={"."} />
                  </div>
                  <div className="noti-info">
                    <label className="primaryTxt">{item.content}</label>
                    <label className="secoundTxt"></label>
                  </div>
                </div>
                <div className="noti-right">
                  <label>
                    {
                      props.language==="Sp"?timeSince(new Date(item.created_at)):timeSince(new Date(item.created_at))
                    }
                  </label>
                </div>
              </div>
            ))}
          </div>
        </Container>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  access: state?.login?.access,
  userId: state?.login?.userId,
  language:state?.theme?.language,
});
export default connect(mapStateToProps)(NotificationPage);
