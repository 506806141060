import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import "./RequestrejectPage.css";

function PostnotificationPage(props) {

  const closePushnotification = () => {
    props.setShowRequestReject(false);
  };

  //console.log(props)

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showRequestReject}
      className="request-reject-modal"
      onHide={() => closePushnotification()}
    >
      <Modal.Body>
        {props?.children}
      </Modal.Body>
    </Modal>
  );
}
const mapStateToProps = (state) => ({
  access: state?.adminlogin?.access,
  admin_data: state?.adminlogin?.admin_data,
});
export default connect(mapStateToProps)(PostnotificationPage);
