export const getPrivacy = async () => {
    try {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      const getResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/footer-pages/privacy-policy`,
        {
          headers,
          method: "GET",
        }
      );
  
      return getResponse;
    } catch (error) {}
};
  