import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Page404() {
    const navigation = useNavigate()
    useEffect(()=>{
        navigation("/")
    // eslint-disable-next-line
    },[])
  return (
    <div>404Page</div>
  )
}
