import React, { useEffect, useState } from 'react'
import "./PrivacyPolicy.css";
import { Container } from 'react-bootstrap';
import HeaderPage from '../../header/components/HeaderPage';
import FooterPage from '../../footer/components/FooterPage';
import { getPrivacydetail } from '../util/privacy';
import draftjsToHtml from 'draftjs-to-html';
import { connect } from 'react-redux';


 function PrivacyPolicy(props) {
    const [policyList,setprivacyList] = useState([]);
    useEffect(()=>{
        callGetPrivacyData()
        // eslint-disable-next-line
    },[])
    const callGetPrivacyData = async () =>{
        const responceAbout = await getPrivacydetail();
        if(responceAbout.status === 200){
            const resultAbout = await responceAbout.json();
            setprivacyList(resultAbout)
        }
    }
  return (
    <aside>
        <HeaderPage background={"#F7F7F7"} color={"black"} logo={"black"} />
        <div className='privacy'>
            <Container>
                {policyList.map((item, index)=>(
                    <div key={index.toString()}>
                        <h3>{props.language==="Sp"?item.title_sp:item.title}</h3>
                        <div dangerouslySetInnerHTML={{__html:draftjsToHtml(JSON.parse(props.language==="Sp"?item.description_sp:item.description))}}></div>
                    </div>
                ))
                    
                }
            </Container>
        </div>
        <FooterPage/>
    </aside>
  )
}
const mapStateToProps = (state) =>({
    language: state?.theme?.language
})
export default connect(mapStateToProps)(PrivacyPolicy);