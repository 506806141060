import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import "./PostnotificationPage.css";
import { toast } from "react-toastify";
import { getPostnotificationdetails } from "../util/postnotification";

function PostnotificationPage(props) {
  const [contentInput, setContentinput] = useState({
    content: "",
  });

  const closePushnotification = () => {
    props.setShownotification(false);
  };

  const contentChange = (e) => {
    setContentinput({
      ...contentInput,
      [e.target.name]: e.target.value,
    });
  };

  const submitContent = async () => {
    let user_id = props?.admin_data[0]?.id;
    user_id = user_id.toString();

    let postDetail = {
      from_id: user_id,
      to_id: props?.specialistId,
      content: contentInput?.content,
    };

   // console.log("Post detail", postDetail, props?.admin_data);

    let getFeedbackdetailresponse = await getPostnotificationdetails(
      postDetail,
      props.access
    );
   // console.log("notification", getFeedbackdetailresponse);
    if (getFeedbackdetailresponse.status === 201) {
      toast.success("Your notification send successfully");
      props.setShownotification(false);
      setContentinput({
        content: "",
      });
    }
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.shownotification}
      className="review-modal"
      onHide={() => closePushnotification()}
    >
      <Modal.Body>
        <div className="review">
          <div className="review-header">
            <h5>Send notification</h5>
          </div>
          <div>
            <Form.Group className="form-group">
              <Form.Control
                type="text"
                placeholder="Tell us content…"
                as="textarea"
                rows="5"
                value={contentInput.content}
                name="content"
                onChange={(e) => contentChange(e)}
              ></Form.Control>
            </Form.Group>
          </div>
          <Button className="review-btn" onClick={() => submitContent()}>
            SEND
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
const mapStateToProps = (state) => ({
  access: state?.adminlogin?.access,
  admin_data: state?.adminlogin?.admin_data,
});
export default connect(mapStateToProps)(PostnotificationPage);
