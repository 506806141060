import { SEARCH_INPUT_STATE_CHANGE } from "./actionTypes";

const searchloginState = {
  search: "",
};

export const searchReducer = (state = searchloginState, action) => {
  switch (action.type) {
    case SEARCH_INPUT_STATE_CHANGE:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};
export default searchReducer;
