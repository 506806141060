import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getComponyInfo } from '../../../contactus/util/getcompany';
import LoaderPage from '../../loader/components/LoaderPage';
import { addComponydetails } from '../util/addcompanydetails';
import { UpdateCompanyinfo } from '../util/updatecompanydetail';
import "./AddcomponyinfoPage.css";

function AddcomponyinfoPage(props) {
    const [addcompanyInput,setAddcompanyInput] = useState({
        email:"",
        address:"",
        mobile:""
    });
    const emailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    const [loadState, setLoadState] = useState(true)

    const [errors, setErrors] = useState({
        email:"",
        mobile:"",
        address:""
    })
    const [companyStatus, setCompanyStatus] = useState('Save');
    
    useEffect(()=>{
        callGetCompanyInfo()
        // eslint-disable-next-line
    },[])

    const callGetCompanyInfo = async ()=>{
        const responceInfo = await getComponyInfo();
        if(responceInfo.status===200){
            const resultInfo = await responceInfo.json();
            setAddcompanyInput({
                email:resultInfo[0]?.email,
                mobile:resultInfo[0]?.mobile,
                address:resultInfo[0]?.address,
                id:resultInfo[0]?.id,
            })
            setCompanyStatus("Update")
        }else{
            await responceInfo.json();
            //console.log(resultInfo);
            setCompanyStatus("Save");
        }
        setTimeout(()=>setLoadState(false), 100)

    }
    const callAddCompanyInfo = async (e) =>{
        e.preventDefault();
        if(validation()){
            const data = {
                email:addcompanyInput.email,
                address:addcompanyInput.address,
                mobile:addcompanyInput.mobile,
            }
        const responceFAQ = await addComponydetails(props.access,data);
            if(responceFAQ.status===200){
                await responceFAQ.json();
                //console.log(resultFAQ);
                setAddcompanyInput({
                    email:"",
                    address:"",
                    mobile:""
                })
            }
        }else{
            toast.error("");
        }
    }
    const callUpdateCompanyinfo = async (e) =>{
        e.preventDefault();
        if(validation()){
            const data = {
                email:addcompanyInput.email,
                address:addcompanyInput.address,
                mobile:addcompanyInput.mobile,
                id:addcompanyInput.id,
            }
            const responceInfo = await UpdateCompanyinfo(props.access,data);
            if(responceInfo.status===200){
                await responceInfo.json();
                //console.log(resultInfo);
                setErrors({
                    email:"",
                    mobile:"",
                    address:""
                })
            }
        }
    }
    const callChangeInput = (e)=>{
      setAddcompanyInput({
          ...addcompanyInput,
          [e.target.name]:e.target.value
      })
      setErrors({
          ...errors,
          [e.target.name]:""
      })
    }
    const validation = () => {
        let checkValue = true;
        if(addcompanyInput.email===""){
           
                errors.email="Enter company email"
            checkValue = false;
        }else{
            if(!addcompanyInput.email.match(emailRegex)){
                errors.email="Enter valid company email"
                checkValue = false;
            }   
        }
        if(addcompanyInput.address===""){
                errors.address="Enter company address"
            checkValue = false;
        }
        if(addcompanyInput.mobile===""){
                errors.mobile="Enter company mobile"
            checkValue = false;
        }
        setErrors({
            ...errors,
            email:errors.email,
            address:errors.address,
            mobile:errors.mobile
        })
        return checkValue
    }

  if(loadState) 
      return <LoaderPage/>

  return (
    <div className='addcompany-contents'>
       <div class="admin content-page">
            <div class="admin content">
                <div class="admin container-fluid">
                    <div class="admin row">
                        <div class="admin col-12">
                            <div class="admin card mt-2">
                                <div class="admin card-body">
                                   
                                    <form onSubmit={companyStatus==="Save"?callAddCompanyInfo:callUpdateCompanyinfo}>
                                        <h4 class=" admin mt-3 mb-2 header-title mt-2">{companyStatus==="Save"?"Add":"Update"} Company Info</h4>
                                            <div class="admin row">
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="email" class="admin form-label">Company Email</label>
                                                    <input value={addcompanyInput.email} onChange={callChangeInput} type="text" id="email" name="email" class="admin form-control" placeholder="Enter official email... " />
                                                    <span className='text-danger'>{errors.email}</span>
                                                </div>
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="address" class="admin form-label">Company Address</label>
                                                    <input rows={5} value={addcompanyInput.address} onChange={callChangeInput} type="text" id="address" name="address" class="admin form-control" placeholder="Enter company address" />
                                                    <span className='text-danger'>{errors.address}</span>
                                                </div>
                                                <div class="admin mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <label for="mobile" class="admin form-label">Company Mobile</label>
                                                    <input rows={5} value={addcompanyInput.mobile} onChange={callChangeInput} type="text" id="mobile" name="mobile" class="admin form-control" placeholder="Enter company mobile no"/>
                                                    <span className='text-danger'>{errors.mobile}</span>
                                                </div>
                                            </div>
                                       
                                        <div class="admin mt-3 col-sm-12 text-left col-md-12 col-lg-12 col-xl-12">
                                            <button type="submit" class="admin btn btn-warning mt-1 width-xl waves-effect waves-light">{companyStatus}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
const mapStateToProps = (store) =>({
    access:store?.adminlogin?.access
})
export default connect(mapStateToProps)(AddcomponyinfoPage);