import { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Outlet,
} from "react-router-dom";
import LoginPage from "./modules/login/components/LoginPage";
import RegisterPage from "./modules/register/components/RegisterPage";
import ChangepasswordPage from "./modules/changepassword/components/ChangepasswordPage";
import ContactusPage from "./modules/contactus/components/ContactusPage";
import HomePage from "./modules/home/components/HomePage";
import ForgotpasswordPage from "./modules/forgotpassword/components/ForgotpasswordPage";
import MyaccountPage from "./modules/myaccount/components/MyaccountPage";
import AboutusPage from "./modules/aboutus/components/AboutusPage";
import Termsandcondition from "./modules/termsandconditions/components/Termsandcondition";
import PrivacyPolicy from "./modules/privacypolicy/components/PrivacyPolicy";
import FaqsPage from "./modules/faq/components/FaqsPage";
import SpecialistmapPage from "./modules/specialistmap/components/SpecialistmapPage";
import MoredetailPage from "./modules/details/components/MoredetailPage";
import CarddetailPage from "./modules/details/components/CarddetailPage";
import { PersistGate } from "redux-persist/es/integration/react";
import store, { persistor } from "./store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LandingPage from "./modules/admin/landing/components/LandingPage";
import DashboardPage from "./modules/admin/dashboard/components/DashboardPage";
import SpecialistPage from "./modules/admin/specialist/components/SpecialistPage";
import Adminrequests from "./modules/admin/request/components/RequestPage.js";
import BuyerPage from "./modules/admin/buyer/components/BuyerPage.js";
import SellerPage from "./modules/admin/seller/components/SellerPage";
import AddspecialistPage from "./modules/admin/addspecialist/components/AddspecialistPage";
import AdminAboutus from "./modules/admin/aboutus/components/AboutusPage";
import RequestdetailPage from "./modules/admin/request/components/RequestdetailPage";
import AdminLoginPage from "./modules/admin/login/components/LoginPage";
import AdminFaqs from "./modules/admin/addfaq/components/AddfaqPage";
import AddprivacyPage from "./modules/admin/addprivacypolicy/components/AddprivacyPage";
import AddtermsPage from "./modules/admin/addterms/components/AddtermsPage";
import AddcomponyinfoPage from "./modules/admin/addcompanyinfo/components/AddcomponyinfoPage";
import AddminmyaccountPage from "./modules/admin/myaccount/components/MyaccountPage";
import AdminChangepasswordPage from "./modules/admin/changepassword/component/ChangepasswordPage";

// specialists
import SpdashboardPage from "./modules/specialists/dashboard/components/DashboardPage";
import NotificationPage from "./modules/specialists/notifications/components/NotificationPage";
import SendnotificationPage from "./modules/admin/notification/components/SendnotificationPage";
import SpecialistdetailPage from "./modules/admin/specialist/components/SpecialistdetailPage";
import FeedbackPage from "./modules/specialists/feedback/components/FeedbackPage";
import Loginexpire from "./modules/loginexpire/components/Loginexpire";
import Page404 from "./modules/404/components/404Page";

export default function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, [pathname]);
    return null;
  };

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <ToastContainer></ToastContainer>
          <Loginexpire/>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forgot" element={<ForgotpasswordPage />} />
            <Route path="/my-account" element={<MyaccountPage />}>
              <Route path=":id" element={<MyaccountPage />} />
            </Route>
            <Route path="/about" element={<AboutusPage />} />
            <Route path="/terms" element={<Termsandcondition />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/faq" element={<FaqsPage />} />
            <Route path="/contact" element={<ContactusPage />} />
            <Route path="/detail" element={<Outlet />}>
              <Route path="map" element={<SpecialistmapPage />} />
              <Route path="car" element={<MoredetailPage />} />
              <Route path="card" element={<CarddetailPage />} />
            </Route>
            <Route path="/changepassword" element={<ChangepasswordPage />} />

            <Route exact path="/bc-admin" element={<LandingPage />}>
              <Route index element={<AdminLoginPage />} />
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="request" element={<Outlet />}>
                <Route index element={<Adminrequests />} />
                <Route path=":id" element={<RequestdetailPage />} />
              </Route>
              <Route path="buyer" element={<BuyerPage />} />
              <Route path="seller" element={<SellerPage />} />
              <Route path="my-account" element={<AddminmyaccountPage />} />
              <Route
                path="change-password"
                element={<AdminChangepasswordPage />}
              />
              <Route path="pages" element={<Outlet />}>
                <Route path="aboutus" element={<AdminAboutus />} />
                <Route path="faq" element={<AdminFaqs />} />
                <Route path="privacy" element={<AddprivacyPage />} />
                <Route path="terms-condition" element={<AddtermsPage />} />
                <Route path="company-info" element={<AddcomponyinfoPage />} />
              </Route>
              <Route path="specialist" element={<Outlet />}>
                <Route index element={<SpecialistPage />} />
                <Route path="add" element={<AddspecialistPage />} />
                <Route path="edit/:id" element={<AddspecialistPage />} />
                <Route path=":id" element={<SpecialistdetailPage />} />
              </Route>
              <Route path="notification" element={<SendnotificationPage />} />
            </Route>

            <Route exact path="/specialist" element={<LandingPage />}>
              <Route index element={<AdminLoginPage />} />
              <Route path="my-account" element={<AddminmyaccountPage />} />
              <Route
                path="change-password"
                element={<AdminChangepasswordPage />}
              />
              <Route path="dashboard" element={<SpdashboardPage />} />
              <Route path="feedback" element={<FeedbackPage />} />
              <Route path="request" element={<Outlet />}>
                <Route index element={<Adminrequests />} />
                <Route path=":id" element={<RequestdetailPage />} />
              </Route>
              <Route path="notification" element={<NotificationPage />} />
            </Route>
            <Route path="*" element={<Page404 />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
