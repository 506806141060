export const uploadFile = async (access, data, type) => {
  try {
    const headers = new Headers();

    headers.append("Authorization", `Bearer ${access}`);

    headers.append("Accept", "application/json");

    const formData = new FormData();

    formData.append("file", data, data.name);

    if (type === "images") {
      formData.append("type", "images");
    } else if (type === "videos") {
      formData.append("type", "videos");
    } else if (type === "documents") {
      formData.append("type", "documents");
    } else {
      formData.append("type", "profile");
    }

    console.log(data);

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/upload/file/`,
      {
        headers,
        method: "POST",
        body: formData,
      }
    );

    return getResponse;
  } catch (error) {}
};
