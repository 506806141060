import draftjsToHtml from 'draftjs-to-html';
import React,{useState,useEffect} from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import FooterPage from '../../footer/components/FooterPage';
import HeaderPage from '../../header/components/HeaderPage';
import { getTermsdetail } from '../util/getTerms';
import "./Termsandcondition.css";

function Termsandcondition(props) {
    const [policyList,setprivacyList] = useState([]);
    useEffect(()=>{
        callGetPrivacyData()
        // eslint-disable-next-line
    },[])
    const callGetPrivacyData = async () =>{
        const responceAbout = await getTermsdetail();
        if(responceAbout.status === 200){
            const resultAbout = await responceAbout.json();
            setprivacyList(resultAbout)
        }
    }
  return (
    <aside>
        <HeaderPage background={"#F7F7F7"} color={"black"} logo={"black"} />
        <div className='terms-main'>
            <Container>
                {policyList.map((item, index)=>(
                    <div key={index.toString()}>
                        <h3   className='title'>{props.language==="Sp"?item?.title_sp:item?.title}</h3>
                        <div dangerouslySetInnerHTML={{__html:draftjsToHtml(JSON.parse(props.language==="Sp"?item?.description_sp:item.description))}}></div>
                    </div>
                ))}
            </Container>
        </div>
        <FooterPage/>
    </aside>
  )
}
const mapStateToProps = (state) =>({
  language: state?.theme?.language
    
})
export default connect(mapStateToProps)(Termsandcondition)