export const getRegisterdetails = async (registerDetail) => {
  try {
    const headers = new Headers();

    console.log("register detail", registerDetail);

    headers.append("Content-Type", "application/json");

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/auth/register/`,
      {
        headers,
        method: "POST",
        body: JSON.stringify(registerDetail),
      }
    );
    return getResponse;
  } catch (error) {}
};
