import React, { useState, useEffect } from "react";
import "./ContactusPage.css";
import { Container, Row, Form, Button } from "react-bootstrap";
import HeaderPage from "../../header/components/HeaderPage";
import mail_icon from "../../../assets/mail_icon.svg";
import map_pin from "../../../assets/map_pin.svg";
import mobile_icon from "../../../assets/mobile_icon.svg";
import FooterPage from "../../footer/components/FooterPage";
import { getContactusdetail } from "../util/contactus";
import { toast } from "react-toastify";
import { getComponyInfo } from "../util/getcompany";
import {useTranslation} from 'react-i18next';
import { connect } from "react-redux";

function ContactusPage(props) {
  const emailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  const [companyinfo,setCompanyinfo] = useState([]);
  const mobileNumRegex = /^\d{10}$/;
  const { t } = useTranslation();
  const [contactInput, setContactinput] = useState({
    sender_name: "",
    sender_email: "",
    sender_mob: "",
    sender_address: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    sender_name: "",
    sender_email: "",
    sender_mob: "",
    message: "",
  });
  useEffect(()=>{
      callGetCompanyinfo()
      // eslint-disable-next-line
  },[])
  const callGetCompanyinfo = async () =>{
    const responceInfo = await getComponyInfo();
    if(responceInfo.status===200){
      const resultComponyInfo = await responceInfo.json();
      setCompanyinfo(resultComponyInfo[0])
     // console.log(resultComponyInfo)
    }
  }
  const contactInputChange = (e) => {
    setContactinput({
      ...contactInput,
      [e.target.name]: e.target.value,
    });

    switch (e.target.name) {
      case "sender_email":
        setErrors({
          ...errors,
          sender_email: "",
        });
        break;
      case "sender_name":
        setErrors({
          ...errors,
          sender_name: "",
        });
        break;
      case "sender_mob":
        setErrors({
          ...errors,
          sender_mob: "",
        });
        break;
      case "message":
        setErrors({
          ...errors,
          message: "",
        });
        break;
      default:
        break;
    }
  };

  const validation = () => {
    let checkValue = true;

    if (!contactInput.sender_email.match(emailRegex)) {
      errors.sender_email = props.language==="Sp"?"Ingresa tu nombre completo":"Enter your valid email address";
      checkValue = false;
    }

    if (!contactInput.sender_mob.match(mobileNumRegex)) {
      errors.sender_mob = props.language==="Sp"?"Introduce tu número de móvil válido":"Enter your valid mobile number";
      checkValue = false;
    }

    if (contactInput.sender_name === "") {
      errors.sender_name = props.language==="Sp"?"Ingresa tu nombre completo":"Enter your full name";
      checkValue = false;
    }

    if (contactInput.sender_email === "") {
      errors.sender_email = props.language==="Sp"?"Ingrese su dirección de correo electrónico":"Enter your email address";
      checkValue = false;
    }

    if (contactInput.sender_mob === "") {
      errors.sender_mob = props.language==="Sp"?"Ingrese su numero celular":"Enter your mobile number";
      checkValue = false;
    }

    if (contactInput.message === "") {
      errors.message = props.language==="Sp"?"Ingrese su mensaje":"Enter your message";
      checkValue = false;
    }

    setErrors({
      ...errors,
      sender_name: errors.sender_name,
      sender_email: errors.sender_email,
      sender_mob: errors.sender_mob,
      message: errors.message,
    });

    if (checkValue === true) {
      return true;
    } else {
      return false;
    }
  };

  const submitContactus = async (e) => {
    e.preventDefault();
    if (validation()) {
      let contactusdetail = {
        sender_email: contactInput.sender_email,
        sender_mob: contactInput.sender_mob,
        sender_address: "",
        message: contactInput.message,
      };
      let getContactusdetailresponse = await getContactusdetail(
        contactusdetail
      );
      if (getContactusdetailresponse.status === 201) {
         await getContactusdetailresponse.json();
        toast.success(t('pages.contact.form.success'));

        setContactinput({
          sender_name: "",
          sender_email: "",
          sender_mob: "",
          sender_address: "",
          message: "",
        });
      }
    }
  };

  return (
    <>
      <HeaderPage logo={"black"} color={"black"} background={"#F7F7F7"} />
      <div className="contactus">
        <Container>
          <Row>
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-6">
              <div className="help">
                <h4>{t('pages.contact.title-1')}</h4>
                <h4 className="help-text">{t('pages.contact.title-2')}</h4>
                <p>
                  {t('pages.contact.subtitle-1')}
                  <br />
                  {t('pages.contact.subtitle-2')}
                </p>
              </div>
              <div className="email mt-5">
                <img src={mail_icon} alt={"."}></img>
                <div className="email-info">
                  <h4>{t('pages.contact.address.email')}</h4>
                  <p >
                  {t('pages.contact.address.email-title')}<br/>
                    {companyinfo.email}
                  </p>
                </div>
              </div>
              <div className="email mt-4">
                <img src={map_pin} alt={"."}></img>
                <div className="email-info">
                  <h4>{t('pages.contact.address.address')}</h4>
                  <p >
                  {t('pages.contact.address.address-title')} <br/>
                    {companyinfo.address}
                  </p>
                </div>
              </div>
              <div className="email mt-4">
                <img src={mobile_icon} alt={"."}></img>
                <div className="email-info">
                  <h4>{t('pages.contact.address.phone')}</h4>
                  <p>
                  {t('pages.contact.address.phone-title')}<br/>
                    {companyinfo.mobile}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-6">
              <Form.Group className="form-group">
                <Form.Label className="form-label">{t('pages.contact.form.fullname')}</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  placeholder={t('pages.contact.form.fullname-place')}
                  name="sender_name"
                  value={contactInput.sender_name}
                  onChange={(e) => contactInputChange(e)}
                ></Form.Control>
                <div className="text-danger">{errors.sender_name}</div>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="form-label">{t('pages.contact.form.email')}</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  placeholder={t('pages.contact.form.email-place')}
                  name="sender_email"
                  value={contactInput.sender_email}
                  onChange={(e) => contactInputChange(e)}
                ></Form.Control>
                <div className="text-danger">{errors.sender_email}</div>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="form-label">{t('pages.contact.form.mobile')}</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  placeholder={t('pages.contact.form.mobile-place')}
                  name="sender_mob"
                  value={contactInput.sender_mob}
                  onChange={(e) => contactInputChange(e)}
                ></Form.Control>
                <div className="text-danger">{errors.sender_mob}</div>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="form-label">{t('pages.contact.form.message')}</Form.Label>
                <Form.Control
                  style={{ height: "120px" }}
                  type="text"
                  placeholder={t('pages.contact.form.message-place')}
                  as="textarea"
                  rows="5"
                  name="message"
                  value={contactInput.message}
                  onChange={(e) => contactInputChange(e)}
                ></Form.Control>
                <div className="text-danger">{errors.message}</div>
              </Form.Group>
              <Button
                className="col-12 submit"
                onClick={(e) => submitContactus(e)}
              >
                {t('pages.contact.form.button')}
              </Button>
            </div>
          </Row>
        </Container>
      </div>
      <FooterPage />
    </>
  );
}
const mapStateToProps = ( state ) =>({
  language:state.theme?.language
})
export default connect(mapStateToProps)(ContactusPage);
