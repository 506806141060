import { REQ_COUNT } from "./actionTypes";

const defaultState = {
  req_count: "",
};

export const dashboardReducer = (state = defaultState, action) => {
  switch (action.type) {
    case REQ_COUNT:
      return {
        ...state,
        req_count: action.req_count,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
