export const uploadPaymentreceipt = async (access, data, payment_id) => {
    try {
      const headers = new Headers();
  
      headers.append("Authorization", `Bearer ${access}`);
  
      headers.append("Accept", "application/json");
  
      const formData = new FormData();
  
      formData.append("file", data, data.name);
  
      formData.append("type", "payment_receipt");
      formData.append("payment_receipt", payment_id);
  
      console.log(data);
  
      const getResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/upload/file/`,
        {
          headers,
          method: "POST",
          body: formData,
        }
      );
  
      return getResponse;
    } catch (error) {}
  };
  