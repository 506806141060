export const deleteFaq = async (access, id) => {
  try {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${access}`);

    headers.append("Content-Type", "application/json");

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/post_faqs/${id}`,
      {
        headers,
        method: "DELETE",
      }
    );

    return getResponse;
  } catch (error) {}
};
