export const getPostrequestdetail = async (access, type, postRequestdetail) => {
  try {
    let url;

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${access}`);

    if (type === "sp") {
      url = `${process.env.REACT_APP_BASE_URL}/api/specialist/post-request/`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/api/specialist/post-request/`;
    }

    const getResponse = await fetch(url, {
      headers,
      method: "POST",
      body: JSON.stringify(postRequestdetail),
    });

    return getResponse;
  } catch (error) {}
};
