export const getProfiledetail = async (profileDetail, access) => {
  try {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    headers.append("Authorization", `Bearer ${access}`);

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/update_user_profile`,
      {
        headers,
        method: "PUT",
        body: JSON.stringify(profileDetail),
      }
    );

    return getResponse;
  } catch (error) {}
};
