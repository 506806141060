export const getChangepassworddetail = async (passworddetail, access) => {
  try {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    headers.append("Authorization", `Bearer ${access}`);

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/auth/change-passsword/`,
      {
        headers,
        method: "PUT",
        body: JSON.stringify(passworddetail),
      }
    );

    return getResponse;
  } catch (error) {}
};
