export const deleteSpecialist = async (access, data) => {
    try {
      const headers = new Headers();
  
      headers.append("Authorization", `Bearer ${access}`);
  
      headers.append("Accept", "application/json");
    headers.append('Content-Type', 'application/json');
      const getResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/manage_user/`,
        {
          headers,
          method: "PUT",
          body:JSON.stringify(data)
        }
      );
  
      return getResponse;
    } catch (error) {}
};
  