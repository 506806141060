import { combineReducers, applyMiddleware, createStore } from "redux";
import { theme } from "./modules/header/redux/reducer";
import loginReducer from "./modules/login/redux/reducer";
import homeReducer from "./modules/home/redux/reducer";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import adminReducer from "./modules/admin/login/redux/reducer";
import searchReducer from "./modules/admin/headernav/redux/reducer";
import dashboardReducer from "./modules/admin/dashboard/redux/reducer";

const persistConfig = {
  key: "persist-blockcar",
  storage,
};

const middleware = [thunk];

const rootReducer = combineReducers({
  theme: theme,
  login: loginReducer,
  home: homeReducer,
  adminlogin: adminReducer,
  searchInput: searchReducer,
  dashboard: dashboardReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = createStore(rootReducer, applyMiddleware(thunk));

const store = createStore(persistedReducer, applyMiddleware(...middleware));

export const persistor = persistStore(store);

export default store;
