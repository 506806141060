import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./BuyerPage.css";
import { getBuyerList } from "./../util/buyerlist";
import moment from "moment";
import {ModalPage} from "../../seller/components/SellerPage";
import { actionBuyerList } from "../util/actionbuyer";
import LoaderPage from "../../loader/components/LoaderPage";
function BuyerPage(props) {
  const [buyerList, setBuyerList] = useState([]);
  const [modalShow, setModalShow] =useState({
    show:false,
    id:""
  });
  const [loadState, setLoadState] = useState(true)
  useEffect(() => {
    callBuyerList();
    // eslint-disable-next-line
  }, [!buyerList]);
  const callBuyerList = async () => {
    const buyerReposnce = await getBuyerList(props?.access);
    if (buyerReposnce.status === 200) {
      const buyerData = await buyerReposnce.json();
      //console.log("buyer", buyerData);
      setBuyerList(buyerData);
    }
    setTimeout(()=>setLoadState(false), 100)
  };
  const callStatusChange = async (e,item) =>{
      e.preventDefault();
      const data = {
          action:item.is_active?"deactivate":"activate",
          user_id:item.id
      }
      const responceBuyer = await actionBuyerList(props.access,data);
      if(responceBuyer.status===200){
          await responceBuyer.json();
         // console.log(sellerResult)
          callBuyerList()
      }else{
          await responceBuyer.json();
        //console.log(sellerResult)
      }
  }
  const callDeleteBuyer = async (item) =>{
    const data = {
        action:"delete",
        user_id:item
    }
    const responceSeller = await actionBuyerList(props.access,data);
    if(responceSeller.status===200){
       await responceSeller.json();
        //console.log(sellerResult)
        setModalShow({show:false, id:""});
        callBuyerList()
    }else{
        await responceSeller.json();
       // console.log(sellerResult)
    }
  }
  if(loadState) 
    return <LoaderPage/>
  return (
    <div className={"buyers-content"}>
      <div class=" admin content-page">
        <div class=" admin content">
          <div class=" admin container-fluid">
            <div class=" admin row">
              <div class=" admin col-12">
                <div class=" admin card mt-2">
                  <div class=" admin card-body">
                    <h4 class=" admin mt-0 header-title">Buyer List</h4>
                    <div class=" admin table-responsive">
                      <table class=" admin table table-hover mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile Number</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th class=" admin text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {buyerList.length === 0 ? (
                            <tr>
                              <td colSpan={7} align={"center"}>
                                No date found
                              </td>
                            </tr>
                          ) : (
                            buyerList.filter((e)=>{
                              const data = e?.first_name?.toLowerCase()?.includes(props?.searchInput?.toLowerCase()) 
                              || e?.mobile?.toLowerCase()?.includes(props?.searchInput?.toLowerCase())
                              || e?.email?.toLowerCase()?.includes(props?.searchInput?.toLowerCase())
                              return data
                          }).length
                          ?
                          buyerList.filter((e)=>{
                              const data = e?.first_name?.toLowerCase()?.includes(props?.searchInput?.toLowerCase()) 
                              || e?.mobile?.toLowerCase()?.includes(props?.searchInput?.toLowerCase())
                              || e?.email?.toLowerCase()?.includes(props?.searchInput?.toLowerCase())
                              return data
                          })?.map((item, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.first_name}</td>
                                <td>{item.email}</td>
                                <td>{item.mobile}</td>
                                <td>{moment(item.created_at).format("DD/MM/YYYY")}</td>
                                <td>
                                  <div class="admin form-check form-switch">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id="flexSwitchCheckDefault"
                                      checked={item.is_active}
                                      onClick={(e)=>callStatusChange(e, item)}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="flexSwitchCheckDefault"
                                    ></label>
                                  </div>
                                </td>
                                <td class=" admin text-center">
                                  <button
                                    type="button"
                                    class=" admin btn btn-link text-danger ripple"
                                    onClick={()=>setModalShow({show:true, id:item.id})}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            )):(
                              <tr>
                                <td colSpan={7} align={"center"}>No data found</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPage 
        class="seller" 
        body="Do you want to delete Buyer" 
        title={"Are you sure"}
        setModalShow={setModalShow}
        modalShow={modalShow.show}
      >
            <button className='delete-btn' onClick={()=>callDeleteBuyer(modalShow.id)}>Delete</button>
            <button  className='cancel-btn' onClick={()=>setModalShow({show:false,id:""})}>Cancel</button>
      </ModalPage>
    </div>
  );
}
const mapStateToProps = (state) => ({
  access: state?.adminlogin?.access,
  searchInput:state?.searchInput?.search
});
export default connect(mapStateToProps)(BuyerPage);
