import React, { useState } from "react";
import "./ForgotpasswordPage.css";
import { connect } from "react-redux";
import HeaderPage from "../../header/components/HeaderPage";
import forgotpage_image from "./../../../assets/forgot_img.png";
import FooterPage from "../../footer/components/FooterPage";
import { Link } from "react-router-dom";
import { FormControl, InputGroup, Button } from "react-bootstrap";
import email_icon from "./../../../assets/email_icon.svg";
import arrow_back from "./../../../assets/arrow_back.png";
import {useTranslation} from 'react-i18next';

function Forgotpassword(props) {
  const primaryBtn = props.theme.primaryBtn;
  const inputType = props.theme.inputForm;
  const [inputField, setinputField] = useState({
    email: "",
  });
  const { t } = useTranslation();
  const handleChange = (e) => {
    setinputField({
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <HeaderPage color={"black"} logo={"black"} background={"transparent"} />
      <div className={"forgot col-12 row m-0"}>
        <div className={"col-6 px-10 py-5"}>
          <div className={"forgot-box"} px={"28px"}>
            <div className={"forgot-heading"}>
              <h3 className={"forgot-title  m-4 "}>{t('pages.forgot.title')}</h3>
              <label className={"secoundry"}>
                {t('pages.forgot.subtitle')}
              </label>
            </div>
            <form onSubmit={(e) => console.log(e.preventDefault())} className={"forgot-form"}>
              <div className={"form-group"}>
                <div className={"form-title"}>{t('pages.forgot.email')}</div>

                <InputGroup style={inputType}>
                  <InputGroup.Text id="basic-addon1">
                    <img
                      src={email_icon}
                      alt={"."}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </InputGroup.Text>
                  <FormControl
                    placeholder={t('pages.forgot.email-place')}
                    name={"email"}
                    onChange={(e) => handleChange(e)}
                    value={inputField.email}
                    required
                    type={"email"}
                    className={"input-field"}
                  />
                </InputGroup>
              </div>
              <Button
                className={"mt-4 shadow-btn forgot-btn"}
                type={"submit"}
                style={primaryBtn}
              >
                {t('pages.forgot.button')}
              </Button>
            </form>
            <center>
              <Link
                to={"/login"}
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <img
                  src={arrow_back}
                  alt={"arrow_back"}
                  className={"arrow_back"}
                />{" "}
                <label className={"alter-forgot"}>{t('pages.forgot.back')}</label>
              </Link>
              <br />
              <br />
            </center>
            <br />
          </div>
          <label className={"all-right-reserve"}>
            © 2022 ConfyCar. {t('pages.footer.rights')}
          </label>
        </div>
        <div className={"col p-0"}>
          <img
            src={forgotpage_image}
            alt={"."}
            className={"user-select-none"}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
      <FooterPage />
    </>
  );
}
const mapStateToProps = (store) => ({
  theme: store.theme?.primaryTheme,
});
export default connect(mapStateToProps)(Forgotpassword);
