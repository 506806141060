export const getAboutusdetail = async () => {
  try {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/footer-pages/about-us`,
      {
        headers,
        method: "GET",
      }
    );

    return getResponse;
  } catch (error) {}
};
