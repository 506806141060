export const getNotificationlist = async (access, userId) => {
  try {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    headers.append("Authorization", `Bearer ${access}`);

    const getResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/notifications?user_id=${userId}`,
      {
        headers,
        method: "GET",
      }
    );
    return getResponse;
  } catch (error) {}
};
