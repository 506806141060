import { LOGIN_SUCCESS, LOG_OUT } from "./actionTypes";
import { USER_ID } from "./actionTypes";

const loginState = {
  access: "",
  userId: "",
  userType: "",
};

export const loginReducer = (state = loginState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        access: action.access,
      };
    case USER_ID:
      return {
        ...state,
        userId: action.userId,
        userType: action.userType,
      };
    case LOG_OUT:
      return loginState;
      
    default:
      return state;
  }
};
export default loginReducer;
