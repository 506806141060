export const getOrderlist = async (access, orderId) => {
  try {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    headers.append("Authorization", `Bearer ${access}`);

    let url;

    if (orderId !== "") {
      url = `${process.env.REACT_APP_BASE_URL}/api/orders/${orderId}`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/api/orders/`;
    }

    console.log("url", url);

    const getResponse = await fetch(url, {
      headers,
      method: "GET",
    });

    return getResponse;
  } catch (error) {}
};
