import React, { useState, useEffect } from 'react'
import "./FaqsPage.css"
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import HeaderPage from '../../header/components/HeaderPage';
import FooterPage from '../../footer/components/FooterPage';
import { getFaqdetail } from "./../util/getFaq"
import {useTranslation} from 'react-i18next';

function FaqsPage(props) {
    const [expand, setExapand] = useState("");
    const [faqlist, setFaqlist] = useState([]);
    const {t} = useTranslation()
    useEffect(() => {
      callGetFaq();
    }, [])
    const callGetFaq = async () =>{
        const responceFAQ = await getFaqdetail();
        if(responceFAQ.status===200){
            const resultFAQ = await responceFAQ.json()
            setFaqlist(resultFAQ)
        }
    }
    return (
        <div>
            <HeaderPage background={"#F7F7F7"} logo={"#383936"} color={"#383936"} />
            <div  className={"faqs"}>
                <Container>
                    <h6>{t('pages.faq.title')}</h6>
                    <label className='pb-3'>{t('pages.faq.subtitle')}</label>

                    <div className={"req-list"}>
                       {
                           faqlist.map((item , index)=>(
                            <div key={index.toString()} className={"req-list-div"}>
                                <div  className={expand===index?"collapsible active":"collapsible"} onClick={()=>expand!==index? setExapand(index):setExapand(false)}>
                                <div className='left-title'>
                                        <span>{props.language==="Sp"?item.title_sp:item.title}</span>
                                </div>
                                <div>
                                    {expand===index? <i className='fa fa-minus' /> : <i className='fa fa-plus' />}
                                </div>
                                </div>
                                <div  className={"collapsible-bottom "} >
                                    <div className={"content"}>
                                        {props.language==="Sp"?item.description_sp:item.description}
                                    </div>
                                </div>
                            </div>
                           ))
                       }
                    </div>
                </Container>
            </div>
            <FooterPage/>
        </div>
    )
}
const mapStateToProps = (store) =>({
  language: store?.theme?.language,
  theme:store.theme?.primaryTheme
})
export default connect(mapStateToProps)(FaqsPage);