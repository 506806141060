import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./ViewerPage.css";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useTranslation } from "react-i18next";
import ReactPlayer from 'react-player'

function ViewerPage(props) {
  const { t } = useTranslation();

  const closeViewer = (e) => {
    props.setShowview(false);
    props.setMediaarray([]);
  };

  const downloadFile = () => {
    if (props?.mediaArray.length === 0) {
      getFileurl(props?.documentUrl[0]?.uri);
    } else {
      let httpUrl = props?.mediaArray.filter(
        (item, index) => index === props.currentIndex
      );
     // console.log("Current image url", httpUrl);
      httpUrl = httpUrl[0].url;
      getFileurl(httpUrl);
    }
  };

  const getFileurl = async (httpUrl) => {
    fetch(httpUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const tempAnchor = document.createElement("a");
        let value = httpUrl.split("/").pop();
        tempAnchor.download = value;
        tempAnchor.href = blobUrl;
        document.body.appendChild(tempAnchor);
        tempAnchor.click();
        tempAnchor.remove();
      });
  };
  const getNextIndex = (type) =>{
    if(type==="prev"){
      if(props.currentIndex===0){
        props.setCurrentIndex(props?.mediaArray.length-1)
      }else{
        props.setCurrentIndex(props.currentIndex-1)
      }
    }else{
      if(props.currentIndex===props?.mediaArray.length-1){
        props.setCurrentIndex(0)
      }else{
        props.setCurrentIndex(props.currentIndex+1)
      }
    }
  }
//console.log(props.currentIndex);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showView}
      onHide={(e) => closeViewer(e)}
      fullscreen={true}
      className="viewer"
    >
      <Modal.Body style={{ height: "50%" }}>
        {props?.mediaArray?.length === 0 ? (
          <DocViewer
            documents={props.documentUrl}
            pluginRenderers={DocViewerRenderers}
            config={{
              header: {
                disableHeader: false,
                disableFileName: false,
                retainURLParams: false,
              },
            }}
          />
        ) : props?.mediaArray[0]?.type==="photo"?(
          <ReactImageVideoLightbox
            data={props?.mediaArray}
            startIndex={props.currentIndex}
            showResourceCount={true}
            onNavigationCallback={(currentIndex) =>
              props.setCurrentIndex(currentIndex)
            }
            onCloseCallback={(e) => closeViewer(e)}
          />
        ):(
          <div className={"video-player"}>
            <div className={"video-list-count"}><span>{props.currentIndex+1}</span> / <span>{props?.mediaArray.length}</span></div>
            <button onClick={(e)=>closeViewer(e)} className={"times-btn"}>
              <i className={"fa fa-times"} />
            </button>
           
            
            {
              props?.mediaArray.map((item,index)=>props.currentIndex===index&&(
                <>
                <button onClick={()=>getNextIndex("prev")} className={"previous-list"}>
                    <i className={"fa fa-angle-left"} />
                </button>
                  <ReactPlayer auto url={item.url} controls className={"url-videos"}/>
                <button onClick={()=>getNextIndex("next")} className={"next-list"}>
                  <i className={"fa fa-angle-right"} />
                </button>
                </>
              ))
            }
          </div>
        )
      }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => downloadFile()}>
          {t("pages.request.request-popup.download")}
        </Button>
        <Button onClick={(e) => closeViewer(e)}>
          {t("pages.request.request-popup.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ViewerPage;
