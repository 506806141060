import React, { useState } from "react";
import FooternavPage from "../../footernav/components/FooternavPage";
import { Outlet } from "react-router-dom";
import HeadernavPage from "../../headernav/components/HeadernavPage";
import SidenavPage from "../../sidenav/components/SidebarPage";
import "./LandingPage.css";
import { connect } from "react-redux";

function LandingPage(props) {
  const [openSideNav, setopenSideNav] = useState(false);

  return (
    <>
      <aside className="main-dashboard">
        {props.access ? (
          <>
            <HeadernavPage
              setopenSideNav={setopenSideNav}
              openSideNav={openSideNav}
            />
            <SidenavPage
              openSideNav={openSideNav}
              setopenSideNav={setopenSideNav}
            />
            <Outlet />
            <FooternavPage />
          </>
        ) : (
          <div className="login-dashboard">
            <Outlet />
          </div>
        )}
      </aside>
    </>
  );
}
const mapStateToProps = (state) => ({
  access: state?.adminlogin?.access,
});
export default connect(mapStateToProps)(LandingPage);
