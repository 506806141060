export const getRequestList = async (access, type) => {
  try {
    let url;

    const headers = new Headers();

    headers.append("Authorization", `Bearer ${access}`);

    headers.append("Accept", "application/json");

    console.log("Current type", type, access);

    if (type === "sp") {
      url = `${process.env.REACT_APP_BASE_URL}/api/specialist/order-list/1/`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/api/admin/order-list/1/`;
    }

    const getResponse = await fetch(url, {
      headers,
      method: "GET",
    });

    return getResponse;
  } catch (error) {}
};
