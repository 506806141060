import React, { useEffect, useState } from "react";
import "./SpecialistPage.css";
import { connect } from "react-redux";
import { getSpecialList } from "../util/specialList";
import { NavLink } from "react-router-dom";
import PostnotificationPage from "../../modal/components/PostnotificationPage";
import pushnoti_icon from "../../../../assets/paper-plane.png";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { deleteSpecialist } from "../util/specialistDelete";
import { ModalPage } from "../../seller/components/SellerPage";
import { toast } from "react-toastify";
import LoaderPage from "../../loader/components/LoaderPage";

function SpecialistPage(props) {
  const [specialList, setspecialList] = useState([]);
  const [shownotification, setShownotification] = useState(false);
  const [specialistId, setSpecialistid] = useState("");
  const [loadState, setLoadState] = useState(true)
  const navigate = useNavigate();
  const [modalShow, setModalShow] =useState({
    show:false,
    id:""
});
  useEffect(() => {
    getSpecialist();
    // eslint-disable-next-line
  }, [!specialList]);

  const getSpecialist = async () => {
//console.log("props.access", props.access);
    const specialresponce = await getSpecialList(props.access);
    if (specialresponce?.status === 200) {
      const specialresult = await specialresponce.json();
    //  console.log("Specialist data", specialresult);
      setspecialList(specialresult?.data);
    } else {
    //  console.log(specialresponce);
    }
    setTimeout(()=>setLoadState(false), 100)
  };

  const sendNotification = (id) => {
  //  console.log("sp id", id);
    setSpecialistid(id.toString());
    setShownotification(true);
  };

  const showSpecialistdetail = (id) => {
  //  console.log("Hello I am specialist", id);
    navigate(`${id}`);
  };

  const callDeleteSpecialist = async ( id ) => {
    const data = {
        action:"delete",
        user_id:id
    }
//console.log(modalShow)
    const responceSpecialist = await deleteSpecialist(props.access,data);
    if(responceSpecialist.status===200){
        const sellerResult =await responceSpecialist.json();
        console.log(sellerResult)
        setModalShow({show:false, id:""});
        toast.success("delete specialist successfull");
        getSpecialist()
    }else{
        const sellerResult =await responceSpecialist.json();
        console.log(sellerResult)
    }
  }
  if(loadState) 
    return <LoaderPage/>

  return (
    <div className="specialist-contents">
      <div class=" admin content-page">
        <div class=" admin content">
          <div class=" admin container-fluid">
            <div class=" admin row">
              <div class=" admin col-sm-12 text-end">
                <NavLink
                  to={"add"}
                  className=" admin btn btn-request rounded-pill w-md waves-effect waves-light mb-2 mt-1 px-2"
                >
                  <i class=" admin mdi mdi-plus"></i> Add Specialist
                </NavLink>
              </div>
            </div>

            <div class=" admin row">
              {specialList.filter((e)=>{
                    const data = e?.basic_details?.fullname?.toLowerCase()?.includes(props?.searchInput?.toLowerCase()) 
                    || e?.basic_details?.specialization?.toLowerCase()?.includes(props?.searchInput?.toLowerCase())
                    return data
                }).length===0?
                  <div className="text-center pt-4">
                    No records found
                  </div>
                :
              specialList.filter((e)=>{
                    const data = e?.basic_details?.fullname?.toLowerCase()?.includes(props?.searchInput?.toLowerCase()) 
                    || e?.basic_details?.specialization?.toLowerCase()?.includes(props?.searchInput?.toLowerCase())
                    return data
                }).map((item) => (
                <div class=" admin col-xl-4">
                  <div
                    class=" admin card"
                   
                  >
                    <div class=" admin card-body project-box">
                      
                      <div className=" card-names">
                        
                        <div className="title-name" onClick={() =>showSpecialistdetail(item?.basic_details?.id)}>
                          <h6 class=" admin mt-0 mb-1">
                            <a href class=" admin text-dark">
                              {item?.basic_details?.specialization}
                            </a>
                          </h6>
                          <p class=" admin font-13">
                            {item?.basic_details?.fullname}
                          </p>
                        </div>
                        <div className=" card-header-divs">
                          <div class={item?.basic_details?.is_active?" admin badge bg-warning ":" admin badge bg-secoundry "}>
                            {item?.basic_details?.is_active?"Active":"In-Active"}
                          </div>
                          <Dropdown align="end" title="Actions" variant={"primary"} className={"action-menu"}  id="dropdown-item-button" >
                            <Dropdown.Toggle className=" ellipsis" variant="primary">
                              <i className="fa fa-ellipsis-v" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu id="dropdown-item-button" >
                              <Dropdown.Item as="button" onClick={()=>navigate(`edit/${item?.basic_details?.id}`)} title={"edit"}>Edit</Dropdown.Item>
                              <Dropdown.Item as="button" onClick={()=>setModalShow({show:true,id:item?.basic_details?.id})} title={"delete"}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className=" card-names">
                        <ul onClick={() =>showSpecialistdetail(item?.basic_details?.id)} class=" admin list-inline">
                          <li class=" admin list-inline-item me-4">
                            <h5 class=" admin mb-1">{item?.pending_order}</h5>
                            <p class=" admin text-muted">Pending</p>
                          </li>
                          <li class=" admin list-inline-item">
                            <h5 class=" admin mb-1">{item?.completed_order}</h5>
                            <p class=" admin text-muted">Completed</p>
                          </li>
                        </ul>

                        <div className="float-end" onClick={() =>sendNotification(item?.basic_details?.id)}>
                          <img src={pushnoti_icon} alt="" class="noti-icon " ></img>
                        </div>
                      </div>
                        {/*  <div class=" admin project-members mb-1">
                          <h6 class=" admin float-start me-3">Customers</h6>
                          <div class=" admin avatar-group">
                            <a
                              href
                              class=" admin avatar-group-item"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Mat Helme"
                            >
                              <img
                                src={user}
                                class=" admin rounded-circle avatar-sm"
                                alt="friend"
                              />
                            </a>

                            <a
                              href
                              class=" admin avatar-group-item"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Michael Zenaty"
                            >
                              <img
                                src={user}
                                class=" admin rounded-circle avatar-sm"
                                alt="friend"
                              />
                            </a>

                            <a
                              href
                              class=" admin avatar-group-item"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="James Anderson"
                            >
                              <img
                                src={user}
                                class=" admin rounded-circle avatar-sm"
                                alt="friend"
                              />
                            </a>

                            <a
                              href
                              class=" admin avatar-group-item"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Mat Helme"
                            >
                              <img
                                src={user}
                                class=" admin rounded-circle avatar-sm"
                                alt="friend"
                              />
                            </a>
                          </div>
                        </div> */}

                        {/* <h6 class=" admin mt-0">
                          Progress{" "}
                          <span class=" admin text-success float-end">80%</span>
                        </h6>
                        <div class=" admin progress progress-bar-alt-success progress-sm">
                          <div
                            class=" admin progress-bar bg-success progress-animated wow animated animated"
                            style={{ width: "70%" }}
                          ></div>
                        </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ModalPage
            setModalShow={setModalShow}
            body={"Do you want to delete Specialist"}
            title={"Are you sure"}
            class={"seller"}
            modalShow={modalShow.show}
        >
            <button className='delete-btn' onClick={()=>callDeleteSpecialist(modalShow.id)}>Delete</button>
            <button  className='cancel-btn' onClick={()=>setModalShow({show:false,id:""})}>Cancel</button>
        </ModalPage>
      <PostnotificationPage
        specialistId={specialistId}
        shownotification={shownotification}
        setShownotification={setShownotification}
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  access: state.adminlogin.access,
  searchInput: state.searchInput.search,
});
export default connect(mapStateToProps)(SpecialistPage);
